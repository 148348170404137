import Tab from "."
import {Grid} from "@geist-ui/core"


const Tabs = ({ tabs, currentTab, setCurrentTab }) => {

    return <Grid.Container>
        {tabs.map(tab => tab?.hidden ? null : <Tab
            title={tab.title}
            tap={() => setCurrentTab(tab.id)}
            key={tab.id}
            variant={currentTab === tab.id ? "Variant 1" : "Variant 2"}
        />
        )}
    </Grid.Container>
}

export default Tabs