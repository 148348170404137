import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { useUser } from "../utils/useUser"


const useTeams = () => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/teams'

    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshTeams = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        teams: data,
        refreshTeams
    }
}


const useTeam = (id) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/teams/' + id
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshTeam = async () => {
        await mutate(url)
    }

    return {
        team: data,
        refreshTeam
    }
}

const useCurrentTeam = ({redirect}={redirect:true}) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser({redirect})
    const url = '/api/v1/users/'+user?._id+'/team'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshTeam = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        team: data,
        refreshTeam,
        teamLoading: !data && !error
    }
}


export { useTeams, useCurrentTeam, useTeam }