import {NameStep, TypeStep, ConfirmationStep} from "./views"

const steps = {
    title: {
        title: 'Create a Workflow',
        subtitle: 'Give your workflow a name.',
        onComplete: setStep => setStep('type'),
        View: NameStep
    }, 
    type: {
        title: 'Choose a Plant Type',
        subtitle: 'This workflow will apply to the selected type only.',
        onComplete: setStep => setStep('confirm'),
        View: TypeStep
    },
    
    confirm: {
        title: "Confirm Details",
        subtitle: "Check everything looks good",
        onComplete: (setStep, submit) => submit(),
        View: ConfirmationStep
    }
}

export default steps