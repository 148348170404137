import Image from "next/image"
import { plantTypes } from "../../../../common/variables";
import styles from './types.module.css'

const PlantTypeListItem = ({ type, onClick, lookupKey }) => {
    const plantType = type
    return (
        <div onClick={() => onClick(lookupKey)} className={styles.plantTypeList}>
            <Image width={40} height={40} alt={`${plantType.title} icon`} src={plantType.icon}></Image>
            <div>{plantType.title}</div>
        </div>
    );
}

const PlantTypeList = ({ onClick }) => {
    const plantTypeKeys = Object.keys(plantTypes);
    return plantTypeKeys.map(key => {
        const plantType = plantTypes[key];
        return <PlantTypeListItem onClick={onClick} key={key} lookupKey={key} type={plantType}></PlantTypeListItem>;
    });
}

export { PlantTypeListItem, plantTypes, PlantTypeList }