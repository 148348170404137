import moment from "moment"
import Image from "next/image"
import { useRouter } from "next/router"
import { SkipForward } from "phosphor-react"
import Skeleton from "react-loading-skeleton"
import { plantTypes } from "../../../../common/variables"
import styles from "./list-item.module.css"

const WorkflowIcon = ({ type }) => {
    return <div className={styles.image}>
        <Image width={50} height={50} src={plantTypes[type]?.icon} />
        <div className={styles.icon}>
            <SkipForward weight="duotone" width={15} height={15}></SkipForward>
        </div>
    </div>
}

const WorkflowListItem = ({ workflow, loading, onClick, lastChild, style, hideDate }) => {
    const router = useRouter()
    const defaultHandler = () => router.push("/workflows/" + workflow?._id)

    return <div className={styles.container} style={{ border: lastChild ? "none" : null, ...style }} onClick={onClick ?? defaultHandler}>
        <div className={styles.leftBlock}>
            <div>
                {loading ? <Skeleton style={{ width: "30px", height: "30px" }} /> : <WorkflowIcon type={workflow.type}></WorkflowIcon>}
            </div>
            <div>
                {loading ? <Skeleton></Skeleton> : plantTypes[workflow?.type]?.title}
                <br />
                <strong>{loading ? <Skeleton width={200} /> : workflow.title}</strong>
                <div className={styles.description}>
                    {loading ? <Skeleton /> : workflow.description}
                </div>
            </div>
        </div>
        {!hideDate ? 
        <div>
            {moment(workflow?.timestamp).format("MMM Do YYYY hh:mm A")}
        </div> : null}
        <div className={styles.description}>
            {loading ? <Skeleton></Skeleton> : Object.keys(workflow.steps).length + " Steps"}
        </div>
    </div>
}

export default WorkflowListItem
export { WorkflowIcon }