import {PopupStepper} from "../../../ui"

import Space from "../../../models/space"
import { useSpaces } from "../../../data"

import { useUser } from "../../../utils/useUser"
import steps from "./steps"

const AddSpacePopup = ({ onClose }) => {

    const { user } = useUser()
    const { refreshSpaces } = useSpaces()

    const initialData = {
        name: "",
        conditions: "outdoor",
        type: "grow",
    }

    const addSpace = async data => {
        await Space.create(user, data)
        await refreshSpaces()
    }

    return <PopupStepper
        initialData={initialData}
        initialStep="confirmation"
        callback={addSpace}
        steps={steps}
        onClose={onClose}>
    </PopupStepper>
}

export default AddSpacePopup