import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { useUser } from "../utils/useUser"
import { useSWRDataWithAuth } from "./useSWRData"


const useStrains = (filter) => {

    const { strains, refresh } = useSWRDataWithAuth('/api/v1/strains', filter, {name: "strains"})

    return {
        strains,
        refreshStrains: refresh
    }
}

const useStrain = (id) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/strains/' + id
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshStrain = async () => {
        await mutate(url)
    }

    return {
        strain: data,
        refreshStrain
    }
}

export { useStrains, useStrain }