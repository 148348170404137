import {Display, Grid, Spacer, Spinner, Text} from "@geist-ui/core"
import { useState } from "react"
import { EditableData, Input, ListAction, Button, SecondaryButton } from "../../ui"
import { ModalHeader } from "./components"

const interfaceLabels = {
    onewire: '1-Wire',
    gpio: 'GPIO',
}

const supportedTypes = {
    // temperature: {
    //     interfaces: ['onewire', 'gpio'],
    //     name: 'Temperature Sensor',
    //     icon: 'Thermometer',
    //     direction: 'in'
    // },
    switch: {
        interfaces: ["gpio"],
        name: "Switch",
        direction: "out",
        icon: "Power"
    }
}

const deviceTypes = Object.keys(supportedTypes)

const Wrapper = ({ children, done, onClose }) => {
    return <div style={{ padding: "20px" }}>
        <Grid.Container>
            <Grid>
                <ModalHeader variant={done ? 'done' : 'primary'} style={{ width: "100%" }} title={done ? 'Device Added' : 'Add Device to Hub'}>
                </ModalHeader>
                <Spacer h={2} />
                {children}
                <Spacer h={0.5} />
                <SecondaryButton onClick={onClose} width='100%'>
                    Dismiss
                </SecondaryButton>
            </Grid>
        </Grid.Container>
    </div>
}

const AddDevice = ({ title, key, callback, onClose }) => {

    const [step, setStep] = useState('start')

    const [data, setData] = useState({})

    const submit = async () => {
        setStep('loading')
        try {
            await callback(data)
            setStep('done')
        } catch (e) {
            setStep("error")
        }
    }

    const updateData = (key, value, nextStep) => {
        var copiedData = { ...data }
        copiedData[key] = value
        setData(copiedData)
        setStep(nextStep ?? step)
    }

    if (step === 'loading') {
        return <Wrapper onClose={onClose}>
            <Display>
                <Spinner />
            </Display>
        </Wrapper>
    }

    if (step === 'start') {
        return <Wrapper onClose={onClose}>
            {deviceTypes.map(type => {
                return <ListAction
                    key={type}
                    onClick={() => updateData('type', type, 'interface')}
                    title={supportedTypes[type].name}
                    icon={supportedTypes[type].icon}
                    subtitle={supportedTypes[type].direction === 'in' ? 'Input' : 'Output'}
                />
            })}
            <Spacer h={2} />
        </Wrapper>
    }

    if (step === 'interface') {
        return <Wrapper onClose={onClose}>
            Pin Number
            <Spacer />
            <Input fullWidth onChange={e => {
                setData({ ...data, interface: "gpio", payload: { pin: e.target.value } })
            }}></Input>
            <Spacer h={2} />
            <Button onClick={() => setStep("confirmation")} style={{ width: "100%" }}>
                Next
            </Button>
        </Wrapper>
    }

    if (step === 'confirmation') {
        return <Wrapper onClose={onClose}>
            <Grid.Container>
                <Input value={data.name ?? ""} onChange={e => {
                    updateData('name', e.target.value)
                }} placeholder='Enter a name' style={{ width: "100%" }} />
                <Spacer />
                <EditableData tap={() => setStep('start')} title='Type' subtitle={supportedTypes[data.type].name} />
                <EditableData tap={() => setStep('interface')} title='Interface' subtitle={interfaceLabels[data.interface]} />
                <EditableData tap={() => setStep('interface')} title='Pin Number' subtitle={`${data?.payload?.pin}`} />
                <Spacer h={2} />
                <Button onClick={submit} style={{ width: "100%" }}>
                    Add Device
                </Button>
            </Grid.Container>
        </Wrapper>
    }

    if (step === 'done') {
        return <Wrapper done onClose={onClose}>
        </Wrapper>
    }

    return <Wrapper>
        <Display>
            <Text type="error">
                Something went wrong
            </Text>
        </Display>
    </Wrapper>
}

export default AddDevice