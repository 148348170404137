import { ListAction } from "../../../../../ui"
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-dom';
import searchClient from '../../../../../services/search'
import { upperFirst } from "lodash"

const StrainListView = ({ data, setData, onChange, onComplete, setStep }) => {

    const handleSelection = async (selection) => {
        setData({...data, strain: { mode: "list", payload: selection }, provider: { mode: "list", payload: selection.provider }, type: selection.type, source: "breeder" })
        await onComplete()
    }

    const StrainListItem = ({ hit }) => {
        return <div onClick={() => handleSelection(hit)} style={{ listStyleType: "none", marginTop: "12px", paddingBottom: "12px", cursor: "pointer", borderBottom: "1px solid rgba(0, 0, 0, 0.1)", display: "flex", alignItems: "center" }}>
            <div style={{ width: "60px", margin: "12px" }}>
                <img src={hit?.provider?.logo}></img>
            </div>
            <div style={{ width: "100%" }}>
                <strong>
                    <Highlight hit={hit} attribute="name" tagName="mark" />
                </strong>
                <br />
                <Highlight hit={hit} attribute="provider.name" tagName="mark" />
            </div>
            <div style={{ width: "100%", textAlign: "right", paddingRight: "12px" }}>
                {upperFirst(hit?.type)}
            </div>
        </div>
    }

    return <div>
        <InstantSearch
            indexName={(process.env.DB_ENV || process.env.OVERRIDE_ENV || process.env.NODE_ENV || 'development')+"_strains"}  // replace with your own Index Name
            searchClient={searchClient}
        >
            <SearchBox />
            <div style={{ maxHeight: "250px", overflow: "scroll" }}>
                <Hits callback={handleSelection} style={{ listStyleType: "none" }} hitComponent={StrainListItem} />
                <ListAction
                style={{marginTop: "12px"}}
                    tap={() => setStep("type")}
                    icon='Plus' title='Add Manually'
                    variant='no-subtitle' />
            </div>
        </InstantSearch>
    </div>
}


const onComplete = (setStep) => {
    setStep("quantity")
}

const strainStep = {
    title: "Strain",
    subtitle: "Which strain did you get?",
    View: StrainListView,
    onComplete
}

export default strainStep