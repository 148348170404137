import { CaretLeft } from "phosphor-react"
import styles from './button.module.css'

import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/client/utils/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-primary-background hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
  fullWidth?: boolean
  loading?: boolean
  width?: string
  style?: React.CSSProperties
  disabled?: boolean, 
  icon?: string | React.ComponentType<{ width: number }>, 
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, fullWidth, width, style, disabled, onClick, icon, children, ...props }, ref) => {
    
    const Comp = asChild ? Slot : "button"
    const classes = cn(buttonVariants({ variant, size, className }))

    const Icon = icon

    return (
      <Comp
        {...props}
        style={{ width: fullWidth ? '100%' : width, ...style, cursor: disabled ? "auto" : "pointer", opacity: disabled ? 0.5 : 1, "boxSizing": "border-box" }}
        className={classes}
        onClick={onClick}
        ref={ref}
      >
        {Icon && <Icon width={24}/>}{children}
      </Comp>
    )
  }
)

Button.displayName = "Button"
export { Button, buttonVariants }

const SecondaryButton = ({ children, onClick, icon, width, style, fullWidth, ...props }: ButtonProps) => {
    return <Button style={{ width: fullWidth ? '100%' : width, ...style }} onClick={onClick} icon={icon} {...props}>
        {children}
    </Button>
}

const LinkButton = (props: ButtonProps) => {
    return <Button variant="link" {...props} onClick={props.onClick}/>
}

interface BackButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void
    style?: React.CSSProperties
}

const BackButton = ({ children, onClick, style }: BackButtonProps) => {
    return <button onClick={onClick} className={styles.backButton} style={style}>
        <CaretLeft weight="bold" size={24} />
        {children ?? "Back"}
    </button>
}

const TextButton = ({ children, onClick, style }: ButtonProps) => {
    return <button onClick={onClick} className={styles.backButton} style={style}>
        {children ?? "Back"}
    </button>
}



export { SecondaryButton, LinkButton, BackButton, TextButton }