import Network from "../../services/network"

class Webhook {
    constructor({url, trigger, team, calls, created, status, lastCall, lastSuccessfulCall, _id, active, name}) {
        this._id = _id
        this.url = url
        this.trigger = trigger
        this.calls = calls
        this.team = team, 
        this.created = created
        this.status = status
        this.lastCall = lastCall
        this.lastSuccessfulCall = lastSuccessfulCall
        this.active = active
        this.name = name
    }

    static baseURL = '/api/v1/webhooks'

    static async create(user, url, trigger) {
        const res = await Network.post(this.baseURL, user?.accessToken, {url, trigger})
        return new Webhook(res)

    }

    async changeName(user, name) {
        return Network.put(`${this.constructor.baseURL}/${this._id}`, user?.accessToken, {
            name
        })  
    }

    async edit(user, key, value) {
        return await Network.put(`${this.constructor.baseURL}/${this._id}`, user?.accessToken, {
            [key]: value
        })
    }

    async enable(user) {
        return Network.put(`${this.constructor.baseURL}/${this._id}`, user?.accessToken, {
            active: true
        })
    }

    async disable(user) {
        return Network.put(`${this.constructor.baseURL}/${this._id}`, user?.accessToken, {
            active: false
        })
    }

    async replay(user, calls) {
        return Network.post(`${this.constructor.baseURL}/${this._id}/replay`, user?.accessToken, {
            calls
        })
    }

    async delete(user) {
        return Network.delete(`${this.constructor.baseURL}/${this._id}`, user?.accessToken)
    }
}

export default Webhook