import {parseValue} from "../../../../server/utils/filter"
import { skipList } from "../constants"

const handleFilterOptionValue = (value) => {
    const isComplex = (value.split("(")).length > 1
    if (!isComplex) return value

    const firstBracket = value.indexOf('(')
    const lastBracket = value.length - 1
    const inner = value.substring(firstBracket + 1, lastBracket)
    const operation = value.substring(0, firstBracket)

    const isArray = (value.split(",")).length > 1

    if (!isArray) {
        const parsedValue = parseValue(inner)
        return { operation, value: parsedValue }

    } else {

        const o = {
            operation, value: (inner.split(",")).map(v => {
                return handleFilterOptionValue(v)
            })
        }

        return o
    }
}

const handleFilterOption = (key, value) => {

    const [type, filterKey] = [key.split("[")[0], key.split("[")[1].split("]")[0]]

    const res = handleFilterOptionValue(value)

    return { key: type, value: { [filterKey]: res } }
}



const buildQueryObjectFromURLParameters = (urlQuery) => {

    const query = {}
    const urlParams = Object.keys(urlQuery ?? {})

    urlParams.forEach((key) => {
        const value = urlQuery[key]
        if (skipList.includes(key)) query[key] = parseInt(value)
        else {
            const res = handleFilterOption(key, value)
            query[res.key] = { ...query[res.key], ...res.value }
        }
    })

    return query
}


export { buildQueryObjectFromURLParameters }