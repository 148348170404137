import useSWR, {useSWRConfig} from "swr"
import Space from "../models/space"
import Network from "../services/network"
import { useUser } from "../utils/useUser"

const useSpaces = () => {
    
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/spaces'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshSpaces = async () => {
        await mutate(user ? [url, user.accessToken] : url)
    }

    return {
        spaces: data?.map(space => new Space(space)),
        spacesLoading: !error && !data,
        refreshSpaces
    }
}

const useSharedSpaces = () => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/spaces/shared'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshSpaces = async () => {
        await mutate(user ? [url, user.accessToken] : url)
    }

    return {
        spaces: data?.map(space => new Space(space)),
        refreshSpaces
    }
}

const useSpace = (id) => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = id ? '/api/v1/spaces/' + id : null
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshSpace = async () => {
        await mutate([url, user.accessToken])
    }

    return {
        space: data ? new Space(data) : null,
        refreshSpace
    }
}

export { useSpaces, useSpace, useSharedSpaces }