import {Grid} from "@geist-ui/core"
import Skeleton from 'react-loading-skeleton'

const PlantLoader = () => {
    return <Grid.Container  gap={2}>
        <Grid>
            <Skeleton style={{ width: "35px", height: "35px" }} />
        </Grid>
        <Grid>
            <Skeleton width="150px" />
            <Skeleton width="150px" height="0.5rem" />
        </Grid>
    </Grid.Container>

}

export default PlantLoader