import { Toggle } from "@geist-ui/core";
import styles from './toggle.module.css'

const ToggleWithLabel = ({ title, subtitle, value, setValue }) => {
    return <div className={styles.wrapper}>
        <div className={styles.label}>
            <span className={styles.title}>{title} </span>
            <br/>
            <span className={styles.subtitle}>{subtitle}</span>
            </div>
        <Toggle scale={2} initialChecked={value} onChange={() => setValue(!value)}></Toggle>
    </div>
}

export { ToggleWithLabel, Toggle }