import axios from "axios"
import {buildQueryFromFilter} from "./filter"
import { useQuery } from "./useQuery";
import { generateConfig } from "./helpers";

const request = async (url, accessToken, data, method) => {
    const config = generateConfig(url, method, data, accessToken)
    const res = await axios(config)
    return res.data
}

const post = async (url, accessToken, data) => {
    return await request(url, accessToken, data, "post")
}

const get = async (url, accessToken) => {
    return await request(url, accessToken, null, "get")
}

const put = async (url, accessToken, data) => {
    return await request(url, accessToken, data, "put")
}

const del = async (url, accessToken) => {
    return await request(url, accessToken, null, "delete")
}

const Network = {
    post,
    get,
    put,
    del
}

export default Network
export { useQuery, buildQueryFromFilter }