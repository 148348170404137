const resolveURL = (url) => {
	return url

	// needed for non vercel deployments

	const firstPart = url.split("/")[0]

	switch (firstPart) {
		case "https:" || "http:":
			return url
		case "":
			const origin = process.env.API_URL || "https://" + process.env.VERCEL_URL
			if (origin) return origin + url
		default:
			return url
	}
}


const generateHeaders = (accessToken) => {
    return accessToken ? { authorization: `Bearer ${accessToken}` } : {}
}

const generateConfig = (url, method, data, accessToken) => {

    const config = {
        method,
        url: resolveURL(url),
        data, 
        headers: generateHeaders(accessToken)
    }

    return config

}


export { resolveURL, generateConfig }