import { Spacer } from "@geist-ui/core"
import { EditableData, Input, Button } from '../../../ui'
import styles from './modal.module.css'
import { PlantTypeList, plantTypes } from '../../../core/plant/types'
import { ToggleWithLabel } from "../../../ui"

const TypeStep = ({ onComplete, onChange }) => {

    const handler = type => {
        onChange("type", type)
        onComplete()
    }

    return <PlantTypeList onClick={handler}></PlantTypeList>
}


const NameStep = ({ onComplete, onChange, data }) => {

    return <>
        <form onSubmit={onComplete} className={styles.form}>
            <Input value={data?.title} onChange={e => { onChange('title', e.target.value) }} placeholder='My New Workflow'></Input>
        </form>
        <Spacer></Spacer>
        <Button onClick={onComplete} fullWidth>Next</Button>
    </>
}

const ConfirmationStep = ({ onComplete, data, setStep, onChange }) => {

    return <>
        <Spacer></Spacer>
        <EditableData tap={() => setStep('title')} icon='TextT' title='Name' subtitle={data?.title}></EditableData>
        <Spacer h={0.5}></Spacer>
        <EditableData tap={() => setStep('type')} icon='List' title='Type' subtitle={plantTypes[data?.type]?.title}></EditableData>
        <ToggleWithLabel
            setValue={val=>onChange("isHarvest", val)}
            value={data?.isHarvest}
            title={"Harvest Workflow"}
            subtitle={"Turn on if this workflow should apply to Harvests instead of Plants and Batches."}
        />
        <Spacer></Spacer>
        <Button fullWidth onClick={onComplete}>Create Workflow</Button>
    </>
}

export { ConfirmationStep, TypeStep, NameStep }