import {Spacer, Tooltip} from "@geist-ui/core"
import moment from "moment";
import { useState } from "react";

const DateDisplay = ({ date, disableToggle }) => {

    const [showRelative, setShowRelative] = useState(true)

    const momentDate = moment(date);
    const displayDate = momentDate.format('DD.MM.YYYY');
    const displayTime = momentDate.format('HH:mm ');

    const relativeDate = momentDate.fromNow();

    if (!date) return <div style={{ opacity: 0.5 }}>No Date</div>
    if (disableToggle) return <div>{relativeDate}</div>
    return (
        <Tooltip text="Click to toggle">
            <div onClick={() => setShowRelative(!showRelative)} style={{ cursor: "pointer" }}>
                {showRelative ? <div>{relativeDate}</div> :
                    <div>{displayDate} <Spacer inline></Spacer> {displayTime}</div>}
            </div>
        </Tooltip>
    );
}

export default DateDisplay