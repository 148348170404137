const fillFromObject = (data, object) => {
	if (!data) throw new Error("Cannot fill object with null data")
	const keys = Object.keys(data)
	keys.forEach((key) => {
		object[key] = data[key]
	})
}


export {
    fillFromObject
}