import { useState } from "react"
import { ListAction, SecondaryButton, LinkButton } from "../../ui"
import { Divider, Grid, Spacer, Link, useToasts, useClipboard, Display, Spinner } from "@geist-ui/core"
import { useUser } from "../../utils/useUser"
import QRCode from "react-qr-code"
import { useCurrentTeam } from "../../data/useTeam"
import Network from "../../services/network"
import { LinkSimple, QrCode, User } from "phosphor-react"

const Wrapper = ({ children, title, subtitle }) => {

    return <div style={{ textAlign: "center" }}>
        <Spacer />
        <h3>{title}</h3>
        <p style={{ fontSize: "17px", opacity: 0.6 }}>
            {subtitle}
        </p>
        <div style={{ textAlign: "left", padding: "10px" }}>
            <Grid.Container justify="center">
                {children}
            </Grid.Container>
            <Spacer h={2} />
        </div>
    </div>
}



const InviteMemberPopup = ({ onClose }) => {

    const { user } = useUser()
    const { team } = useCurrentTeam()
    const [loading, setLoading] = useState(false)
    const [mode, setMode] = useState('role')
    const [link, setLink] = useState()
    const { setToast } = useToasts()
    const { copy } = useClipboard()
    const handler = () => {
        copy(link)
        setToast({ text: 'Link Copied.', type: "success" })
    }

    const createShareLink = async (shareMode) => {

        setLoading(true)

        try {

            const invite = await Network.post('/api/invites', user.accessToken, {
                team: {
                    _id: team._id,
                    name: team.name,
                    logo: team?.logo,
                    location: team?.location
                },
                role: "ADMIN",
                maxUses: 1,
            })

            setLink(`${window.location.origin}/invites/${invite._id}`)
            setLoading(false)
            setMode(shareMode)

        } catch (error) {
            setLoading(false)
            setToast({ text: error.message, type: "error" })
        }
    }

    if (loading) return <Wrapper title="Scan to Join" subtitle="Show this QR code to anyone you want to share the space with">
        <Display>
            <Spinner />
        </Display>
    </Wrapper>
    if (mode === 'qr') return <Wrapper title="Scan to Join" subtitle="Show this QR code to anyone you want to share the space with">
        <Grid xs={24} justify="center">
            <QRCode width="100%" value={link} />
        </Grid>
        <Spacer h={2} />
        <Spacer h={2} />
        <SecondaryButton style={{ width: "100%" }} onClick={onClose}>Dismiss</SecondaryButton>
    </Wrapper>

    if (mode === 'role') return <Wrapper title="Choose Role" subtitle="Choose the permission level">
        <ListAction
            icon={User}
            style={{ width: "100%" }}
            subtitle="All permissions"
            title="Admin"
            tap={() => setMode()}
            variant="primary"
        />
        <ListAction
            icon={User}
            variant='disabled'
            style={{ width: "100%" }}
            subtitle="Coming Soon"
            title="Grower"
        />
        <ListAction
            icon={User}
            variant='disabled'
            style={{ width: "100%" }}
            subtitle="Coming Soon"
            title="Viewer"
        />
    </Wrapper>

    if (mode === 'link') return <Wrapper title="Link Generated" subtitle="Share the following link">
        <Link href={link}>{link.slice(0, 35)}... </Link>
        <Grid style={{ width: "100%" }} direction="column" alignItems="center">
            <Spacer />
            <Grid.Container justify="space-evenly">
                {/* <LinkButton onClick={shareWithBrowser} icon='Share'> Share</LinkButton> */}
                <LinkButton onClick={handler} icon='Copy'> Copy</LinkButton>
            </Grid.Container>
            <Spacer h={4} />
        </Grid>
        <SecondaryButton style={{ width: "100%" }} onClick={onClose}>Dismiss</SecondaryButton>

    </Wrapper>

    return <Wrapper title="Add Member" subtitle="Grant someone access to your organisation">
        <Divider />
        <Spacer />
        <ListAction
            icon={QrCode}
            info="14 days ago"
            style={{ width: "100%" }}
            subtitle="Scan with a camera app"
            title="Share with QR Code"
            tap={() => createShareLink('qr')}
            color="#8E51C8"
            variant="primary"
        />
        <ListAction
            icon={LinkSimple}
            info="14 days ago"
            style={{ width: "100%" }}
            subtitle="Generate a one-time link that expires after it's used."
            title="Share with Link"
            tap={() => createShareLink('link')}
            color="#8E51C8"
            variant="primary"
        />
    </Wrapper>
}

export default InviteMemberPopup