function handleValue(value) {
    if (value?.constructor === Array) {
        return value.map(handleValue).join(",")
    } else if (typeof value === "object") {
        if (!value.value && !value.value === 0) throw new Error("No value provided")
        const handledValue = handleValue(value.value)
        if (!value.operation) return handledValue
        return `${value.operation}(${handledValue})`
    } else return value
}

function handleKeyAndValue(type, data) {
    var output = ""
    const filterKeys = Object.keys(data)
    filterKeys.forEach(filterKey => {
        const value = data[filterKey]
        const handledValue = handleValue(value)
        output += type + "[" + filterKey + "]" + "=" + handledValue + "&"
    })
    return output
}

export { handleKeyAndValue }