import {motion} from "framer-motion"
import styles from './checkbox.module.css'

import { useState } from 'react'
import { Check } from '@geist-ui/icons'



const CheckBox = ({ checked, onClick }) => {

    const [isChecked, setIsChecked] = useState(checked)

    const checkVariants = {
        checked: {
            scale: 1,
            opacity: 1,
            transition: {
                scale: {
                    duration: 0.2,
                    ease: 'easeInOut'
                }
            }
        },
        unchecked: {
            scale: 0,
            opacity: 0,
            transition: {
                scale: {
                    duration: 0.2,
                    ease: 'easeInOut'
                }
            }
        }
    }

    return <div className={styles.checkbox} onClick={onClick} >
        <motion.div variants={checkVariants} animate={isChecked ? 'checked' : 'unchecked'} >
            <Check></Check>
        </motion.div>
    </div>
}

export default CheckBox