import {useState} from "react"

const useSelectMode = () => {
    const [selectMode, setSelectMode] = useState(false)
    const [selected, setSelected] = useState({})
    const [totalSelected, setTotalSelected] = useState(0)

    const checkForMembership = (member) => {
        const members = Object.keys(selected)
        return members.includes(member)
    }

    const toggleSelectMode = () => {
        setTotalSelected(0)
        setSelected({})
        setSelectMode(!selectMode)
    }

    const addToSelection = (id, type) => {
        var copiedSelection = { ...selected }
        copiedSelection[id] = type
        setTotalSelected(totalSelected + (type === 'batches' ? 0 : + 1))
        setTotalSelected(totalSelected + 1)
        setSelected(copiedSelection)
    }


    const removeFromSelection = (id) => {
        var copiedSelection = { ...selected }
        setTotalSelected(totalSelected + (copiedSelection[id] === 'batches' ? 0 : -1))
        delete copiedSelection[id]

        setSelected(copiedSelection)
    }

    const addManyToSelection = (objects) => {
        var counter = objects.length
        var copiedSelection = { ...selected }
        for (const i in objects) {
            const { id, type } = objects[i]
            copiedSelection[id] = type
            if (type === 'batches') {
                counter = counter - 1
            }
        }
        setSelected(copiedSelection)
        setTotalSelected(totalSelected + counter)
    }

    const removeManyFromSelection = (ids) => {
        var counter = ids.length
        var copiedSelection = { ...selected }
        for (const i in ids) {
            const id = ids[i]
            if (copiedSelection[id] === 'batches') {
                counter = counter - 1
            }
            delete copiedSelection[id]
        }
        setSelected(copiedSelection)
        setTotalSelected(totalSelected - counter)
    }

    return {
        selected,
        totalSelected,
        selectMode,
        checkForMembership,
        toggleSelectMode,
        addToSelection,
        removeFromSelection,
        addManyToSelection,
        removeManyFromSelection
    }
}

export default useSelectMode