import {useState} from "react"
import { ListAction, SecondaryButton, LinkButton } from "../../ui"
import { Divider, Grid, Spacer, Link, useToasts, useClipboard } from "@geist-ui/core"
import QRCode from "react-qr-code"
import { LinkSimple, QrCode } from "phosphor-react"

const Wrapper = ({ children, title, subtitle }) => {

    return <div style={{ textAlign: "center" }}>
        <Spacer />
        <h3>{title}</h3>
        <p style={{ fontSize: "17px", opacity: 0.6 }}>
            {subtitle}
        </p>
        <div style={{ textAlign: "left", padding: "10px" }}>
            <Grid.Container justify="center">
                {children}
            </Grid.Container>
            <Spacer h={2} />
        </div>
    </div>
}



const URLPopup = ({ onClose, link }) => {

    const [mode, setMode] = useState('role')
    const {setToast} = useToasts()
    const { copy } = useClipboard()
    const handler = () => {
        copy(link)
        setToast({ text: 'Link Copied.', type: "success" })
    }

    const createShareLink = (mode) => setMode(mode)

    if (mode === 'qr') return <Wrapper title="Scan to Join" subtitle="Show this QR code to anyone you want to share the space with">
        <Grid xs={24} justify="center">
            <QRCode width="100%" value={link} />
        </Grid>
        <Spacer h={2} />
        <Spacer h={2} />
        <SecondaryButton style={{ width: "100%" }} onClick={onClose}>Dismiss</SecondaryButton>
    </Wrapper>

    if (mode === 'link') return <Wrapper title="Link Generated" subtitle="Share the following link">
        <Link href={link}>{link.slice(0, 35)}... </Link>
        <Grid style={{ width: "100%" }} direction="column" alignItems="center">
            <Spacer />
            <Grid.Container justify="space-evenly">
                {/* <LinkButton onClick={shareWithBrowser} icon='Share'> Share</LinkButton> */}
                <LinkButton onClick={handler} icon='Copy'> Copy</LinkButton>
            </Grid.Container>
            <Spacer h={4} />
        </Grid>
        <SecondaryButton style={{ width: "100%" }} onClick={onClose}>Dismiss</SecondaryButton>

    </Wrapper>

    return <Wrapper title="Share URL" subtitle="How would you like to share this URL?">
        <Divider />
        <Spacer />
        <ListAction
            icon={QrCode}
            info="14 days ago"
            style={{ width: "100%" }}
            subtitle="Scan with a camera app"
            title="Share with QR Code"
            tap={() => createShareLink('qr')}
            color="#8E51C8"
            variant="primary"
        />
        <ListAction
            icon={LinkSimple}
            info="14 days ago"
            style={{ width: "100%" }}
            subtitle="Send this to anyone you like."
            title="Share with Link"
            tap={() => createShareLink('link')}
            color="#8E51C8"
            variant="primary"
        />
    </Wrapper>
}

export default URLPopup