import {Check, Clock, X} from "phosphor-react"
import styles from './verification-status.module.css'

const details = {
    'verified': {
        bg: 'rgba(0, 255, 0, 0.1)',
        colour: 'green',
        text: 'Verified',
        icon: Check
    },
    'unverified': {
        bg: 'rgba(255, 0, 0, 0.1)',
        colour: 'red',
        text: 'Unverified',
        icon: X
    },
    'pending': {
        bg: 'rgba(255, 255, 0, 0.1)',
        colour: 'yellow',
        text: 'Pending',
        icon: Clock
    }
}

const VerificationStatusTag = ({ status }) => {

    if (!status) return "Status Unknown"

    const statusDetails = details[status]

    return <div className={styles.container} style={{ background: statusDetails.bg, color: statusDetails.colour }}>
        <statusDetails.icon size={20} />
        {statusDetails.text}
    </div>
}


export { VerificationStatusTag }