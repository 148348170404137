import {Display, Grid, Spacer, Spinner} from "@geist-ui/core"
import { Input, SecondaryButton, Button, Warning } from "../../ui"
import { useState } from "react"
import { ModalHeader } from "./components"

const Wrapper = ({ children, variant, close, title }) => {
    return <div style={{ padding: "20px", textAlign: "center" }}>
        <ModalHeader
            variant={variant}
            style={{ width: "100%" }}
            subtitle={!variant ? "You can change Hub settings from within the Settings tab" : "Hubs enable you to feed sensor data into Cloner"}
            title={title ?? "Add Hub"}
        />
        <Spacer h={2} />
        {children}
        <SecondaryButton onClick={close} style={{ width: "100%" }}>
            Dismiss
        </SecondaryButton>
    </div>
}

const AddHubPopup = ({ title, key, data, callback, onClose }) => {

    const [loading, setLoading] = useState(false)
    const [newValue, setNewValue] = useState(data ?? "")
    const [done, setDone] = useState()
    const [hubId, setHubId] = useState("")
    const [error, setError] = useState()

    const submit = async () => {
        setLoading(true)

        try {
            await callback(hubId)
            setLoading(false)
            setDone(true)
        } catch (error) {
            setLoading(false)
            setError(error.message)
        }

    }
    if (loading) return <Wrapper>
        <Display>
            <Spinner />
        </Display>
    </Wrapper>

    if (error) return <Wrapper title='Failed to add hub.' variant={"error"} >
        {error}
        <Spacer h={2}></Spacer>
    </Wrapper>

    if (done) return <Wrapper title='Hub Added' variant={"done"} >
    </Wrapper>

    return <Wrapper close={onClose}>
        <div style={{ width: "100%", textAlign: "left" }}>
            <Grid.Container>
                <strong>Enter Hub ID:</strong>
                <Spacer h={2} />
                <Warning style={{ width: "100%" }} variant='info' text='Located on the device' />
                <Spacer h={0.5} />
                <Input fullWidth onChange={e => { setHubId(e.target.value) }} value={hubId} placeholder={"e.g XYZHDFDFD"} />
            </Grid.Container>
        </div>
        <Spacer h={1} />
        <Button onClick={submit} width='100%'>
            Add Hub
        </Button>
        <Spacer h={0.5} />
    </Wrapper>
}

export default AddHubPopup