import Skeleton from "react-loading-skeleton"
import { BarChart, YAxis, ResponsiveContainer, Tooltip, XAxis, Bar } from 'recharts';
import EmptyState from '../../ui/empty-state';

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div style={{ background: 'rgba(0, 0, 0, 0.8)', padding: '10px', backdropFilter: "blur(10px)", "-webbkit-backdrop-filter": "blur(10px)", color: "white", borderRadius: "8px" }}>
                <h3 style={{ margin: 0 }}>{data?.value} {data?.unit}</h3>
            </div>
        );
    }
    return null;
};

const CustomBar = (props) => {
    const { x, y, width, height } = props;
    const barStyles = {
        fill: "var(--color-brand-10)",
        strokeWidth: 1,
        stroke: 'var(--color-brand)',
        rx: 4, // Rounded corners
        ry: 4,
    };

    return <rect x={x} y={y} width={width} height={height} style={barStyles} />;
};

const SimpleBarChart = ({ data, dimensions }) => {
    if (!data) return <Skeleton width={dimensions?.width ? dimensions?.width + "px" : "100%"} height={dimensions?.height ? dimensions?.height + "px" : "300px"} />
    if (!data.length) return <EmptyState title='No data in the given range'>
    </EmptyState>

    return <>
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
                // width={500}
                // height={300}
                data={data}
                margin={{
                    top: 24,
                    right: 16,
                    left: -10,
                }}
            >
                {/* <CartesianAxis strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" />
                <YAxis unit={"Kg"} />
                <Tooltip content={<CustomTooltip />} />

                <Bar shape={<CustomBar></CustomBar>} style={{ borderRadius: "12px", position: "relative" }} dataKey="value" fill="var(--color-brand)" />
                {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
            </BarChart>
        </ResponsiveContainer></>
}


export { SimpleBarChart }