import useSWR, {useSWRConfig} from "swr"
import Plant from "../models/plant"
import { buildQueryFromFilter } from "../services/network"
import { useUser } from "../utils/useUser"
import Network from "../services/network"

const usePlants = (filter) => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const queryString = buildQueryFromFilter(filter)
    const url = filter ? '/api/v1/plants?' + queryString : '/api/v1/plants'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshPlants = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        plants: data?.map(plant => new Plant(plant)),
        plantsLoading: !data && !error,
        plantsError: error,
        refreshPlants
    }
}

const useActivePlants = () => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/plants/active'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshPlants = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        plants: data?.map(plant => new Plant(plant)),
        plantsLoading: !data && !error,
        plantsError: error,
        refreshPlants
    }
}



const useDonePlants = () => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/plants/done'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshPlants = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        plants: data?.map(plant => new Plant(plant)),
        plantsLoading: !data && !error,
        plantsError: error,
        refreshPlants
    }
}


const useCancelledPlants = () => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/plants/cancelled'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshPlants = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        plants: data?.map(plant => new Plant(plant)),
        plantsLoading: !data && !error,
        plantsError: error,
        refreshPlants
    }
}

const usePlantWithoutAuth = (id) => {
    const { data, error } = useSWR(id ? ['/api/v1/plants/' + id + '/public'] : null, Network.get)
    return {
        plant: {
            ...data?.plant,
            certificate: data?.certificate,
            team: data?.team,
            events: data?.events
        },
        plantLoading: !data && !error,
        plantError: error
    }
}

const usePlant = (id, initialData) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = id ? '/api/v1/plants/' + id : null
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get, { fallbackData: initialData })

    const refreshPlant = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        plant: data ? new Plant(data) : null,
        refreshPlant
    }
}

export { usePlants, usePlant, useActivePlants, useDonePlants, useCancelledPlants, usePlantWithoutAuth }