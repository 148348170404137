import { upperFirst } from "lodash"

class Validator {

    static validate = (config, data) => {
        if (!config || !data) throw new Error("Validator.validate: config and data are required")
        const dataKeys = Object.keys(data)

        dataKeys.forEach(key => {
            const currentConfig = config[key]
            if (!currentConfig) throw new Error(`Validator.validate: config for ${key} is not defined`)
            const { required, minLength, maxLength, validators } = config[key]
            const value = data[key]

            if (required && (value === '' || !value)) throw new Error(`${upperFirst(key)} cannot be empty`)
            if (minLength && value.length < minLength) throw new Error(`Validator.validate: ${key} must be at least ${minLength} characters long`)
            if (maxLength && value.length > maxLength) throw new Error(`Validator.validate: ${key} must be at most ${maxLength} characters long`)
            if (validators) {
                validators.forEach(validator => {
                    const {checker, errorText} = validator
                    if (!checker(value)) throw new Error(errorText)
                })
            }
        })
    }
}


export default Validator