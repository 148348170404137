import {usePlant} from "../../data"
import PlantLoader from "./loader"
import PlantListItem from "./list-item"
import { useRouter } from "next/router"

const WrappedPlant = ({ plantId, onClick, hide }) => {

    const router = useRouter()
    const { plant } = usePlant(plantId)

    if (!plant) return <PlantLoader />

    if (hide) {
        if (hide(plant)) return null
    }

    return <PlantListItem
        plant={plant}
        tap={onClick ? onClick : () => { router.push('/plants/' + plantId) }}
        time={"timeEstimate"}
    />
}

export default WrappedPlant