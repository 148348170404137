import {Popover} from "@geist-ui/core"
import styles from '../../table.module.css'
import Phosphor from "https://framer.com/m/Phosphor-9Q9Y.js@qyebYqHqVZaLb5Up06qa"
import { FilterSelectorPopover } from "../popovers"

const FilterButton = ({ active, text }) => {
    const color = active ? "#8E51C8" : "black"
    return <div className={styles.filterOption}>
        <Phosphor style={{ width: "18px", height: "18px", color }} color={color} name={"FunnelSimple"} />
        <span style={{ color, lineHeight: "18px" }}>{text}</span>
    </div>
}

const getFilterCount = (filter) => {
    const activeFilterKeys = Object.keys(filter)
    return activeFilterKeys.reduce((acc, key) => {
        if (key === "limit") return acc
        return acc + 1
    }, 0)
}

const FilterSelector = ({ onChange, filter, config }) => {

    const filterConfig = config.filter

    const filterCount = getFilterCount(filter)
    const filtersApplied = filterCount > 0

    return <Popover placement="bottomEnd" content={<FilterSelectorPopover filter={filter} onChange={onChange} config={filterConfig} />}>
        <FilterButton active={filtersApplied} text={filtersApplied ? `${filterCount} Filter${filterCount > 1 ? "s" : ""}` : "Filter"}>
        </FilterButton>
    </Popover>
}

export default FilterSelector