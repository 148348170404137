import {buttonVariants} from "@/client/ui"
import Link from "next/link"

type MenuItemProps = {
  url: string
  title: string
  Icon?: React.ComponentType<{size: number}>
  active?: boolean
  className?: string
  iconOnly?: boolean
}

const MenuItem = ({url, title, Icon, active, className, iconOnly, ...props}: MenuItemProps) => {
  return <Link className={`${buttonVariants({variant: active ? "secondary" : "ghost"})} ${className}`} href={url} {...props}>{Icon ? <Icon size={24}/> : null} {!iconOnly ? title : null}</Link>
}

export default MenuItem