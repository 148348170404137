import {useRouter} from "next/router"
import { buildQueryObjectFromURLParameters } from "./helpers";
import { buildQueryFromFilter } from "../filter";
import { useState, useEffect } from "react";

const useQuery = (defaultValue, syncURL) => {

    const router = useRouter()

    const [userOverride, setUserOverride] = useState(false)
    const isURLFilter = Object.keys(router.query).length > 0 && !router.query.id
  
    // Initial state is always defaultValue. We will adjust in useEffect if needed.
    const [query, setQuery] = useState({
        ...defaultValue
    });

    useEffect(() => {
        if(isURLFilter && syncURL && !userOverride) {
            const parsedURL = isURLFilter ? buildQueryObjectFromURLParameters(router.query) : {}
            setQuery((prevState) => ({
                ...prevState, 
                ...parsedURL,
            }));
        }
    }, [router.query, setQuery, isURLFilter, syncURL, userOverride]); // Trigger the effect whenever router.query changes

    const updateQuery = (providedQuery) => {
        if (syncURL) {
            const newQuery = buildQueryFromFilter(providedQuery)
            router.replace(router.pathname + newQuery, undefined, { shallow: true })
        }
        setUserOverride(true)
        // Now, always update query based on previous state
        setQuery((prevState) => ({
            ...prevState,
            ...providedQuery,
        }));
    }

    return {
        query,
        setQuery: updateQuery
    }

}


export { useQuery};