import {Grid, Spacer} from "@geist-ui/core"
import { Input, SecondaryButton, Button, Loader } from "../../ui"
import { useState } from "react"
import { ModalHeader } from "./components"

const RenamePopup = ({ title, key, data, callback, onClose, placeholder }) => {


    const [newValue, setNewValue] = useState(data ?? "")
    const [state, setState] = useState("start")
    const [res, setRes] = useState()

    const titles = {
        "start": {
            title: "Edit " + (title ? " " + title : ""),
            subtitle: "Change the contents of this field"
        },
        "loading": {
            title: "Applying Changes",
            subtitle: "Please wait..."
        },
        "success": {
            title: "Success",
            subtitle: "Changes applied successfully",
            variant: "done"
        },
        "error": {
            title: "Error",
            variant: "error",
            subtitle: res,
        }
    }

    const submit = async e => {
        if (e) e.preventDefault()
        setState("loading")
        try {
            await callback(newValue)
            setState("success")
        } catch (error) {
            setState("error")
            setRes(error.message)
        }
    }

    return <div style={{ padding: "20px", textAlign: "center" }}>
        <form onSubmit={submit}>
            <Grid.Container >
                <Grid xs={24} direction="column" >
                    <ModalHeader
                        variant={titles[state]?.variant}
                        style={{ width: "100%" }}
                        title={titles[state].title}
                        subtitle={titles[state].subtitle}>
                    </ModalHeader>
                    <Spacer h={2} />
                    {state === 'loading' ? <Loader /> : ""}
                    {state === "start" ? <>
                        <Input placeholder={placeholder ?? "Enter new name..."} value={newValue} onChange={e => setNewValue(e.target.value)} />
                        <Spacer />
                        <Spacer h={2} />
                        <Button loading={state === 'loading'} width={"100%"} onClick={submit}>Save Changes</Button>
                    </> : ""}
                    <Spacer h={0.5} />
                    <SecondaryButton width={"100%"} onClick={onClose}>Dismiss</SecondaryButton>
                </Grid>
            </Grid.Container>
        </form>
    </div>
}

export default RenamePopup