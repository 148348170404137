import {Grid} from "@geist-ui/core"
import styles from './table.module.css'
import { Pagination } from "@geist-ui/core"


const Footer = ({ query, setQuery, total }) => {
    const { skip=0, limit } = query ?? {}

    const handle = pageNumber => {
        const newSkip = pageNumber === 0 ? 0 : (pageNumber - 1) * limit
        if (newSkip === skip) return
        setQuery({ ...query, skip: newSkip })
        
    }

    return <Grid.Container className={styles.footer} justify="space-between" alignItems="center">
        {total ? <Grid direction='row' style={{ color: "var(--color-primary-50)", paddingLeft: "16px", marginBottom: "10px" }}>
            Showing {query?.skip}-{(query?.limit + query?.skip) > total ? total : (query?.limit + query?.skip)} of {total} results

        </Grid> : ""}
        <Grid>
            <Pagination page={((skip / limit) ?? 0) + 1} onChange={handle} count={Math.ceil(total / limit)} initialPage={((skip / limit) ?? 0) + 1} />
        </Grid>
        <Grid style={{opacity: 0}}>
        Showing {query?.skip}-{(query?.limit + query?.skip) > total ? total : (query?.limit + query?.skip)} of {total} results
        </Grid>

    </Grid.Container>
}

export default Footer