import {Grid, User} from "@geist-ui/core"
import { LogoIcon } from "../components/logo"
import { VerificationStatusTag } from "../components/verification-status"
import styles from './wrappers.module.css'

const ReportPageHeader = ({ title, verificationStatus, team }) => {

    return <Grid.Container className={styles.reportPageHeader} justify="space-between" alignItems="center">
        <Grid>
            <Grid.Container gap={2} alignItems='center'>
                <Grid>
                    <LogoIcon style={{ width: "32px" }} />
                </Grid>
                <Grid>
                    <h5 style={{ margin: 0 }}>{title ?? "Untitled Report"}</h5>
                    <h5 style={{ margin: 0, opacity: 0.5 }}>{team?.name}</h5>
                </Grid>
            </Grid.Container>
        </Grid>
        <Grid>
            <VerificationStatusTag status={verificationStatus}></VerificationStatusTag>
        </Grid>
    </Grid.Container>
}

const ReportPage = ({ children, verificationStatus, team, title }) => {
    return <div>
        <ReportPageHeader title={title} team={team} verificationStatus={verificationStatus} />
        <div className={styles?.reportPage}>
            {children}
        </div>
    </div>
}

export default ReportPage