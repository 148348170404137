import {useState} from "react"
import { EditableData } from "../../ui"

import { Camera, CameraResultType } from '@capacitor/camera';

import { useToasts } from "@geist-ui/core";

const WrappedUpload = ({ callback, title, providedImage, icon }) => {

    const [image, setImage] = useState(providedImage)
    const {setToast} = useToasts()

    const takePicture = async () => {
        try {
            const photo = (await Camera.getPhoto({
                quality: 90,
                allowEditing: true,
                resultType: CameraResultType.DataUrl
            })).dataUrl

            setImage(photo)
            callback(photo)
            
        } catch (error) {
            setToast({type: "error", text: error.message})
        }
    }

    return <div style={{ alignItems: "center" }}>
        <EditableData icon={icon ?? "Image"} image={image ?? providedImage} variant={(image ?? providedImage) ? "photo" : "primary"} tap={takePicture} title={title ?? "Add Photo (optional)"} actionText={(image ?? providedImage) ? "Replace" : "Take Photo"} subtitle={(image ?? providedImage) ? "Photo Added" : "Tap to add"} />
    </div>

}

export default WrappedUpload