import { Popover, useClipboard, useToasts } from "@geist-ui/core"
import { useRouter } from "next/router"
import { DotsThree, DotsThreeVertical } from "phosphor-react"
import { getUserCookies } from "../../../../auth"

const ShareFilterPopover = () => {
    const router = useRouter()
    const { copy } = useClipboard()
    const { setToast } = useToasts()

    const shareFilteredView = () => {
        try {
            copy(window.location.href)
            setToast({
                type: "success",
                text: "Link copied to clipboard"
            })
        } catch (e) {
            setToast({ type: "error", text: "Failed to copy link to clipboard" })
        }
    }

    const downloadCSV = async () => {
        try {
            const { user } = await getUserCookies()
            const queryParams = window.location.search
            const url = window.location.origin + "/api/v2/csv/" + router.pathname + (queryParams ? queryParams + "&" : "?") + "token=" + user?.accessToken
            window.open(url, "_blank")
            setToast({
                type: "success",
                text: "Link copied to clipboard"
            })
        } catch (e) {
            setToast({ type: "error", text: "Failed to copy link to clipboard" })
        }
    }

    const content = <div style={{ width: "120px" }}>
        <Popover.Option title>Options</Popover.Option>
        <Popover.Item onClick={shareFilteredView}>Share Filtered View</Popover.Item>
        <Popover.Item onClick={downloadCSV}>Download CSV</Popover.Item>
    </div>

    return <Popover portalClassName="popoverFrame" placement="bottomEnd" content={content}>
        <DotsThreeVertical style={{ cursor: "pointer", fontWeight: 700, color: "var(--color-brand)" }} size={24} weight='bold'/>
    </Popover>
}

export default ShareFilterPopover