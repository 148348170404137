import {useState} from "react"

import { Button, SecondaryButton, Loader } from '../../ui'
import { Spacer } from '@geist-ui/core'

import { ModalHeader } from './components'
import WrappedUpload from '../upload'
import { uploadFromDataURI } from '../../utils/image-upload'
import { useUser } from '../../utils/useUser'
import Network from '../../services/network'

const VerifyGeneticsPopup = ({ callback, genetic }) => {

    const { user } = useUser()

    const [step, setStep] = useState('first')
    const [error, setError] = useState()
    const [data, setData] = useState({
        branding: "",
        seeds: "",
        identifier: ""
    })

    const onComplete = async () => {

        if (data.branding === "" || data.seeds === "" || data.identifier === "") {
            return
        }

        setStep('loading')

        try {

            const brandingImage = await uploadFromDataURI(data.branding)
            const seedImage = await uploadFromDataURI(data.seeds)
            const identifierImage = await uploadFromDataURI(data.identifier)

            await Network.post(
                '/api/v1/genetics/' + genetic._id + '/verification',
                user.accessToken,
                {
                    brandingImage,
                    seedImage,
                    identifierImage
                }
            )
            if (callback) await callback()
            setStep('done')

        } catch (error) {
            setError(error.message)
            setStep('error')
        }
    }

    const Wrapper = ({ children, title, subtitle, variant }) => {
        return <div style={{ padding: "30px" }}>
            <ModalHeader variant={variant} style={{ width: "100%" }} subtitle={subtitle ?? "We need a clear photos of the seed packaging"} title={title ?? "Verify Seeds"} />
            <Spacer h={2} />
            {children}
        </div>
    }


    if (step === 'error') return <Wrapper variant='error' title='Submission Failed.' subtitle={error}>
        <SecondaryButton fullWidth onClick={close}>Dismiss</SecondaryButton>
    </Wrapper>


    if (step === 'loading') return <Wrapper title='Submitting Request' subtitle='Your images are on the way.'>
        <Loader></Loader>
    </Wrapper>

    if (step === 'done') return <Wrapper variant='done' done title='Request Submitted' subtitle='Your images have been sent.'>
        <SecondaryButton fullWidth onClick={close}>Dismiss</SecondaryButton>
    </Wrapper>

    return <Wrapper>
        <WrappedUpload providedImage={data.branding} callback={image => setData({ ...data, branding: image })} title='Provider Branding'></WrappedUpload>
        <Spacer h={0.5} />
        <WrappedUpload providedImage={data.identifier} callback={image => setData({ ...data, identifier: image })} title='Batch ID / Unique ID'></WrappedUpload>
        <Spacer h={0.5} />
        <WrappedUpload providedImage={data.seeds} callback={image => setData({ ...data, seeds: image })} title='Unopened Seeds'></WrappedUpload>
        <Spacer h={2}></Spacer>
        <Button onClick={onComplete} fullWidth>
            Request Verification
        </Button>
        <Spacer h={0.5}></Spacer>
        <SecondaryButton onClick={close} fullWidth>
            Cancel
        </SecondaryButton>
    </Wrapper>

}

export default VerifyGeneticsPopup