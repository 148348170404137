import { Grid, Spacer } from "@geist-ui/core"
import { EditableData, Input, ListAction, Button } from '../../../ui'
import styles from './modal.module.css'
import { useState } from "react"
import { typeOptions } from "../../../../common/variables"

const TypeStep = ({ onComplete, onChange }) => {

    const [other, setOther] = useState(false)
    const [otherType, setOtherType] = useState(null)
    const [inputError, setInputError] = useState(null)

    const handleSelection = async (key) => {
        await onChange('type', key)
        onComplete()
    }

    const handleOtherInput = (value) => {
        if (!value) { setOtherType(value); return }
        if (!value.match(/^[_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*$/)) {
            setInputError("Invalid character")
            return
        }
        setInputError(null)
        setOtherType(value)

    }

    if (other) {
        return <Grid.Container direction='column'>
            <p style={{ margin: 0, color: "var(--color-primary-50)", fontWeight: 500 }}>
                Enter an identifier for your sensor type. You can use this identifier in future to group sensors of the same type. E.g <code>soil_moisture</code>
            </p>
            <Spacer></Spacer>
            <div style={{ color: "red" }}>{inputError}</div>
            <Spacer h={.5} />
            <Input name='type' value={otherType} onChange={e => handleOtherInput(e.target.value)} onEnter={() => handleSelection(otherType)}></Input>
            <Spacer h={2} />
            <Button fullWidth onClick={() => handleSelection(otherType)}>Next</Button>
        </Grid.Container>
    }

    return <Grid.Container direction='column'>
        <div style={{ maxHeight: "160px", overflow: "scroll" }}>
            {Object.keys(typeOptions).map((key, index) => {
                return <ListAction icon={typeOptions[key]?.icon} key={index} tap={() => { handleSelection(key) }} title={typeOptions[key].title}></ListAction>
            })}
            <ListAction icon='Plus' tap={() => { setOther(true) }} title='Other'></ListAction>
        </div>
    </Grid.Container>
}


const NameStep = ({ onComplete, onChange, data }) => {

    return <>
        <form onSubmit={onComplete} className={styles.form}>
            <Input value={data?.name} onChange={e => { onChange('name', e.target.value) }} placeholder='My New Sensor'></Input>
        </form>
        <Spacer></Spacer>
        <Button onClick={onComplete} fullWidth>Next</Button>
    </>

}

const UnitStep = ({ onComplete, onChange, data }) => {

    const [otherUnit, setOtherUnit] = useState(null)

    const handleSelection = (key) => {
        onChange('unit', key)
        onComplete()
    }

    if (typeOptions[data.type]) {
        onChange('unit', typeOptions[data.type].unit)
        onComplete()
    }

    return <Grid.Container direction='column'>
    <p style={{ margin: 0, color: "var(--color-primary-50)", fontWeight: 500 }}>
        Enter the unit of your custom type. If you&apos;ve used this type before, ensure make sure they&apos;re the same. E.g <code>°C</code>
    </p>
    <Spacer></Spacer>
    <Input name='type' value={otherUnit} onChange={e => setOtherUnit(e.target.value)} onEnter={() => handleSelection(otherUnit)}></Input>
    <Spacer h={2} />
    <Button fullWidth onClick={() => handleSelection(otherUnit)}>Next</Button>
</Grid.Container>

}

const ConfirmationStep = ({ onComplete, data, setStep }) => {

    return <>
        <EditableData tap={() => setStep('title')} icon='TextT' title='Name' subtitle={data?.name}></EditableData>
        <Spacer h={0.5}></Spacer>
        <EditableData tap={() => setStep('type')} icon='List' title='Type' subtitle={typeOptions[data?.type]?.title ?? data.type}></EditableData>
        <Spacer h={0.5}></Spacer>
        <EditableData tap={() => setStep('unit')} icon='Ruler' title='Unit' subtitle={typeOptions[data?.type]?.unit ?? data?.unit}></EditableData>
        <Spacer></Spacer>
        <Button fullWidth onClick={onComplete}>Add Sensor</Button>
    </>
}

export { ConfirmationStep, TypeStep, NameStep, UnitStep }