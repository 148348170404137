import { Grid, Link, Spacer } from "@geist-ui/core"
import styles from '../table.module.css'
import { ActiveFilters, FilterSelector, ShareFilterPopover } from "./filter"


const Toolbar = ({ query, setQuery, config, selectModeData, children, syncURL, style }) => {

    const { totalSelected, toggleSelectMode, selectMode } = selectModeData ?? { selectMode: false }

    if (!config) return "No Filter Options"
    if (!query) return "No Filter"
    if (!setQuery) return "No Updator"

    const updateFilter = (key, value) => {
        setQuery({ ...query, filter: { ...query.filter, [key]: value }, skip: 0 })
    }

    const removeKey = (key) => {
        const newFilter = { ...query.filter }
        delete newFilter[key]
        setQuery({ ...query, filter: newFilter, skip: 0 })
    }

    return <Grid.Container className={styles.toolbar} style={style}>
        <Grid xs={24} direction='row'>
            <Grid.Container alignItems="center" justify="space-between">
                <ActiveFilters config={config} filter={query.filter} onRemove={removeKey}></ActiveFilters>
                <Grid direction="row">
                    <Grid.Container gap={2} direction="row">
                        {children}
                    </Grid.Container>
                </Grid>
                <Grid alignItems='center' justify="flex-end" direction="row">
                    {selectMode ?
                        "" : <FilterSelector filter={query.filter} config={config} onChange={updateFilter}></FilterSelector>
                    }
                    {selectMode && <>{totalSelected} selected</>}
                    <Spacer inline w={0.5} />
                    {selectModeData &&
                        <div style={{ color: "var(--color-brand)", fontWeight: 500, margin: 0, padding: 0, display: "inline" }} onClick={toggleSelectMode} href='#'>
                            {selectMode ? "Cancel" : "Select"}
                        </div>}
                    {!selectMode ? <>{syncURL ? <ShareFilterPopover></ShareFilterPopover> : ""}</> : null}
                </Grid>
            </Grid.Container>
        </Grid>
    </Grid.Container>
}

export default Toolbar