const CurrentStatus = ({status, workflow}) => {
	if (!status) return <span style={{color: "grey"}}>Unknown</span>
	const phase = workflow?.steps?.[status] ?? {title: "Unknown"}
	return (
		<span style={{color: phase?.colour}}>
			{phase?.title === "Unknown" ? status : phase?.title}
		</span>
	)
}


export default CurrentStatus