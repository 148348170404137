import {Popover} from "@geist-ui/core"
import { DayPicker } from 'react-day-picker'
import { useState } from "react"
import { LinkButton, Button } from "../../button"

const DateFilterPopover = ({ onChange, value }) => {

    const [range, setRange] = useState({
        from: value?.value?.[0]?.value ? new Date(value?.value?.[0]?.value) : undefined,
        to: value?.value?.[1]?.value ? new Date(value?.value?.[1]?.value) : undefined
    })

    return <>
    
        <DayPicker onSelect={days => {

            const copiedRange = { ...(range ?? {}) }
            copiedRange.from = days?.from
            copiedRange.to = days?.to
            setRange(copiedRange)

        }} selected={range} mode='range' />

        <Button onClick={() => {
            onChange({
                operation: "and",
                value: [{
                    operation: "gt",
                    value: range.from.getTime()
                }, {
                    operation: "lt",
                    value: range.to.getTime()
                }]
            })
        }} fullWidth>Save</Button>
    </>
}

const PopoverValueList = ({ values, onChange }) => {

    const valueKeys = Object.keys(values)

    return <div>
        {valueKeys.map(valueKey => {
            return <div onClick={() => onChange(valueKey)} key={valueKey}>
                {typeof values[valueKey].title === "string" ?
                    <Popover.Item>
                        {values[valueKey]?.title}
                    </Popover.Item> : values[valueKey]?.title
                }
            </div>
        })}
    </div>
}


const FilterSelectorPopover = ({ config, onChange, filter }) => {

    const [detail, setDetail] = useState()
    const options = Object.keys(config ?? {})

    const Wrapper = ({ children }) => {
        return <div style={{ minWidth: "250px", padding: "20px" }}>
            {detail ? <>
                <LinkButton icon='CaretLeft' onClick={() => setDetail()}>Back</LinkButton>
                <Popover.Item title><strong>{config[detail].title}</strong> </Popover.Item>
            </> : ""}
            {children}
        </div>
    }

    if (!detail) {
        return <Wrapper>
            {options.length === 0 && <div>No Filter Options</div>}
            {options.map(option => {
                if (config[option]?.getListItem) return config[option].getListItem(setDetail)
                return <Popover.Item disableAutoClose onClick={() => setDetail(option)} key={option} />
            })}
        </Wrapper>
    }

    const activeItem = config[detail]

    return <Wrapper>
        {activeItem.getPopover(onChange, filter, config, detail)}
    </Wrapper>

}

export { DateFilterPopover, PopoverValueList, FilterSelectorPopover }