import Loader from "../loader"
import { Steps } from "./types"

const defaultSteps: Steps = {
    done: {
        title: 'Success',
        subtitle: "",
        variant: "done",
        View: () => {
            return ""
            // return <Button onClick={() => router.push('/')}>View Workflow</Button>
        }
    },
    error: {
        title: "Something went wrong",
        variant: "error",
        subtitle: "Please try again",
        View: ({ res }) => <>{res}</>
    },
    loading: {
        title: "Making stuff happen",
        View: Loader
    }
}

export default defaultSteps