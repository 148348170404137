import { Grid, Spacer } from "@geist-ui/core"

const DoneStep = () => {
    return  <Grid.Container justify="center">
        <Spacer h={2} />
    </Grid.Container>
}


const doneStep = {
    View: DoneStep,
    title: "Genetic Added",
    subtitle: "You can see it in your Genetic library",
}

export default doneStep