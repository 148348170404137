import styles from "./tab-bar.module.css"
import { useRouter } from 'next/dist/client/router';
import Bar from "https://framer.com/m/Tab-Bar-xtes.js@BLHm6iRGgju1kpC7x0tt"
import { MenuItem } from '../../ui';
import { Calendar, Cpu, Folder, GitMerge, GridFour, House, Leaf, Link, List, Scissors, Target, Timer } from "phosphor-react";

const menuSections = {
    "overview": {
        steps: {
            "overview": {
                title: "Overview",
                icon: "House", 
                Icon: House
            },
            "spaces": {
                title: "Spaces",
                icon: "GridFour", 
                Icon: GridFour, 
            },
        },
        
    },
    "cultivation": {
        steps: {
            "genetics": {
                title: "Genetics",
                icon: "GitMerge", 
                Icon: GitMerge
            },
            "plants": {
                title: "Plants",
                icon: "Leaf",
                Icon: Leaf
            },
            "batches": {
                title: "Batches",
                icon: "Folder", 
                Icon: Folder
            },
            "harvests": {
                title: "Harvests",
                icon: "Scissors",
                url: "/harvests?sort[created]=-1&filter[status]=eq(harvested)&", 
                Icon: Scissors
            }
        }
        // "settings": {
        //     title: "Settings",
        //     icon: "GearSix"
        // }
    },
    "actions": {
        steps: {
            "events": {
                title: "Events",
                icon: "Timer",
                Icon: Timer
            },
            "tasks": {
                title: "Tasks",
                icon: "Target",
                url: "/tasks?sort[created]=-1&filter[status]=eq(created)&", 
                Icon: Target
            },
            "workflows": {
                title: "Workflows",
                icon: "List",
                Icon: List
            },
        },
    },
    "integrations": {
        steps: {
            "sensors": {
                title: "Sensors",
                icon: "Cpu",
                Icon: Cpu
            }, 
            "webhooks": {
                title: "Webhooks",
                icon: "Link",
                Icon: Link
            }
        }
    }, 
    "calendar": {
        steps: {
            "calendar": {
                title: "Calendar",
                icon: "Calendar",
                Icon: Calendar
            }
        }
    }
    // "settings": {
    //     title: "Settings",
    //     icon: "GearSix"
    // }

}


const DesktopMenu = (props) => {

    const router = useRouter()

    return <div style={{ width: "100%", display: props?.horizontal ? "flex" : "block", direction: "row", justifyContent: "center" }}>
        {Object.keys(menuSections).map(section => {
            const { steps } = menuSections[section]
            return <div key={section} style={{ marginBottom: "32px" }}>
                {Object.keys(steps).map(step => {
                    const isActive = step === 'overview' ? router.pathname.split('/')[1] === "" : router.pathname.split("/")[1] === step
                    const override = steps?.[step]?.url
                    const link = override ? override : "/" + step
                    const dmi = <MenuItem
                        title={steps[step].title}
                        active={isActive}
                        iconOnly={props?.iconOnly}
                        Icon={steps[step].Icon}
                        style={{ width: "100%", justifyContent: "start", gap: 8 }}
                        {...props}
                        url={link} />
                    if (props?.horizontal) {
                        return <div key={step} style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            {dmi}
                        </div>
                    }
                    return <div key={step}>{dmi}</div>
                })}</div>
        })}
    </div>

}



const TabBar = () => {
    const router = useRouter()

    const navigate = (route) => {
        router.push(route)
    }

    return <div className={styles.bar}>
        <Bar
            style={{ zIndex: 200, width: "100%" }}
            variant={router.pathname.split('/')[1]}
            onTapGenetics={() => navigate('/genetics')}
            onTapOverview={() => navigate('/')}
            onTapBatches={() => navigate('/batches')}
            onTapPlants={() => navigate('/plants')}
            onTapSpaces={() => navigate('/spaces')}
        />
    </div>
}

const Item = ({ title, icon, href }) => {
    const router = useRouter()
    return <div style={{ color: router.pathname === href ? 'green' : null }} onClick={() => { router.push(href) }} className={styles.tabItem}>
        {icon}
        {title}
    </div>
};

TabBar.Item = Item;
export default TabBar
export { DesktopMenu }