import "../client/styles/globals.css"
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-day-picker/dist/style.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import 'react-day-picker/style.css'
import { useEffect, useState } from 'react';
import { GeistProvider, CssBaseline, useMediaQuery } from '@geist-ui/core'
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { Page } from '../client/wrappers';
import { useRouter } from 'next/router';
import { Loader } from '../client/ui';

import { Analytics } from '@vercel/analytics/react';
import { ThemeProvider } from "@/client/wrappers/components/theme-provider";

const hideUIFor = ['auth', 'login', 'strains', 'landing', 'hand-off', 'teams', 'invites', 'pdf', 'report', 'public', 'capture']

const shouldHideUI = (router) => {
  let shouldHide = false
  hideUIFor.forEach(u => {
    if (router?.pathname.includes(u)) shouldHide = true
  })
  return shouldHide
}

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const [modal, setModal] = useState()
  const close = () => setModal()
  const isMobile = useMediaQuery('sm', { match: 'down' })
  const [tabs, setTabs] = useState()
  const [fullScreenModal, setFullScreenModal] = useState(false)

  useEffect(() => { defineCustomElements(window) });

  const hideUI = shouldHideUI(router)

  if (hideUI) return <GeistProvider>
    <CssBaseline />
    <Component modal={modal} setModal={setModal} close={close}   {...pageProps} />
    <Analytics />
  </GeistProvider >

  if (fullScreenModal) {
    return <GeistProvider>
      <CssBaseline />
      <div className='fullscreen'>
        <Loader></Loader>
        Sorting you out right quick...
      </div>
      <Component setTabs={setTabs} tabs={tabs} isMobile={isMobile} modal={modal} setModal={setModal} close={close}  {...pageProps} />
      <Analytics />
    </GeistProvider>
  }

  return <ThemeProvider
    attribute="class"
    defaultTheme="light"
    enableSystem
    disableTransitionOnChange>
    <Page setFullScreenModal={setFullScreenModal} tabs={tabs} isMobile={isMobile} setModal={setModal} closeModal={close} modal={modal}>
      <Component setFullScreenModal={setFullScreenModal} setTabs={setTabs} tabs={tabs} isMobile={isMobile} modal={modal} setModal={setModal} close={close}  {...pageProps} />
      <Analytics />
    </Page>
  </ThemeProvider>
}


export default MyApp
