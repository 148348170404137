import {Storage} from "@capacitor/storage"

const get = async (key) => {
    return (await Storage.get({ key }))?.value
}

const set = async (key, value) => {
    await Storage.set({ key, value })
}

const remove = async (key) => { await Storage.remove({ key }) }

const getJSON = async (key) => {
    const cookie = await get(key)
    try { return JSON.parse(cookie) }
    catch (error) { return null }
}

const setJSON = async (key, value) => {
    await Storage.set({ key, value: JSON.stringify(value) })
}

const local = {
    get,
    set,
    remove,
    getJSON,
    setJSON
}


export default local