import {House, Lightbulb, PlugsConnected, Sun} from "phosphor-react"
import { Card } from '../../../ui'
import styles from './card.module.css'

const SensorIndicator = ({ sensors, white }) => {
    if (sensors?.length > 0) return <div className={styles.sensorIndicator}>
        <PlugsConnected color={white ? "white" : "var(--color-success)"} size={24} />
    </div>
    return <></>
}

const ConditionsIndicator = ({ config }) => {
    const {Icon, text} = config ?? {}
    return <div className={styles?.conditionsIndicator}>
        {Icon ? <Icon></Icon> : <></>}
        {text}
    </div>
}

const conditionConfig = {
    "indoor": {
        text: "Indoors",
        Icon: Lightbulb
    }, 
    "outdoor": {
        text: "Outdoors",
        Icon: Sun
    },
    "greenhouse": {
        text: "Greenhouse",
        Icon: House
    }
}


const SpaceCard = ({ space, onClick }) => {
    return <Card onClick={onClick} style={{ background: space?.image ? `url(${space?.image}) rgba(0, 0, 0, 0.4) ` : "", backgroundBlendMode: "multiply", color: space?.image ? "white" : "black", backgroundSize: "cover", backgroundPosition: "center" }} className={styles.container} >
        <SensorIndicator white={space?.image} sensors={space?.sensors} />
        <ConditionsIndicator config={conditionConfig[space?.conditions]}></ConditionsIndicator>
        <h2 style={{ margin: 0, lineHeight: "1", textAlign: "center" }}>{space?.name}</h2>
        <div style={{opacity: 0.5, fontWeight: 500}}>{space?.plants?.length} Plants</div>
    </Card>
}


export default SpaceCard