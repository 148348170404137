import {NameStep, TypeStep, ConfirmationStep, UnitStep} from "./views"


const steps = {
    title: {
        title: 'Add a Sensor',
        subtitle: 'Give your sensor a name.',
        onComplete: setStep => setStep('type'),
        View: NameStep
    }, 
    type: {
        title: 'Choose a Sensor Type',
        subtitle: 'This helps us show you meaningful data',
        onComplete: (setStep, submit, {data, setData}) => {
            setStep('unit')
        },
        View: TypeStep
    },
    unit: {
        title: 'Unit of Measurement',
        subtitle: 'Will be used to display the sensor data.',
        onComplete: setStep => setStep('confirm'),
        View: UnitStep
    },
    confirm: {
        title: "Confirm Details",
        subtitle: "Check everything looks good",
        onComplete: (setStep, submit) => submit(),
        View: ConfirmationStep
    }
}

export default steps