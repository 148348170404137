import {useState} from "react"
import moment from 'moment'

import { ListAction, EditableData, Input, Button, SecondaryButton, Loader } from '../../ui'
import { Spacer, Grid } from '@geist-ui/core'
import { DayPicker } from 'react-day-picker'

import { getEventsAvailableToUser } from '../../utils/event'

import WrappedUpload from '../upload'
import { v4 } from 'uuid'

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { CalendarBlank, UploadSimple } from "phosphor-react"


function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });


}

const uploadBlob = async (blob) => {
    const storage = getStorage();
    const storageRef = ref(storage, v4());
    return uploadBytes(storageRef, blob).then((snapshot) => {

        return getDownloadURL(snapshot.ref).then(url => {
            return url
        })
    });
}


const PlantUpdateForm = ({ callback, close, space, type }) => {

    const eventCategories = getEventsAvailableToUser()

    const [loading, setLoading] = useState(false)
    const [confirmation, setConfirmation] = useState(!!type ?? false)
    const [pickingDate, setPickingDate] = useState(false)

    const [data, setData] = useState({
        date: Date.now(),
        description: "",
        category: type ?? "",
        photo: ""
    })


    const upload = async (image) => {
        const blob = dataURItoBlob(data.photo)
        const imageUrl = await uploadBlob(blob)
        return imageUrl
    }

    const handle = async () => {
        setLoading(true)
        if (data.photo !== "") {
            try {
                const photo = await upload(data?.photo)
                await callback({ ...data, photo })
            } catch (err) {
                console.log(err)
            }
        } else {
            await callback(data)
        }
        setLoading(false)
    }

    const handleData = e => {
        var copiedData = { ...data }
        copiedData[e.target.name] = e.target.value
        setData(copiedData)
    }

    const changeCategoryHandler = val => {
        setData({ ...data, category: val })
        setConfirmation(true)
    }


    if (loading) return <div style={{ padding: "20px", height: "400px" }}>
        <Loader></Loader>
    </div>


    if (pickingDate) return <div style={{ padding: "20px", textAlign: "center" }}>
        <h3>Choose Date</h3>
        <p style={{ opacity: 0.4 }}>Select a date for your {eventCategories[data.category].title}</p>
        <DayPicker selected={Date(data.date)}
            onDayClick={day => {
                setData({ ...data, date: day.getTime() });
                setPickingDate(false);
            }} />
    </div>

    if (confirmation) return <div style={{ padding: "20px", }}>
        <Grid.Container direction='column' alignContent='center' alignItems='center'>
            <h3>Confirm Details</h3>
            <p style={{ opacity: 0.4, margin: 0 }}>Choose an action from the list below. </p>
            <Spacer h={2} />
        </Grid.Container>
        <EditableData variant={!!type ? 'Variant 2':'primary'} tap={() => setConfirmation(false)} title="Update Type" icon={UploadSimple} subtitle={eventCategories[data.category].title} />
        <Spacer />
        <EditableData tap={() => { setPickingDate(true) }} title="Date" icon={CalendarBlank} subtitle={moment(data.date).format("DD.MM.yyyy")} />
        <Spacer />
        <WrappedUpload callback={photo => setData({ ...data, photo })} />
        <Spacer />
        <Input style={{ width: "90%" }} placeholder="Add a note (optional)" value={data.description} onChange={handleData} name='description' />
        <Spacer />
        <Spacer h={2} />
        <Button width={"100%"} onClick={handle}>Post Update</Button>


    </div>

    return <div style={{ padding: "20px" }}>
        <Grid.Container direction='column' alignContent='center' alignItems='center'>
            <h3 style={{ margin: 0 }}>Post an Update</h3>
            <p style={{ opacity: 0.4, fontSize: "17px" }}>Choose an action from the list below. </p>
        </Grid.Container>
        <form onSubmit={handle}>
            <Grid.Container>
                {Object.keys(eventCategories).map((key) => {
                    return <ListAction
                        key={key}
                        tap={() => { changeCategoryHandler(key) }}
                        color={eventCategories[key]?.color}
                        icon={eventCategories[key].icon}
                        subtitle={eventCategories[key].description}
                        title={eventCategories[key].title} />
                })}
            </Grid.Container>
            <Spacer h={2} />
            <SecondaryButton style={{ width: "100%" }} onClick={close}>Cancel</SecondaryButton>
        </form>
    </div>

}

export default PlantUpdateForm