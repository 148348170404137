import { useEffect, useState } from "react"
import { NextRouter, useRouter } from "next/router"
import { getUserCookies, logout } from '../auth'
import AuthUtils from "../auth"
import { User } from "../components/user/types"

const noRedirectFor = ['/login', '/signup', '/invites/[id]', 'public']

const determineIfRoutable = (router: NextRouter) => {
    return !noRedirectFor.includes(router.route) && !router.pathname.includes("pdf")
}

const resolveRedirect = (userData: User, router: NextRouter) => {
    const refreshTokenExists = !userData.refreshToken
    const isTestEnv = process.env.NODE_ENV === 'test'

    const isReroutable = determineIfRoutable(router)

    if (!refreshTokenExists && !isTestEnv) return false
    if (!isReroutable) return false
    return true
}

const getExistingUser = async () => {
    const foundUser = (await getUserCookies()) ?? {}
    return foundUser
}

const useUser = ({ redirect } = { redirect: true }) => {

    const router = useRouter()
    const [user, setUser] = useState()
    const [refreshing, setRefreshing] = useState(false)

    const handleLogout = () => {
        logout()
        router.push('/auth')
    }

    useEffect(() => {

        const handleUser = async () => {
            if (!user) {
                const { user, refreshToken } = await getExistingUser()
                if (!user) {
                    if (!refreshToken) {
                        const shouldRedirect = redirect ? resolveRedirect(user, router) : false
                        if (shouldRedirect) router.push('/auth?url=' + router.asPath)
                    } else {
                        if (!refreshing) {
                            setRefreshing(true)
                            try {
                                await AuthUtils.refreshToken(refreshToken)
                            } catch {
                                handleLogout()
                            }
                            setRefreshing(false)
                        }
                    }
                } else setUser({ ...user, refreshToken })
            }
        }

        handleUser()

    }, [router, user, refreshing, redirect])

    return {
        user: user ? user as User : null,
        logout: handleLogout
    }
}

export { useUser }