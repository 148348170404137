import {Spacer} from "@geist-ui/core"
import { useUser } from "../../utils/useUser"
import { SecondaryButton } from "../../ui"
import UserData from "https://framer.com/m/UserData-tRT1.js@0Cm7ajMeSpZTeXQrQpcH"
import { generateAcr } from "../../utils"
import { useUserData } from "../../data/userData"

const ProfilePopup = () => {

    const { user, logout } = useUser()
    const { userData } = useUserData(user?.id)

    const acr = generateAcr(userData?.firstName + " " + userData?.lastName)

    return <div style={{padding: "20px"}}>
        <UserData initials={acr} email={user?.email} title={userData?.firstName + " " + userData?.lastName}>
        </UserData>
        <Spacer />
        <Spacer h={4} />
        <SecondaryButton width={"100%"} onClick={logout}>Logout</SecondaryButton></div>
}

export default ProfilePopup