import useSWR from "swr"
import {buildQueryFromFilter} from "../utils/fetcher"
import { useUser } from "../utils/useUser"
import Network from "../services/network"

const useProviders = (filter) => {
    const {user} = useUser()
    const { data, error } = useSWR(user? ['/api/v1/providers?' + buildQueryFromFilter(filter), user.accessToken]:null, Network.get)

    return {
        providers: data
    }
}

const useProvider = (id) => {
    const {user} = useUser()
    const { data, error } = useSWR(user&& id ? ['/api/v1/providers/' + id, user.accessToken ]: null, Network.get)

    return {
        provider: data
    }
}

export { useProviders, useProvider }