import {Grid, Spacer} from "@geist-ui/core"
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useUser } from '../../utils/useUser'
import { ListAction, Loader, SecondaryButton } from '../../ui'
import { ModalHeader } from './components'

// An object to replace the titles of the ListItems further down
const reasons = {
    died: 'Plant Died',
    lost: 'Plant Lost',
    stolen: 'Plant Stolen',
    damaged: 'Plant Damaged',
    other: 'Other'
}

const ReasonList = ({ handleChoice }) => {
    const reasonKeys = Object.keys(reasons)
    return <Grid.Container style={{display: "flex", flexDirection: "column"}}>
        {reasonKeys.map(reason => {
           return <ListAction key={reason} tap={() => handleChoice(reason)} title={reasons[reason]} color='red' variant='no-subtitle' icon='X' />
        })}
    </Grid.Container>
}


const DeletePlantPopup = ({ onClose, callback }) => {

    const { user } = useUser()
    const { query: { id } } = useRouter()
    const [state, setState] = useState("start")
    const [res, setRes] = useState()

    const handleChoice = async (choice) => {
        try {
            setState('loading')
            await callback(reasons[choice])
            setState('done')
        } catch (err) {
            console.log(err)
            setRes(err.message)
            setState('error')
        }
    }

    return <div style={{ textAlign: "center", padding: "20px", }}>
        {state === 'done' ?
            <ModalHeader variant='done' style={{ width: "100%" }} title='Uprooting' subtitle='Done' /> : null
        }
        {state === 'error' ?
            <ModalHeader variant='error' style={{ width: "100%" }} title='Uprooting' subtitle={res ? res : 'Something went wrong'} /> : null
        }
        {state === 'start' ?
            <>
                <ModalHeader style={{ width: "100%" }} title='Uprooting' subtitle='Are you sure you want to uproot all plants in this batch? If so, please provide a reason.' />
                <Spacer h={2}></Spacer>
                <ReasonList handleChoice={handleChoice}></ReasonList>
            </> : null
        }
        {state === 'loading' ? <Loader /> : ""}
        <Spacer h={2} />
        <SecondaryButton onClick={onClose} style={{ width: "100%" }}>Dismiss</SecondaryButton>
    </div>
}
export default DeletePlantPopup