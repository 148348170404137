import {useRouter} from "next/router"
import moment from "moment"
import { Spacer, Grid } from "@geist-ui/core"

import { eventCategories } from "../../../common/variables"
import Phosphor from "https://framer.com/m/Phosphor-9Q9Y.js@qyebYqHqVZaLb5Up06qa"

import { useProfile } from "../../data/useProfile"
import { EditableData, LinkButton, SecondaryButton, Warning } from "../../ui"
import { useBatch, usePlant, useSpace } from "../../data"
import { useState } from "react"
import { useSWRDataWithAuth } from "../../data/useSWRData"
import Harvest from "../../core/harvest/model"

const WrappedSpaceData = ({ event }) => {
    const router = useRouter()
    const { space } = useSpace(event.spaces[0])
    return <>
        <EditableData title='Space' subtitle={space?.name ?? "Loading..."} tap={() => { router.push('/spaces/' + event.spaces[0]) }} actionText='View' />
        <Spacer h={0.5} />
    </>
}

const WrappedBatchData = ({ event, close }) => {

    const { batch } = useBatch(event?.batches[0])
    const router = useRouter()

    const isMultiple = event.batches.length > 1
    const variant = isMultiple ? "Variant 2" : "Primary"
    const subtitle = isMultiple ? "Multiple Batches" : (batch ? batch.name : "Loading")

    const tap = isMultiple ? null : () => {
        close()
        router.push('/batches/' + event.batches[0])
    }

    return <>
        <EditableData icon='Folder'
            actionText='View'
            tap={tap}
            variant={variant}
            title='Batch'
            subtitle={subtitle} />
        <Spacer h={0.5} />
    </>
}

const WrappedPlantData = ({ event, close }) => {
    const router = useRouter()
    const { plant } = usePlant(event?.plants[0])

    const isMultiple = event.plants.length > 1
    const subtitle = isMultiple ? "Multiple Plants" : (plant ? plant.name : "Loading")
    const variant = isMultiple ? "Variant 2" : "Primary"

    const tap = isMultiple ? null : () => {
        close()
        router.push('/plants/' + event.plants[0])
    }

    return <>
        <EditableData icon='Leaf'
            actionText={"View"}
            tap={tap}
            variant={variant}
            title='Plant'
            subtitle={subtitle} />
        <Spacer h={0.5} />
    </>
}


const WrappedHarvestData = ({ event, close }) => {

    const router = useRouter()
    const {harvest} = useSWRDataWithAuth(Harvest.baseURL + "/" + event?.harvests[0], {}, {
        model: Harvest, 
        name: "harvest",
        v2: true
    })

    const isMultiple = event.harvests.length > 1
    const subtitle = isMultiple ? "Multiple Harvests" : (harvest ? harvest.name : "Loading")
    const variant = isMultiple ? "Variant 2" : "Primary"

    const tap = isMultiple ? null : () => {
        close()
        router.push('/harvests/' + event.harvests[0])
    }

    return <>
        <EditableData icon='Leaf'
            actionText={"View"}
            tap={tap}
            variant={variant}
            title='Harvest'
            subtitle={subtitle} />
        <Spacer h={0.5} />
    </>
}


const EventDetailPopup = ({ event, close }) => {

    const { profile } = useProfile(event?.user)
    const { title, icon } = eventCategories[event?.category] ?? {}
    const [detail, setDetail] = useState()

    const closeDetail = () => setDetail()

    return <div style={{ padding: "20px" }}>
        {detail ? <Grid.Container>
            <Grid>
                <LinkButton onClick={closeDetail} icon='CaretLeft'>Back</LinkButton>
                <Spacer></Spacer>
                {detail}
                <Spacer></Spacer>
                <SecondaryButton width='100%' onClick={close}>
                    Dismiss
                </SecondaryButton>
            </Grid>
        </Grid.Container> :
            <Grid.Container justify="center" direction="column" alignItems="center">
                <Phosphor color='black' width='200px' height='200px' name={icon} />
                <Spacer />
                <h3>{title ?? event.category}</h3>
                <span style={{ fontSize: "17px" }}>
                    {event.description}
                </span>
                <Spacer h={2} />
                <Grid xs={24} style={{ width: '100%' }} direction='column' justify="space-between">
                    {event?.task ? <><Warning variant='info' icon='Target'>Generated from Task</Warning>
                        <Spacer /></> : ""}
                    {event?.photo ? <EditableData tap={() => setDetail(<img style={{ borderRadius: "20px" }} src={event.photo} />)} variant='photo' image={event?.photo} icon='User' title='Proof' actionText='View' subtitle="Type: Image" /> : null}
                    <Spacer h={0.5} />
                    {event?.harvests.length ? <WrappedHarvestData setDetail={setDetail} close={close} event={event} /> : null}
                    {event?.plants.length ? <WrappedPlantData setDetail={setDetail} close={close} event={event} /> : null}
                    {event?.spaces.length ? <WrappedSpaceData setDetail={setDetail} close={close} event={event} /> : null}
                    {event?.batches.length ? <WrappedBatchData setDetail={setDetail} close={close} event={event} /> : null}
                    <EditableData icon='Calendar' title='Date Posted' subtitle={moment(event.date).format("DD.MM.YYYY")} variant='Variant 2' />
                    <Spacer h={0.5} />
                    <EditableData icon='User' title='Posted By' subtitle={profile?.firstName + " " + profile?.lastName} variant='Variant 2' />
                </Grid>
                <Spacer h={2} />
                <SecondaryButton width='100%' onClick={close}>
                    Dismiss
                </SecondaryButton>
            </Grid.Container>}
    </div>
}

export default EventDetailPopup