import { User as GeistUser, Grid } from "@geist-ui/core"
import { useProfile } from '../../data/useProfile'
import styles from './user.module.css'
import { User as UserIcon } from 'phosphor-react'
import { generateAcr } from '../../utils'
import Image from 'next/image'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/client/ui/avatar"
import { Button } from "@/client/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/client/ui/dropdown"
import React from "react"
import Skeleton from "react-loading-skeleton"
import { useUser } from "@/client/utils/useUser"
import { useRouter } from "next/router"

export function UserNav() {
  const router = useRouter()
  const { user, logout } = useUser({ redirect: false })
  const { profile } = useProfile(user?._id)

  if (!user) return "Log In"

  const openSettings = () => {
    router.push('/settings')
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src={profile?.profilePicture} alt="@shadcn" />
            <AvatarFallback>{user?.firstName?.[0]}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.firstName} {user?.lastName}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {/* <DropdownMenuItem>
              Profile
              <DropdownMenuShortcut>⇧⌘P</DropdownMenuShortcut>
            </DropdownMenuItem> */}
          {/* <DropdownMenuItem>
              Billing
              <DropdownMenuShortcut>⌘B</DropdownMenuShortcut>
            </DropdownMenuItem> */}
          <DropdownMenuItem onClick={openSettings}>
            Settings
            {/* <DropdownMenuShortcut></DropdownMenuShortcut> */}
          </DropdownMenuItem>
          {/* <DropdownMenuItem>New Team</DropdownMenuItem> */}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>
          Log out
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}


interface ProfileProps {
  profile: any
}

const Profile = ({ profile }: ProfileProps) => {
  return <div style={{ width: "40px", height: "40px", borderRadius: "500px", cursor: "pointer", background: `url(${profile?.profilePicture})`, backgroundSize: "cover" }}>
    {profile?.profilePicture ? <Image className={styles.image} width={40} height={40} alt='profile-picture' src={profile?.profilePicture ?? ""}></Image> : <div style={{ width: "40px", height: "40px", background: "black", borderRadius: "200px" }} ></div>}
  </div>
}

interface UserProps {
  userId: string
  initialsOnly?: boolean
  style?: any
}

const User = ({ userId, initialsOnly, style }: UserProps) => {
  const { profile } = useProfile(userId)

  if (!profile) return ""
  const acr = generateAcr(profile?.firstName + " " + profile.lastName)

  if (initialsOnly) return <GeistUser name={null} src={profile?.profilePicture} style={style} text={acr} />
  return <GeistUser style={style} name={profile?.firstName + " " + profile.lastName} text={acr} />
}

interface WrappedUserProps {
  userId: string
  callback: () => void
  loading?: boolean
  style?: any
}

interface WrapperProps {
  children: React.ReactNode
  style?: any
}

const WrappedUser = ({ userId, callback, loading, style }: WrappedUserProps) => {

  const { profile } = useProfile(userId)

  const Wrapper = ({ children, style }: WrapperProps) => {

    return <Grid.Container style={style} alignItems="center" alignContent="center">
      <Grid>
        {profile?.profilePicture ? <Image alt="Profile Picture" className={styles.displayIcon} src={profile?.profilePicture} /> :
          <div className={`${styles.displayIcon}`} style={{ color: style?.color, border: `1px ${userId ? "solid" : "dashed"} ${style?.color}` }}>
            <UserIcon />
          </div>
        }
      </Grid>
      <Grid>
        {children}
      </Grid>
    </Grid.Container>
  }

  return <div onClick={callback} style={{ padding: "10px", ...style }}>
    {loading ?
      <Skeleton width={100}></Skeleton> :
      <Wrapper style={{ color: userId ? "black" : "red" }}>
        {userId && profile ?
          <>{profile?.firstName + " " + profile?.lastName}</>
          : "No User"
        }
      </Wrapper>}
  </div>
}

export default User
export { Profile, WrappedUser }