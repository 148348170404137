
import { useUser } from "../../utils/useUser"

import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useSpaces } from "../../data"
import moment from "moment"

import { Spacer, Grid, Divider } from "@geist-ui/core"
import { EditableData, ListAction, Input, Button, SecondaryButton, Loader, EmptyState } from "../../ui"
import { DayPicker } from 'react-day-picker';

import { ModalHeader, SubModalTitle } from '../../components/modal/components'
import SpaceListItem from "../space"
import { useSWRDataWithAuth } from "../../data/useSWRData"
import Workflow from '../../components/workflow/model'
import WorkflowListItem from '../../components/workflow/list-item'
import { Leaf } from "phosphor-react"

const Wrapper = ({ children, title, subtitle, sub, variant }) => {
    if (sub) {
        return <div style={{ padding: "20px" }}>
            {children}
        </div>
    }
    return <div style={{ padding: "30px" }}>
        <ModalHeader variant={variant} style={{ width: "100%" }} title={title} subtitle={subtitle}>
        </ModalHeader>
        <Spacer></Spacer>
        <Spacer></Spacer>
        {children}
    </div>
}


const QuantityStep = ({ setData, setStep, data, genetic }) => {

    const [quantity, setQuantity] = useState(1)

    const hijacker = () => {
        if (quantity > genetic.seeds.length) return
        setData({ ...data, quantity })
        setStep('batch')
    }

    return <><Grid xs={24}>
        {quantity > genetic.seeds.length ? <>
            <span style={{ color: "red" }}>
                Available Seeds: {genetic.seeds.length}</span></> : ""}
        <Spacer></Spacer>
        <Input onEnter={hijacker} style={{ width: "100%" }} placeholder="Quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
    </Grid>
        <Spacer />
        <Button fullWidth onClick={hijacker}>
            Next
        </Button></>
}


const ModeStepView = ({ onChange }) => {

    return <>
        <ListAction
            icon={Leaf}
            subtitle="Add a single plant"
            title="Single Plant"
            tap={() => onChange('overview')} />
        <ListAction
            icon={Leaf}
            subtitle="Add multiple, and optionally create a batch"
            title="Multiple Plants"
            tap={() => onChange('quantity')}
        />
    </>

}

const BatchStep = ({ data, setData, setStep }) => {

    const handler = (shouldCreate) => {
        setData({ ...data, shouldCreate })
        setStep('overview')
    }

    return <>
        <EditableData
            subtitle="To verify the authenticity of your batch"
            title="Certificates"
            variant="Variant 2"
            actionText="Change"
            icon="Square"
        />
        <Spacer />

        <EditableData
            icon="Cursor"
            subtitle="Post Updates and Phase Changes to the whole batch"
            title="Actions"
            variant="Variant 2"
            actionText="Change"
        />

        <Spacer h={2} />
        <Button onClick={() => handler(true)} fullWidth>Create Batch Now</Button>
        <Spacer h={0.5} />
        <SecondaryButton onClick={() => handler(false)} fullWidth>Later</SecondaryButton>

    </>
}


const OverviewStep = ({ data, setData, setStep, addPlant }) => {

    return <>
        <EditableData tap={() => setStep('quantity')} icon="Hash" subtitle={`${data.quantity}`} title="Quantity" />
        <Spacer />
        <EditableData tap={() => setStep('date')} icon="Calendar" subtitle={moment(data.date).format("DD.MM.YYYY") ?? "Today"} title="Date Germinated" />
        <Spacer />
        <EditableData tap={() => setStep('batch')} icon="Folder" subtitle={data?.shouldCreate ? "Yes" : "No"} title="Create Batch" />
        <Spacer />
        <EditableData style={{ width: "100%" }} title="Space Selected" subtitle={data?.space.name} tap={() => setStep('space')} />
        <Spacer></Spacer>
        <EditableData icon='SkipForward' style={{ width: "100%" }} title="Workflow" subtitle={data?.workflow?.title} tap={() => setStep('workflow')} />
        {/* <Spacer /> */}
        {/* <EditableData icon="Circle" variant="Variant 2" title="Seed Batch" subtitle={transaction?.slice(0, 8) + "..." ?? "Auto-Selected"} /> */}
        <Spacer h={2} />
        <Button onClick={addPlant} style={{ width: "100%" }}>
            {data.quantity > 1 ? "Add Plants" : "Add Plant"}
        </Button>
    </>
}

const DateStepView = ({ data, setData, setStep }) => {
    return <>
        <SubModalTitle tap={() => { setStep('overview') }} />
        <Spacer />
        <Divider />
        <DayPicker style={{ width: "100%" }} selected={data.date}
            onDayClick={day => {
                setData({ ...data, date: day });
                setStep('overview')
            }} />
    </>
}

const WorkflowStepView = ({ data, setData, setStep, type }) => {
    const { workflows } = useSWRDataWithAuth('/api/v1/workflows', { filter: { appliesTo: { operation: "eq", value: "grow", } } }, { model: Workflow, name: 'workflows' })
    if (!workflows) return <Loader />

    return <>
        <SubModalTitle icon='SkipForward' title='Choose a Workflow' tap={() => { setStep('overview') }} />
        <Spacer />
        <Divider />
        <div style={{ maxHeight: "400px", overflowY: "scroll", overflowX: "hidden" }}>
            {!workflows.length ? <EmptyState title='No Workflows' subtitle={'No ' + type + " workflows. Add one in settings."}></EmptyState> : null}
            {workflows?.map((workflow, i) => {
                if (workflow.type !== type) return
                return <div key={i}>
                    <WorkflowListItem hideDate onClick={() => { setData({ ...data, workflow: workflow }); setStep('overview') }} key={i} workflow={workflow}></WorkflowListItem>
                    <Spacer />
                </div>
            })}
        </div>
    </>
}


const SpaceStepView = ({ data, setData, setStep, spaces }) => {

    return <>
        <SubModalTitle tap={() => setStep('overview')} icon="GridFour" title="Select a Space" />
        <Spacer />
        <Divider />
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
            {spaces.map((space, i) => {
                return <SpaceListItem tap={() => { setData({ ...data, space: space }); setStep('overview') }} space={space} key={i} />
            })}
        </div>
    </>
}


const UseGeneticPopup = ({ genetic, close, transaction, callback }) => {

    const router = useRouter()
    const { user } = useUser()
    const [data, setData] = useState({
        space: "",
        date: Date.now(),
        type: "seed",
        shouldCreate: false,
        transaction,
        workflow: genetic?.strain?.type === 'cannabis' ? { _id: "cannabis", title: "Cannabis (default)" } : { _id: "microgreens", title: "Microgreens (default)" },
        quantity: 1,
    })

    const [loading, setLoading] = useState(false)
    const { spaces } = useSpaces()
    const [error, setError] = useState()
    const [step, setStep] = useState('overview')

    useEffect(() => {
        if (!data.space && spaces) {
            setData({ ...data, space: spaces[0] })
        }

    }, [spaces])


    const addPlant = async () => {

        setLoading(true)

        try {

            await genetic.use(user, {
                space: data.space._id,
                workflow: data?.workflow?._id ?? "cannabis",
                type: data.type,
                date: data.date,
                makeBatch: data.shouldCreate,
                quantity: data.quantity,
            })

            if (callback) await callback()
            setStep("done")
            setLoading(false)
        }
        catch (erro) {
            setLoading(false)
            console.log(erro.message)
            setStep('error')
            setError("Failed to germinate seeds.")
        }
    }

    const steps = {
        "mode": {
            view: <ModeStepView onChange={setStep} />,
            title: "Use Genetic",
            subtitle: "How would you like to use this genetic?"
        },
        "quantity": {
            view: <QuantityStep genetic={genetic} setData={setData} data={data} setStep={setStep} />,
            title: "Use Genetic",
            subtitle: "How would you like to use this genetic?"
        },
        "batch": {
            view: <BatchStep setData={setData} data={data} setStep={setStep} />,
            title: "Use Genetic",
            subtitle: "How would you like to use this genetic?"
        },
        "overview": {
            view: <OverviewStep addPlant={addPlant} transaction={transaction} setData={setData} data={data} setStep={setStep} />,
            title: "Confirm Details",
            subtitle: "Check that everything looks correct"
        },
        "date": {
            view: <DateStepView setData={setData} data={data} setStep={setStep} />,
            title: "Date",
            subtitle: "Choose a date",
            sub: true
        },
        "workflow": {
            view: <WorkflowStepView setData={setData} type={genetic?.type} data={data} setStep={setStep} />,
            title: "Workflow" + genetic?.type,
            sub: true
        },
        "space": {
            view: <SpaceStepView spaces={spaces} setData={setData} data={data} setStep={setStep} />,
            title: "Choose a Space",
            subtitle: "Check that everything looks correct",
            sub: true
        },
        "error": {
            view: <SecondaryButton fullWidth onClick={close}>Dismiss</SecondaryButton>,
            title: "Adding Plant Failed",
            subtitle: error,
            variant: "error",
        },
        "done": {
            view: <SecondaryButton onClick={close} fullWidth>Dismiss</SecondaryButton>,
            title: data.quantity > 1 ? "Plants Added" : "Plant Added",
            subtitle: "Let's get growing",
            variant: "done"
        }

    }

    if (spaces?.length === 0) {
        return <Wrapper variant='error' title='You need a Space.' subtitle='You can create one now.'>
            <Button fullWidth onClick={() => {
                router.push('/spaces')
                close()
            }}>Go to Spaces</Button>
        </Wrapper>
    }

    if (loading) return <div style={{ height: "300px" }}>
        <Loader></Loader>
    </div>

    return <Wrapper variant={steps[step].variant} sub={steps[step]?.sub} title={steps[step].title} subtitle={steps[step].subtitle}>
        {steps[step].view}
    </Wrapper>

}

export default UseGeneticPopup