import Network from "../../services/network"
import { Cpu, Thermometer } from "phosphor-react"

class Sensor {
    constructor({ name, type, hub, spaces, _id, unit, lastSeen, mapper }) {
        this.name = name
        this.type = type
        this.hub = hub
        this.lastSeen = lastSeen
        this.spaces = spaces
        this._id = _id
        this.unit = unit,
            this.mapper = mapper
    }

    static baseURL = '/api/v1/sensors'
    static icons = {
        temperature: Thermometer,
    }

    static async create(user, { name, type, unit }) {
        const sensor = await Network.post(this.baseURL, user?.accessToken, { name, type, unit })
        return sensor
    }

    getIcon = () => {
        if (!this.type) return Cpu
        return Sensor.icons[this.type] || Cpu
    }

    async saveDataKey(user, dataKey) {
        const updatedSensor = await Network.put(`${Sensor.baseURL}/${this._id}`, user.accessToken, {
            mapper: {
                dataKey,
                examplePayload: this.mapper.examplePayload
            }
        })
        return updatedSensor
    }

    async resetMapping(user) {
        const updatedSensor = await Network.put(`${Sensor.baseURL}/${this._id}`, user.accessToken, {
            mapper: null
        })
        return updatedSensor
    }

    async remove(user) {
        await Network.del(`${Sensor.baseURL}/${this._id}`, user.accessToken)
    }
}

export default Sensor