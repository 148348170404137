import styles from "./icon.module.css"

const ListItemIcon = ({ CustomIcon, size }) => {

    return <div className={styles.icon} style={size ? { width: size + size * 0.5, height: size + size * 0.5 } : null}>
        <CustomIcon size={size ?? 24}></CustomIcon>
    </div>

}

export default ListItemIcon