import Link from "next/link"
import styles from "./title.module.css"

const Icon = ({ children }) => {
    return <div style={{ width: "72px", height: "72px", background: "var(--color-primary-5)", borderRadius: "12px", border: "var(--standard-border)", display: "flex", justifyContent: "center", alignItems: "center", padding: "16px" }}>
        {children}
    </div>
}

const Title = ({ children }) => {
    return <h2 style={{ margin: "0px", lineHeight: 1.1, fontSize: "32px" }}>{children}</h2>
}

const Subtitle = ({ children, noOpacity, link }) => {
    const render = () => <p style={{ margin: "0px", opacity: noOpacity ? 1 : 0.5 }}>{children}</p>
    if (link) return <Link className={styles.link} href={link}>{render()}</Link>
    else return render()
}

const DetailPageTitle = ({ icon, title, subtitle, tag, noOpacity, subtitleLink }) => {
    return <div style={{ display: "flex", alignItems: "center", gap: "16px", width: "100%", padding: "0 24px" }}>
         <Icon>{icon}</Icon>
        <div>
            {tag}
            <Title>{title}</Title>
            <Subtitle link={subtitleLink} noOpacity={noOpacity}>{subtitle}</Subtitle>
        </div>
    </div>
}

// const DetailPageTitle = () => <div></div>

export default DetailPageTitle
