import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { useUser } from "../utils/useUser"

const useCurrentProfile = () => {
    const { user } = useUser()
    const { data, error } = useSWR(user ? ['/api/v1/users', user.accessToken] : null, Network.get)

    return {
        profile: data,
        loading: !data && !error
    }
}

const useProfile = (userId) => {
    const { user } = useUser()
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(userId && user ? ['/api/v1/users/' + userId, user.accessToken] : null, Network.get)

    const refreshProfile = async () => {
        mutate(['/api/v1/users/' + userId, user.accessToken])
    }

    return {
        profile: data,
        loading: !data && !error, 
        refreshProfile
    }
}

const useProfileForUid = (uid) => {
    const { user } = useUser()
    const { data, error } = useSWR(uid && user ? ['/api/v1/users?uid=' + uid, user.accessToken] : null, Network.get)
    return {
        profile: data,
        loading: !data && !error
    }
}






export { useCurrentProfile, useProfile, useProfileForUid }