import useSWR, {useSWRConfig} from "swr"
import Network from '../services/network'
import { useUser } from "../utils/useUser"

const useBatchWithoutAuth = (id) => {

    const url = id ? '/api/v1/batches/' + id + '/public' : null
    const { data, error } = useSWR(url, Network.get)


    return {
        batch: { ...data?.batch, genetics: data?.genetics, team: data?.team, events: data?.events },
        batchLoading: !data && !error,
        refreshBatches: () => { }
    }
}


const useBatchEvents = (batchId) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const { data, error } = useSWR(user ? ['/api/v1/batches/' + batchId + '/events', user.accessToken] : null, Network.get)

    const refreshEvents = async () => {
        await mutate(user ? ['/api/batch/' + batchId + '/events', user.accessToken] : null)
    }

    return {
        events: data,
        refreshEvents
    }
}

var queryString = filter => (Object.keys(filter).map(key => key + '=' + filter[key]).join('&')) ?? null

const useBatches = (filter) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/batches' + (filter ? ("?" + queryString(filter)) : "")
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshBatches = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        batches: data,
        refreshBatches
    }
}


const useBatch = (id) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = id ? '/api/v1/batches/' + id : null
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshBatch = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        batch: data,
        refreshBatch
    }
}

export { useBatches, useBatch, useBatchWithoutAuth, useBatchEvents }