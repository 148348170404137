import { Grid, Spacer } from "@geist-ui/core"
import { ListAction } from "../../../../../ui"
import { Bag, Bank } from "phosphor-react"

const Source = ({ onChange, onComplete }) => {

    const handleSelection = async selection => {
        onChange("source", selection)
        await onComplete()
    }

    return <div><Grid.Container>
        <ListAction
            icon={Bank}
            info="16 days ago"
            subtitle="With strain data accessible"
            tap={() => handleSelection('breeder')}
            title="From Breeder/Seedbank"
            style={{ with: "100%" }}
            variant="primary"
        />
        <ListAction
            icon={Bag}
            subtitle="From a bag or anything else"
            title="Other"
            style={{ with: "100%" }}
            tap={() => handleSelection('other')}
            info="Use Genetic"
            variant="primary"
        />

    </Grid.Container>
        <Spacer />
    </div>
}

const onComplete = (setStep) => {
    setStep("provider")
}


const sourceStep = {
    title: "Seed Source",
    subtitle: "Choose a source",
    View: Source,
    onComplete
}

export default sourceStep