import NextLink from "next/link"
import {Link as GuistLink} from '@geist-ui/core'

const Link = ({href, children, ...props}) => {
    if (!href) return <GuistLink {...props}>{children}</GuistLink>
    return <NextLink href={href}>
        <GuistLink {...props}>{children}</GuistLink>
    </NextLink>
}

export default Link