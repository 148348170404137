import {useState} from "react"
import { Spacer } from "@geist-ui/core"
import { SecondaryButton, ListAction } from "../../ui"
import { ModalHeader } from "./components"
import { spaceTypes } from "../../utils/static"

const ChangeSpaceTypePopup = ({ onClose, callback }) => {
    const [data, setData] = useState({
        conditions: "outdoor",
        type: "grow",
    })

    const updateData = (newData) => {
        setData({ ...data, ...newData })
    }

    const updateType = async (type) => {
        updateData({ type })
        await callback(type)
        onClose()
    }

    return <div style={{ padding: "20px" }}>
        <ModalHeader style={{width: "100%"}} subtitle='For this space' title='Select Space Type' />
        <Spacer h={2} />
        {Object.keys(spaceTypes).map(type => {
            return <ListAction key={type.title} style={{ width: "100%" }} tap={() => updateType(type)} subtitle={spaceTypes[type].subtitle} icon={spaceTypes[type].icon} title={spaceTypes[type].title} />
        })}
        <Spacer h={2} />
        <SecondaryButton style={{ width: "100%" }}>
            Dismiss
        </SecondaryButton>
    </div>
}

export default ChangeSpaceTypePopup