import {Spacer, Grid} from "@geist-ui/core"
import { useEffect, useState } from "react"

import { SecondaryButton } from "../../ui"

import { useUser } from "../../utils/useUser"
import Network from "../../services/network"

const GeneratePDFPopup = ({ plants }) => {

    const { user } = useUser()
    const [status, setStatus] = useState("loading")
    const [pdf, setPdf] = useState(null)

    useEffect(()=>{
        async () => {
        if (!pdf && user) {
            setStatus("loading")

            const res = Network.post('/api/v1/utilities/pdf', user?.accessToken, {
                plants
            })

            if (!res) return setStatus("error")
            else {

                setPdf(res?.url)
                setStatus("success")
            }
        }
        generate()
    }}, [plants, user])

    return <div style={{ padding: "20px" }}>
        <Grid.Container justify="center" direction="column" alignItems="center">
            {status === "loading" ? <div>Loading...</div> : null}
            {status === "error" ? <div>Error</div> : null}
            {status === "success" ? <div>
                <a href={pdf} rel='noreferrer' target="_blank">Download PDF</a>
            </div> : null}
            <Spacer h={2} />
            <SecondaryButton width='100%' onClick={close}>
                Dismiss
            </SecondaryButton>
        </Grid.Container>
    </div>
}

export default GeneratePDFPopup