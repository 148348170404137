import {useState} from "react"

import { Button, SecondaryButton } from '../../ui'
import { Spacer, Spinner } from '@geist-ui/core'

import { ModalHeader } from './components'
import WrappedUpload from '../upload'
import { uploadFromDataURI } from '../../utils/image-upload'

const AddProfilePicturePopup = ({ callback, close }) => {

    const [step, setStep] = useState('start')
    const [image, setImage] = useState()
    const [res, setRes] = useState()

    const onComplete = async () => {
        setStep('loading')
        try {
            const imageURL = await uploadFromDataURI(image)
            await callback(imageURL)
            setStep('done')
        } catch (e) {
            setStep('error')
            setRes(e.message)

        }
    }

    const Wrapper = ({ children, title, subtitle, variant }) => {
        return <div style={{ padding: "20px" }}>
            <ModalHeader
                variant={variant}
                style={{ width: "100%" }}
                subtitle={subtitle ?? "Add a custom profile picture to this page to make it easily identifiable"}
                title={title ?? "Add Profile Picture"} />
            <Spacer h={2} />
            {children}

            <SecondaryButton onClick={close} fullWidth>Dismiss</SecondaryButton>
        </div>
    }

    if (step === 'done') return <Wrapper
        title='Done'
        subtitle='Successfully uploaded your image'
        variant='done'
    >
        <Spacer h={2} />
    </Wrapper >

    if (step === 'error') return <Wrapper
        variant={'error'}
        title='Something went wrong'
        subtitle={res}
    >
        <Spacer></Spacer>
    </Wrapper>

    if (step === 'loading') return <div style={{ padding: "20px", height: "400px" }}>
        <Spinner></Spinner>
    </div>

    return <Wrapper>
        <WrappedUpload providedImage={image} callback={image => setImage(image)} title='Profile Picture'></WrappedUpload>
        <Spacer h={2}></Spacer>
        <Button onClick={onComplete} fullWidth icon='Upload'>Upload</Button>
        <Spacer h={0.5} />
    </Wrapper>

}

export default AddProfilePicturePopup