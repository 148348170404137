"use client"
import Head from "next/head"
import styles from "./components.module.css"
import { useRouter } from "next/router";
import { Dot, Grid } from "@geist-ui/core";
import Skeleton from "react-loading-skeleton";
import { LinkButton, ShareFilterPopover } from '../../ui'
import { DesktopMenu } from "../../components/tab-bar";
import { LogoIcon } from '../../components/logo'
import { AddSpacePopup } from "../../components/modal";
import { useUser } from "../../utils/useUser";
import Network from "../../services/network";
import { useCurrentTeam, useTeams } from '../../data/useTeam'

import Back from "https://framer.com/m/Back-raY7.js@mXZCTj7EGmvZvFxHUXZJ"

import { motion, useViewportScroll } from 'framer-motion'
import { useEffect, useState } from "react";
import { Bell, Calendar, Settings } from "@geist-ui/icons";
import { useProfile } from "../../data/useProfile";
import AuthUtils from "../../auth";

import AddWorkflowModal from "../../components/workflow/modal";
import AddWebhookPopup from "../../core/webhooks/modal";
import AddSensorModal from "../../core/sensor/modal";
import { AddGeneticPopup } from "../../core/genetic/popups";
import { Bug } from "phosphor-react";
import TeamSwitcher from "@/client/components/team/TeamSwitcher";
import { UserNav } from "@/client/components/user";
import * as React from "react"
import { Moon, Sun } from "lucide-react"
import { useTheme } from "next-themes"

import { Button } from "@/client/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/client/ui/dropdown"

const titles = {
    '': {
        title: "Dashboard",
    },
    'plants': { title: "Plants" },
    'batches': { title: "Batches" },
    'notifications': { title: "Notifications" },
    'genetics': {
        title: "Genetics",
    },
    'spaces': {
        title: "Spaces",
    },
    'settings': { title: "Settings" },
    'tasks': { title: "Tasks" },
    'events': { title: "Events" },
    'workflows': {
        title: "Workflows",
    },
    'webhooks': {
        title: "Webhooks",
    },
    'sensors': {
        title: "Sensors",
    },
    'harvests': {
        title: "Harvests",
    },

}



export function ModeToggle() {
  const { setTheme } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
          <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setTheme("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("system")}>
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}



const HeadSection = () => {
    const router = useRouter()
    const { title } = titles[router?.route.split('/')[1]] ?? {}

    return <Head>
        <title>{title ? title + " | " : ""}Cloner Grow</title>
        <meta name="description" content="Next Generation Grow Software" />
    </Head>
}


const WrappedTeamSwitcher = ({ activateLoader }) => {
    const { user } = useUser()
    const router = useRouter()
    const { team } = useCurrentTeam()
    const { teams, refreshTeams } = useTeams()

    const switchTeam = async (team) => {
        activateLoader(true)
        await Network.put('/api/v1/users/' + user?._id + '/team', user.accessToken, {
            team
        })
        await AuthUtils.refreshToken(user.refreshToken)
        router.reload()
    }

    if (!team) return <Skeleton />

    return <TeamSwitcher onAdd={refreshTeams} teams={teams} team={team} switchTeam={switchTeam} />

}

const NotificationBadge = ({ showDot }) => {

    return <div style={{ position: "relative" }}>
        <Bell></Bell>
        {showDot ? <Dot style={{ position: "absolute", top: "-1px", left: "13px" }} type="success"></Dot> : null}</div>

}

const NotificationSection = () => {

    const router = useRouter()
    const { user } = useUser()
    const { profile, refreshProfile } = useProfile(user?._id)

    const handleClick = () => {

        if (profile?.hasActiveNotifications) {
            Network.put('/api/v1/users/' + user?._id, user.accessToken, {
                hasActiveNotifications: false
            }).then(() => {
                refreshProfile()
            })
        }

        router.push('/notifications')
    }

    return <Grid className={styles.topBarItem} onClick={handleClick} direction="column">
        <NotificationBadge showDot={profile?.hasActiveNotifications}></NotificationBadge>
    </Grid>
}


const UserProfileSection = () => {
    const router = useRouter()

    return <Grid.Container alignItems="center" gap={2}>
        <Grid title="Report a bug" className={styles.topBarItem} onClick={() => window.open('https://cloner.co.za/bugs', "_blank")}>
            <Bug size={28} />
        </Grid>
        <NotificationSection></NotificationSection>
        <Grid>
            <UserNav />
        </Grid>
    </Grid.Container>
}


const TopBar = ({ setFullScreenModal }) => {

    const isServer = typeof window === 'undefined'

    return <div className={`${styles.topBar} sticky top-0`}>
        <Grid.Container style={{ height: "100%", width: "100%", paddingLeft: "20px", paddingRight: "20px" }} alignContent='center' alignItems="center" justify="space-between">
            <Grid>
                <Grid.Container alignItems="center" gap={2}>
                    <Grid>
                        <LogoIcon style={{ width: "30px" }}></LogoIcon>
                    </Grid>
                    <Grid>
                        {isServer ? <Skeleton></Skeleton> :
                            <WrappedTeamSwitcher activateLoader={setFullScreenModal} />}
                    </Grid>
                </Grid.Container>
            </Grid>
            <Grid direction="row">
                <UserProfileSection></UserProfileSection>
            </Grid>
        </Grid.Container>
    </div>
}

const DesktopMenuList = () => {

    return <div className={styles.sidebar}>
        <div className={styles.expanded}>
            <DesktopMenu />
        </div>
        <div className={styles.collapse}>
            <DesktopMenu iconOnly />
        </div>
        <div className={styles.mobile}>
            <DesktopMenu horizontal iconOnly />
        </div>
    </div>
}


const TitleBar = ({ scrollOffset, children, setModal, tabs }) => {

    const router = useRouter()
    const shouldShowBackButton = router.route.split('/').length > 2
    const { scrollY } = useViewportScroll()

    const threshhold = scrollOffset ?? 20
    const [state, setState] = useState('top')

    useEffect(() => {
        return scrollY.onChange(latest => {
            setState(latest > threshhold ? "scroll" : "top")
        })
    }, [])

    const pageTitles = {
        '/': {
            title: "Dashboard",
        },
        '/plants': { title: "Plants" },
        '/batches': {
            title: "Batches",
            View: ShareFilterPopover
        },
        '/notifications': { title: "Notifications" },
        '/genetics': {
            title: "Genetics",
            button: {
                title: "Add Genetic",
                icon: "Plus",
                action: () => {
                    setModal(<AddGeneticPopup onClose={() => {
                        setModal()
                    }} />)
                }
            }
        },
        '/spaces': {
            title: "Spaces",
            button: {
                title: "Add Space",
                icon: "Plus",
                action: () => {
                    setModal(<AddSpacePopup onClose={() => setModal()} />)
                }
            },
            View: ShareFilterPopover
        },
        '/settings': { title: "Settings" },
        '/tasks': { title: "Tasks" },
        '/events': { title: "Events" },
        '/workflows': {
            title: "Workflows",
            button: {
                title: "Add Workflow",
                icon: "Plus",
                action: () => {
                    setModal(<AddWorkflowModal onClose={() => setModal()}></AddWorkflowModal>)
                }
            }
        },
        '/sensors': {
            title: "Sensors",
            button: {
                title: "Add Sensor",
                icon: "Plus",
                action: () => {
                    setModal(<AddSensorModal onClose={() => setModal()} />)
                }
            }
        },
        '/webhooks': {
            title: "Webhooks",
            button: {
                title: "Add Webhook",
                icon: "Plus",
                action: () => {
                    setModal(<AddWebhookPopup onClose={() => setModal()} />)
                }
            }
        },
        '/harvests': {
            title: "Harvests",
        }, 
        '/calendar': {
            title: "Calendar"
        }
    }

    const getPageTitle = () => {
        const pageTitle = pageTitles[router.route]
        if (!pageTitle) return {}
        else return pageTitle
    }

    const variants = {
        top: {
            fontSize: "32px"
        },
        scroll: {
            fontSize: "17px",
        }
    }

    const { title, button, View } = getPageTitle()

    if (!shouldShowBackButton) {
        return <motion.div
            className="stickyStyle"
            style={{
                zIndex: 40,
                background: 'rgba(245, 245, 245, 0.4)',
                backdropFilter: "blur(20px)",
                WebkitBackdropFilter: "blur(20px)",
                borderBottom: state === 'top' ? 'none' : '1px solid rgba(0,0,0,0.1)',
                top: "72px",
                padding: '20px',
                paddingTop: "12px",
                paddingBottom: "8px",

            }}>
            <Grid.Container style={{ height: "100%" }} justify='space-between' alignItems='center' alignContent="center" direction='row'>
                <Grid>
                    <motion.h2 variants={variants} animate={state}>{title}</motion.h2>
                </Grid>
                <Grid direction="row">
                    <div className={styles.actionSection}>
                        {button ? <LinkButton icon={button.icon} onClick={button?.action} auto type='success-light'>{button?.title ?? ""}</LinkButton> : ""}
                        {View ? <View /> : null}
                    </div>
                </Grid>
            </Grid.Container>

            {tabs}
        </motion.div>
    }

    return <motion.div className="stickyStyle" style={{
        zIndex: 200,
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(20px)",
        top: "72px",
        borderBottom: state === 'top' ? 'none' : '1px solid rgba(0,0,0,0.1)',
        padding: '5px'
    }}>
        <Grid.Container alignItems='center' direction='row'>
            <Grid xs={8}>
                {shouldShowBackButton ?
                    <div onClick={() => { router.back() }}>
                        <Back />
                    </div> : ""}
            </Grid>
            <Grid xs={8} justify="center" style={{ textAlign: "center", fontWeight: 600 }}>
                {state === 'scroll' ? children : ""}
            </Grid>
            <Grid xs={8} style={{ textAlign: "right" }}>
            </Grid>
        </Grid.Container>
    </motion.div>
}

export { HeadSection, WrappedTeamSwitcher, TopBar, DesktopMenuList, TitleBar }