import { PlantLoader } from "../../../components/plant"
import SeedProvider from "../../../components/providers"
import { useProvider, useProviders } from "../../../data"
import { EmptyState } from "../../../ui"
import { plantTypes } from "../../plant/types"

const ProviderList = ({ callback, type }) => {

    const { providers } = useProviders({ type })

    if (!providers) return <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
        <PlantLoader></PlantLoader>
        <PlantLoader></PlantLoader>
        <PlantLoader></PlantLoader>
    </div>


    if (providers.length === 0) return (
        <EmptyState title={"No " + plantTypes[type]?.title + " Providers"} subtitle='Use the "Add Manually" option above'></EmptyState>
    )

    return <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
        {providers.map(provider => {
            return <SeedProvider key={provider._id} tap={() => callback(provider)} title={provider.name} image={provider.logo} />
        })}
    </div>
}

export default ProviderList