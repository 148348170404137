import { fillFromObject } from "../utils"
import Provider from '../provider'
import Strain from '../strain'
import Network from "../../services/network";

const getOrCreateProvider = async (user, data, type) => {
    if (!data) throw new Error("No provider data provided")
    const { mode, payload } = data
    if (mode === 'manual') {
        const { name, website } = payload
        if (!name) throw new Error("No provider name provided")
        if (!website) throw new Error("No provider website provided")
        const provider = await Provider.create(user, { name, website, type })
        return provider._id
    } else {
        return payload._id
    }
}

const getOrCreateStrain = async (user, provider, data, type) => {
    if (!data) throw new Error("No provider data provided")
    const { mode, payload } = data
    if (mode === 'manual') {
        const { name } = payload
        if (!name) throw new Error("No provider name provided")
        const strain = await Strain.create(user, provider, name, type)
        return strain._id
    } else {
        return payload._id
    }
}

class Genetic {
    constructor(data) {
        fillFromObject(data, this);
    }

    static dataUrl = "/api/v1/genetics";

    static async create(user, data, type) {

        const provider = await getOrCreateProvider(user, data.provider, type)
        const strain = await getOrCreateStrain(user, provider, data.strain, type)

        const { quantity } = data

        await Network.post(this.dataUrl, user.accessToken, {
            provider,
            strain,
            quantity,
            type
        })
    }


    async use(user, data) {

        const { date, space, quantity, makeBatch, workflow } = data

        const res = await Network.post(Genetic.dataUrl + "/" + this._id + "/use", user.accessToken, {
            quantity,
            space,
            date,
            makeBatch,
            workflow
        })

        return res
    }
}

export default Genetic;