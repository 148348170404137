import steps from "./steps"
import {PopupStepper} from "../../../ui"
import Workflow from '../model'
import { useUser } from '../../../utils/useUser'
import { useRouter } from 'next/router'

const AddWorkflowModal = ({ onClose }) => {
    const { user } = useUser()
    const router = useRouter()

    const callback = async (data) => {
        const workflow = await Workflow.create(user, data)
        await router.push("/workflows/" + workflow._id)
        onClose()
    }

    return <PopupStepper callback={callback} onClose={onClose} steps={steps}></PopupStepper>

}


export default AddWorkflowModal