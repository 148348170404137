import { EditableData, Button } from "../../../../../ui"
import { Grid, Spacer } from "@geist-ui/core"
import { plantTypes } from "../../../../../../common/variables"
import { labels } from "../helpers"
import { useState } from "react"


const Confirmation = ({ data, onComplete }) => {
    const [loading, setLoading] = useState(false)

    const handleSubmission = async () => {
        setLoading(true)
        await onComplete()
        setLoading(false)
    }

    return <>
        <EditableData
            icon="List"
            subtitle={plantTypes[data.type]?.title}
            tap={() => handleEdit('type')}
            title="Type"
        />
        <EditableData
            icon="ArrowSquareIn"
            subtitle={labels[data.source]}
            tap={() => handleEdit('source')}
            title="Source"
        />
        <EditableData
            icon="Bank"
            subtitle={data.provider.payload.name}
            tap={() => handleEdit('provider')}
            title="Provider"
        />
        <EditableData
            icon="GitMerge"
            subtitle={data.strain.payload.name}
            tap={() => handleEdit('strain')}
            title="Strain"
        />
        <EditableData
            icon="Hash"
            subtitle={data.quantity}
            tap={() => handleEdit('quantity')}
            title="Quantity"
        />
        <Spacer w={100} />
        <Spacer w={100} h={2} />
        <Grid xs={24} alignItems="center" direction="column" alignContent="stretch">
            <Button loading={loading} fullWidth onClick={handleSubmission}>
                Add Strain
            </Button>
        </Grid></>

}

const onComplete = async (setStep, submit) => {
    await submit()
}

const confirmationStep = {
    View: Confirmation,
    title: "Confirm Details",
    subtitle: "Double check this is as you want it",
    onComplete
}

export default confirmationStep