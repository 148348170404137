import {Confirmation, SubStep} from "./views"
import { conditionTypes, spaceTypes } from '../../../utils/static'

const subSteps = {
    conditions: { data: conditionTypes, key: 'conditions', title: 'Select Grow Conditions' },
    type: { data: spaceTypes, key: 'type', title: 'Select Space Type' }
}

const steps = {
    confirmation: {
        title: "Add a Space",
        subtitle: "Spaces are locations which contain plants.",
        View: Confirmation,
        onComplete: async (setStep, submit, params) => {
            try {
                await submit(true)
            } catch (e) {
                console.log(e)
            }
        }
    },
    conditions: {
        title: "Select Grow Conditions",
        subtitle: "Select the conditions your plants will be grown in.",
        sub: true,
        onBack: (setStep) => {
            setStep('confirmation')
        },
        View: ({ onChange, onComplete }) => <SubStep onComplete={onComplete} config={subSteps.conditions} onChange={onChange} ></SubStep>,
        onComplete: (setStep, submit) => {
            setStep('confirmation')
        }
    },
    type: {
        title: "Select Space Type",
        subtitle: "Select the type of space you are adding.",
        View: ({ onChange, onComplete }) => <SubStep onComplete={onComplete} config={subSteps.type} onChange={onChange} ></SubStep>,
        sub: true,
        onBack: (setStep) => {
            setStep('confirmation')
        },
        onComplete: (setStep, submit) => {
            setStep('confirmation')
        }
    },

}
export default steps