import * as React from "react"
import { cn } from "@/client/utils/lib/utils"
import styles from "./card.module.css"

type CardProps = React.HTMLAttributes<HTMLDivElement> 

interface CardComponent extends React.ForwardRefExoticComponent<CardProps & React.RefAttributes<HTMLDivElement>> {
  Section: typeof Section;
}

const Card = React.forwardRef<
  HTMLDivElement,
  CardProps
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      `rounded-lg border bg-card text-card-foreground shadow-sm ${styles.card}`,
      className
    )}
    {...props}
  />
)) as CardComponent
Card.displayName = "Card"

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
))
CardFooter.displayName = "CardFooter"

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }


interface CardSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    title?: string
    pad?: boolean
    Icon?: React.ComponentType<{ size: number }>
}

const Section = ({ children, title, pad, Icon }: CardSectionProps) => {
    return <div style={{ padding: pad ? "0px 12px" : "10px" }}>
        <div style={{ display: "flex", alignItems: 'center', gap: 8, marginBottom: "16px" }}>
            {Icon ? <Icon size={24}></Icon> : null}
            {title && <h4 style={{ margin: 0, fontWeight: 600, fontSize: "16px" }}>{title}</h4>}</div>
        {children}
    </div>
}

Card.Section = Section

export default Card;