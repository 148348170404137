import React, {useState} from "react"
import { ChromePicker } from 'react-color'
import styles from './color-picker.module.css'

const ColorPicker = ({ onChange, value, hideValue }) => {

  const [color, setColor] = useState(value ?? "#000000")

  const [displayColorPicker, setDisplayColorPicker] = useState(false)

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  return <div>
    <div className={styles.button} onClick={handleClick}>
      <div className={styles.dot} style={{ background: color }}></div>{!hideValue ? color : null}</div>
    {displayColorPicker ? <div className={styles.popover}>
      <div className={styles.cover} onClick={handleClose} />
      <ChromePicker color={color} value={color} onChange={newColor => {
        setColor(newColor.hex)
        if (onChange) onChange(newColor.hex)
      }} />
    </div> : null}
  </div>
}

export default ColorPicker