import Image from "next/image"
import { useRouter } from "next/router";
import { FolderSimple } from "phosphor-react";
import { plantTypes } from "../../../../common/variables";
import { PlantListItem as FramerPlantListItem } from "../framer";
import styles from './list-item.module.css'
import CurrentStatus from "../../workflow/status";

const PlantListItem = ({ plant, tap, selected, selectMode, lastChild }) => {

    const router = useRouter()

    const type = plant?.strain?.type ?? "cannabis"
    const image = plantTypes[type]?.icon

    if (!selectMode) {
        return <div onClick={tap ? tap : () => {
            router.push('/plants/' + plant._id)
        }} className={styles.container}>
            <div className={styles.left}>
                <Image alt='icon' src={image} objectFit='contain' width={50} height={50}></Image>
                <div>
                    <div className={styles.title}>{plant.name}</div>
                    <div className={styles.strain}>{plant.strain?.name + " from " + plant?.strain?.provider?.name}</div>
                </div>
            </div>
            <div className={styles?.batchIndicator}><FolderSimple></FolderSimple> {plant?.batch?.name}</div>
            <div className={styles.right}>

                <CurrentStatus status={plant?.status} workflow={plant?.workflow}/>
            </div>
        </div>
    }

    return <FramerPlantListItem
        style={{ width: "100%" }}
        tap={tap}
        variant={"selectable"}
        batchStatus={plant?.batch ? "has-batch" : "no-batch"}
        status={plant.status ?? "--"}
        image={plant?.image}
        selected={selected}
        title={plant.name ?? "--"}
        key={plant._id}
        strain={plant?.strain?.name + " from " + plant?.strain?.provider?.name ?? "---"}
    />
}

export default PlantListItem

