import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { useUser } from "../utils/useUser"

const useUserData = (id) => {

    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const { data, error } = useSWR(user ? ['/api/v1/users/' + id, user.accessToken] : null, Network.get)

    const refreshUserData = async () => {
        await mutate('/api/v1/users/' + id)
    }

    return {
        userData: data,
        userDataError: error,
        userDataLoading: !error && !data,
        refreshUserData
    }

}

export {useUserData}