import {Grid, Spacer} from "@geist-ui/core"
import { useUser } from "../../utils/useUser"
import { FixedData, SecondaryButton } from "../../ui"
import QRCode from "react-qr-code";
import { Hash, Shield, ShieldCheck } from "phosphor-react";

const TransactionInfoPopup = ({ transaction, genetic, dismiss }) => {

    const { number, added, token, form } = transaction

    const Wrapper = ({ children }) => {
        return <div>
            <Spacer /><Grid.Container gap={3} justify="center">
                {children}
            </Grid.Container></div>
    }

    const { user, logout } = useUser()

    if (form === 'clone') return <></>

    return <Wrapper>
        <Grid xs={24} direction="column" alignItems="center">
            {genetic?.strain?.verified ? <ShieldCheck size={30} /> : <Shield size={30} />}
            <Spacer />
            <h3>{genetic?.strain?.verified ? "Verified" : "Unverified"} {form !== 'clone' ? "Seeds" : "Clones"} Added</h3>
        </Grid>
        <Grid>
            <FixedData icon={Hash} title="Quantity" text={`${transaction.number}`} />
        </Grid>
        {genetic.strain.verified ?
            <Grid>
                <QRCode value={`${window.location.origin}/verify/${genetic.strain.provider.id}?t=${token}`} /></Grid> : ""}
        <Grid xs={24}>
            <SecondaryButton onClick={dismiss} width="100%">
                Dismiss
            </SecondaryButton>
        </Grid>
    </Wrapper>
}

export default TransactionInfoPopup