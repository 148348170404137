import {handleKeyAndValue} from "./helpers"
import {skipList} from "../constants"

const buildQueryFromFilter = (query) => {
    if (Object.keys(query).length === 0) return ""
    var output = "?"
    const queryKeys = Object.keys(query)
    queryKeys.forEach(key => {
        if (skipList.includes(key)) output += key + "=" + query[key] + "&"
        else if (typeof query[key] === 'string') output += "filter[" + key + "]" + "=" + "eq(" + query[key] + ")&"
        else {
            const value = query[key]
            const handledValue = handleKeyAndValue(key, value)
            output += handledValue
        }
    })
    return output
}


export { buildQueryFromFilter }