import WarningFromFramer from "https://framer.com/m/Warning-jEyl.js@YqGEaqZ4sbKCPDei1pes"
import { WarningCircle } from "phosphor-react"
import styles from './warning.module.css'


const Warning = ({ text, children, variant, icon }) => {
    return <WarningFromFramer icon={icon} variant={variant} style={{ width: "100%" }} text={text || children} />
}

const WarningWithAction = ({ text, children, variant, icon, actionText, action }) => {
    const variants = {
        "error": {
            color: "#FF0000",
            backgroundColor: "#FFEEEE",
        },
        "warning": {
            color: "#ff740a",
            backgroundColor: "rgba(255, 116, 10, 0.14)",
        },
        "success": {
            color: "#00FF00",
            backgroundColor: "#EEFFEE",
        },
    }


    const style = variants[variant ?? "warning"]

    return <div className={styles.container} style={style}>
        <div className={styles.leftBlock}>
        <WarningCircle size={24}></WarningCircle>
        <div className={styles.text}>{children || text}</div>
        </div>
        <div onClick={action} className={styles.button} style={{ backgroundColor: style.color }}>{actionText}</div>
    </div>
}
export { Warning, WarningWithAction }