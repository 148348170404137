import steps from "./steps"
import {PopupStepper} from "../../../ui"
import { useUser } from '../../../utils/useUser'
import { useRouter } from 'next/router'
import Webhook from '../model'

const AddWebhookPopup = ({ onClose }) => {
    const { user } = useUser()
    const router = useRouter()

    const callback = async (data) => {
        const {trigger, url} = data
        const webhook = await Webhook.create(user, url, trigger)
        router.push(`/webhooks/${webhook._id}`)
        onClose()
    }

    return <PopupStepper initialStep='trigger' callback={callback} onClose={onClose} steps={steps}></PopupStepper>

}


export default AddWebhookPopup