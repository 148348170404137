import {Grid, Spacer} from "@geist-ui/core"
import { Input, ListAction, Button } from '../../../ui'
import { eventCategories } from '../../../utils/event'


const URLStep = ({ onComplete, onChange, data }) => {

    return <>
        <Input onEnter={onComplete} value={data?.name} onChange={e => { onChange('url', e.target.value) }} placeholder='https://example.com/webhook'></Input>
        <Spacer></Spacer>
        <Button onClick={onComplete} fullWidth>Next</Button>
    </>

}

const TriggerStep = ({ onComplete, onChange, data }) => {

    return <div style={{ width: "100%", maxHeight: "250px", overflowY: "scroll" }}>
        <Grid.Container direction='column' alignContent='stretch' style={{width: "100%"}}>
            {Object.keys(eventCategories).map((key, index) => {
                const eventDetails = eventCategories[key]
                return <Grid key={index}><ListAction color={eventDetails?.color} icon={eventDetails.icon} key={index} tap={() => { onChange('trigger', key); onComplete() }} title={eventDetails.title}></ListAction></Grid>
            })}
        </Grid.Container>
    </div>

}


export { URLStep, TriggerStep }