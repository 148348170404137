import useSWR, {useSWRConfig} from "swr"
import { useUser } from "../utils/useUser"
import { buildQueryFromFilter } from "../services/network"
import Network from "../services/network"


const useEvents = (filter) => {

    const { user } = useUser()
    const { mutate } = useSWRConfig()
    const url = '/api/v1/events' + (filter ? buildQueryFromFilter(filter) : "")
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshEvents = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        events: data,
        isError: error,
        loading: !data && !error,
        refreshEvents
    }

}

export { useEvents }