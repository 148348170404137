import React from "react"
import { Grid, Spacer } from "@geist-ui/core"
import { useState } from "react"
import defaultSteps from "./statics"
import Wrapper from "./wrapper"
import { PopupStepperProps, Steps } from "./types"

const getStep = (stepKey: string, steps: Steps) => {

    if (!steps) throw new Error("No steps provided")

    if (stepKey && steps[stepKey]) return steps[stepKey]

    if (stepKey && defaultSteps[stepKey]) return defaultSteps[stepKey]

    else return defaultSteps.error
}

const PopupStepper = ({ steps, onClose, callback, initialStep, excludeIf, initialData, secondaryButton, hideDismiss, ...props }: PopupStepperProps) => {

    const [step, setStep] = useState<string>(initialStep ?? Object.keys(steps)?.[0])
    const [res, setRes] = useState<string|any>()
    const [data, setData] = useState({
        title: "",
        type: "",
        ...initialData
    })

    const submit = async (throwError?: boolean) => {
        const copiedStep = step
        setStep("loading")
        try {
            if (callback) await callback(data)
            setStep("done")
        } catch (e: any) {
            console.log(e)
            const { message, response } = e
            if (response) {
                const { data } = response
                if (data) {
                    if (data.message) {
                        setRes(data.message)
                    }
                }
            } else {
                setRes(message)
            }
            if (throwError) {
                setStep(copiedStep)
                throw e
            } else {
                setStep("error")
            }
        }
    }

    const next = async (e: {preventDefault: ()=>void}) => {
        if (e) e.preventDefault()
        const currentStep = steps[step]
        if (!currentStep) throw new Error("No step found")
        const {onComplete} = currentStep

        try {
            if (typeof onComplete === 'function') {
                await onComplete(setStep, submit, { data, setData })
            }
        } catch (err: any) {
            const { message, response } = err
            if (response) {
                const { data } = response
                if (data) {
                    if (data.message) {
                        setRes(data.message)
                    }
                }
            } else {
                setRes(message)
            }
            setStep("error")
        }

    }

    const updateData = (key: string, value: any) => {
        const copied = { ...data }
        copied[key] = value
        setData(copied)
    }

    const current = getStep(step, steps)
    const { View } = current

    return <Wrapper hideDismiss={hideDismiss} secondaryButton={secondaryButton} {...current} onClose={onClose} {...props}>
        <Spacer></Spacer>
        <Grid.Container direction="column">
            {View ? <View excludeIf={excludeIf} setData={setData} data={data} res={res} onChange={updateData} setStep={setStep} onComplete={next}></View> : "No View Provided"}
        </Grid.Container>
    </Wrapper>
}

export default PopupStepper