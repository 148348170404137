import {upperFirst} from "lodash"
import { GridFour } from "phosphor-react"
import Skeleton from "react-loading-skeleton"
import {ListItemIcon} from "../../../ui"
import styles from './list-item.module.css'

const SpaceListItem = ({ space, tap, style, xs }) => {
    return <div className={styles.container} onClick={() => tap(space._id)} style={style}>
        <div className={styles.left}>
            <ListItemIcon CustomIcon={GridFour}></ListItemIcon>
            <div className={styles.titles}>
                <div className={styles.title}>
                    {space?.name ??
                        <Skeleton width="100px" />}
                </div>
                {xs ? "" :
                    <div className={styles.subtitle}>
                        {space?.type ? upperFirst(space?.conditions + " " + space?.type + " space") : <Skeleton width="300px" />}
                    </div>}
            </div>
        </div>
        <div className={styles.right}>
           {space?.plants?.length + " plants"}
        </div>
    </div>
}


export default SpaceListItem