import {eventCategories} from "../../common/variables"

const getEventsAvailableToUser = () => {

    var res = {}
    Object.keys(eventCategories).map(key => {
        if (!eventCategories[key].util) res[key] = eventCategories[key]
    })
    
    return res
}

export { eventCategories, getEventsAvailableToUser }