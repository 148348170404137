import {Display} from "@geist-ui/core"
import EmptyStateFramer from "https://framer.com/m/EmptyState-Ntf6.js@GwVwUyGzNsF0Pff4jNVL"

const EmptyState = props => {
    const { icon, title="Nothing to see here", subtitle, variant } = props

    if (!props.variant) {
        return  <Display style={{ opacity: 0.5, textAlign: "center" }}>
            {props.icon}
        <h4 style={{ fontWeight: 500, margin: 0 }}>{title}</h4>
        <p style={{margin: 0}}>{props.subtitle}</p>
    </Display>
    }
    return <EmptyStateFramer
        layout='Desktop'
        style={{
            width: props?.style?.width ?? "100%",
            maxWidth: "450px",
            margin: "auto"
        }}
        {...props} />
}
export default EmptyState