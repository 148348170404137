const conditionTypes = {
	indoor: {
		title: "Indoor Space",
		icon: "Lightbulb",
		subtitle: "Controlled Light",
	},
	outdoor: {title: "Outdoor Space", icon: "Sun", subtitle: "Natural Light"},
	greenhouse: {
		title: "Greenhouse Space",
		icon: "House",
		subtitle: "Mixed Conditions",
	},
}

const spaceTypes = {
    'grow': { title: 'Grow Space', subtitle: "A space for growing plants", icon: "Leaf" },
    'drying': { title: 'Drying Space', subtitle: "A space for drying bud", icon: "Wind" },
    'processing': { title: 'Processing Space', subtitle: "A space for processing", icon: "TestTube" },
    'breeding': { title: 'Breeding Space', subtitle: "Special space for breeding", icon: "Shuffle" },
    'storage': { title: "Storage Space", subtitle: "A space for storing plants", icon: "Cube" }
}

const statuses = {
    "created": "unverified",
    "verification_requested": "pending",
    "verified": "verified"
}

export {conditionTypes, spaceTypes, statuses}