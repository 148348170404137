import {useState} from "react"
import { Spacer } from "@geist-ui/core"
import { SecondaryButton, ListAction } from "../../ui"
import { ModalHeader } from "./components"
import { conditionTypes } from "../../utils/static"

const ChangeSpaceConditionsPopup = ({ onClose, callback }) => {
    const [data, setData] = useState({
        conditions: "outdoor",
        type: "grow",
    })

    const updateData = (newData) => {
        setData({ ...data, ...newData })
    }

    const updateConditions = async (conditions) => {
        updateData({ conditions })
        await callback(conditions)
        onClose()
    }

    return <div style={{ padding: "20px" }}>
        <ModalHeader style={{width: "100%"}} subtitle='For this space' title='Select Grow Conditions' />
        <Spacer h={2} />
        {Object.keys(conditionTypes).map(condition => {
            return <ListAction key={condition.title} style={{ width: "100%" }} tap={() => updateConditions(condition)} subtitle={conditionTypes[condition].subtitle} icon={conditionTypes[condition].icon} title={conditionTypes[condition].title} />
        })}
        <Spacer h={2} />
        <SecondaryButton style={{ width: "100%" }}>
            Dismiss
        </SecondaryButton>
    </div>
}

export default ChangeSpaceConditionsPopup