import {Popover} from "@geist-ui/core"
import { DateFilterPopover, PopoverValueList } from "./toolbar/popovers"
import moment from "moment"

class TableFilterOptions {
    constructor(title) {
        this.title = title
    }
}

const allowedTypes = ['list', 'date']

class TableFilter {
    constructor(type, title, key, values) {
        if (!type || !title || !key) throw new Error("TableFilter: Missing required parameters")
        if (!allowedTypes.includes(type)) throw new Error("TableFilter: Invalid type")
        this.title = title
        this.key = key
        this.values = values
    }

    getPopover() {
        return "No Popover"
    }

    getListItem(setDetail) {
        return <Popover.Item disableAutoClose onClick={() => setDetail(this.key)} key={this.key}>
            <span>{this.title}</span>
        </Popover.Item>
    }

    getChipText(currentValue) {
        return this.values[currentValue.value]?.title
    }
}


class DateTableFilter extends TableFilter {
    constructor(title, key) {
        super("date", title, key)
    }

    getPopover(onChange, filter) {
        const handler = (value) => onChange(this.key, value)
        return <DateFilterPopover onChange={handler} value={filter[this.key]}></DateFilterPopover>
    }

    getChipText(currentValue) {
        const from = moment(currentValue.value[0].value).format("MMM Do")
        const to = moment(currentValue.value[1].value).format("MMM Do")
        return `${from} - ${to}`
    }
}

class ListTableFilter extends TableFilter {
    constructor(title, key, values, overrideHandler) {
        super("list", title, key, values)
        this.overrideHandler = overrideHandler
    }

    getPopover(onChange, filter) {
        const handler = value => {
            const filterValue = this.overrideHandler ? this.overrideHandler(value) : { operation: "eq", value }
            onChange(this.key, filterValue)
        }
        return <PopoverValueList onChange={handler} values={this.values}></PopoverValueList>
    }
}


class BoolTableFilter extends TableFilter {
    constructor(title, key, value, invert = false) {
        super("list", title, key, {
            true: {
                title: "Yes"
            },
            false: {
                title: "No"
            }
        })
        this.value = value
        this.inverted = invert
    }

    getPopover(onChange, filter) {
        const handler = (value) => {
            let shouldBeEqual = value.value === "true"
            if (this.inverted) shouldBeEqual = !shouldBeEqual
            const operation = shouldBeEqual ? "eq" : "ne"
            onChange(this.key, { operation: operation, value: this.value })
        }
        return <PopoverValueList onChange={handler} values={this.values}></PopoverValueList>
    }

    getChipText(currentValue) {
        let shouldBeEqual = currentValue.operation === "eq"
        if (this.inverted) shouldBeEqual = !shouldBeEqual
        return (!shouldBeEqual ? ("Not " + this.title) : this.title)
    }
}

export { TableFilterOptions, TableFilter, DateTableFilter, ListTableFilter, BoolTableFilter }