

const getMajorityStatus = (plants) => {
	const counter = {}

	plants.forEach((plant) => {
		if (!counter[plant.status]) counter[plant.status] = 0
		counter[plant.status]++
	})

	let largestCount = 0
	let finalStatus = ""

	Object.keys(counter).forEach((key) => {
		if (counter[key] > largestCount) {
			largestCount = counter[key]
			finalStatus = key
		}
	})

	return finalStatus
}

export {getMajorityStatus}