import useSWR, { useSWRConfig } from "swr"
import Genetic from "../core/genetic"
import Network from "../services/network"

import { buildQueryFromFilter } from "../services/network"
import { useUser } from "../utils/useUser"

const useGenetics = () => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = '/api/v1/genetics'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshGenetics = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        genetics: data,
        refreshGenetics
    }
}

const useActiveGenetics = () => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = Genetic.dataUrl + buildQueryFromFilter({ filter: { archived: { operation: "eq", value: "false" } } })
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshGenetics = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        genetics: data,
        refreshGenetics
    }
}

const useArchivedGenetics = () => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = Genetic.dataUrl + buildQueryFromFilter({ filter: { archived: { operation: "eq", value: "true" } } })
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshGenetics = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        genetics: data,
        refreshGenetics
    }
}


const useGenetic = (id) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser()
    const url = id ? '/api/v1/genetics/' + id : '/api/v1/genetics/'
    const { data, error } = useSWR(user ? [url, user.accessToken] : null, Network.get)

    const refreshGenetic = async () => {
        await mutate(user ? [url, user.accessToken] : null)
    }

    return {
        genetic: data ? new Genetic(data) : null,
        refreshGenetic
    }
}


export { useGenetics, useGenetic, useActiveGenetics, useArchivedGenetics }