import { Spacer } from "@geist-ui/core"
import { PlantTypeList } from "../../../../plant/types"

const PlantTypeStep = ({ onChange, onComplete }) => {
    return <><PlantTypeList onClick={typeKey => {
        onChange("type", typeKey)
        onComplete()
    }} />
    <Spacer/>
    </>
}

const onComplete = (setStep) => {
    setStep("source")
}

const plantTypeStep = {
    title: "Type",
    subtitle: "What type of genetics are you adding?",
    View: PlantTypeStep,
    onComplete,
}

export default plantTypeStep