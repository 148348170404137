const singulars = {
    plants: "plant",
    batches: "batch",
    spaces: "space",
    users: "user",
    teams: "team",
    notifications: "notification",
}


const oneDayInMilliseconds = 24 * 60 * 60 * 1000

const repeat_task_intervals = {
    "daily": oneDayInMilliseconds,
    "weekly": 7 * oneDayInMilliseconds,
    "monthly": 30 * oneDayInMilliseconds,
}


const repeat_task_titles = {
    "daily": "Day(s)",
    "weekly": "Week(s)",
    "monthly": "Month(s)",
}


const eventCategories = {
    custom: {
        activeTitle: 'Custom',
        inactiveTitle: 'Custom',
        icon: 'DotsThree',
        color: '#000',
        preposition: "for",
        description: 'A custom event or task',
    },
    water_added: {
        activeTitle: "Add Water",
        icon: 'Drop',
        title: "Water Added",
        color: '#3565DE',
        bgColor: '#D1DCF5',
        preposition: "to",
        description: 'Add water to your plants',
        util: false,
        allowedContexts: ['plants', 'batches', 'spaces'],
    },
    phase_changed: {
        activeTitle: "Record Phase Change",
        icon: 'Sun',
        title: "Phase Changed",
        color: '#FF6600',
        bgColor: '#FDE0CC',
        preposition: "of",
        description: 'Add water to your plants',
        util: true,
        allowedContexts: ['plants'],
    },
    pest_control: {
        activeTitle: "Add Pest Control",
        color: "rgb(255, 0, 174)",
        bgColor: '#FFC5EB',
        icon: "Drop",
        preposition: "to",
        description: "Report use of pest control",
        title: "Pest Control",
        info: "Use Genetic",
        variant: "primary",
        util: false,
        allowedContexts: ['plants', 'batches', 'spaces'],
    },
    trimmed: {
        activeTitle: "Trim",
        preposition: "",
        color: "#C70049",
        bgColor: "#F7C5D5",
        icon: "Scissors",
        description: "Trim or prune your plant",
        title: "Trimming",
        info: "Use Genetic",
        variant: "primary",
        util: false,
        allowedContexts: ['plants', 'batches', 'spaces'],

    },
    nutrients_added: {
        activeTitle: "Add Nutrients",
        icon: 'Flask',
        preposition: "to",
        title: "Nutrients Added",
        color: '#00B315',
        bgColor: '#C0EECC',
        description: 'Add nutrients to your plants',
        util: false,
        allowedContexts: ['plants', 'batches', 'spaces']
    },

    grow_started: {
        activeTitle: "Start Grow",
        icon: 'Play',
        preposition: "",
        bgColor: "white",
        color: "black",
        title: "Grow Started",
        description: 'Add water to your plants',
        util: true,
        allowedContexts: ['plants', 'spaces', 'batches', 'genetics']
    },

    added_to_batch: {
        activeTitle: "Add to Batch",
        icon: 'Plus',
        preposition: "",
        bgColor: "white",
        color: "black",
        title: "Added to Batch",
        util: true,
        allowedContexts: ['plants', 'spaces', 'batches', 'genetics']
    },
    moved_to_space: {
        activeTitle: "Move to Space",
        icon: 'ArrowSquareOut',
        bgColor: "white",
        color: "black",
        title: "Changed Space",
        util: true,
        allowedContexts: ['plants', 'spaces', 'batches']
    },
    uprooted: {
        activeTitle: "Uproot",
        icon: 'X',
        bgColor: "white",
        color: "black",
        title: "Uprooted",
        util: true,
        allowedContexts: ['plants', 'spaces', 'batches']
    },
    photo: {
        activeTitle: "Add Photo",
        icon: 'Camera',
        bgColor: "white",
        color: "black",
        description: 'Add a photo of your plants',
        title: "Add Photo",
        allowedContexts: ['plants', 'spaces', 'batches'],
    },
    genetic_material_added: {
        activeTitle: "Add Genetic Material",
        icon: 'ArrowSquareIn',
        bgColor: "white",
        color: "black",
        title: "Genetic Material Added",
        util: true,
        allowedContexts: ['genetics']
    },
    verification_requested: {
        activeTitle: "Request Verification",
        icon: 'Timer',
        bgColor: "white",
        color: "black",
        title: "Verification Requested",
        util: true,
        allowedContexts: ['genetics']
    },
    verified: {
        activeTitle: "Verify",
        icon: 'Check',
        bgColor: "white",
        color: "black",
        title: "Genetic Verified",
        util: true,
        allowedContexts: ['genetics']
    },
    member_added: {
        activeTitle: "Add Member",
        icon: 'UserPlus',
        bgColor: "white",
        color: "black",
        title: "Member Added",
        util: true,
        allowedContexts: ['teams']
    },
    location_updated: {
        activeTitle: "Update Location",
        icon: 'MapPin',
        bgColor: "white",
        color: "black",
        title: "Location Updated",
        util: true,
        allowedContexts: ['spaces']
    },
    team_created: {
        activeTitle: "Create Team",
        icon: 'Users',
        bgColor: "white",
        color: "black",
        title: "Team Created",
        util: true,
        allowedContexts: ['teams']
    },
    clone_cut: {
        activeTitle: "Cut Clone",
        icon: "Scissors",
        bgColor: "white",
        color: "black",
        title: "Clone Cut",
        util: true,
        allowedContexts: ['plants']
    },
    "harvest.created": {
        activeTitle: "Create Harvest",
        icon: 'Plus',
        bgColor: "white",
        color: "black",
        title: "Harvest Created",
        util: true,
        allowedContexts: ['harvests']
    }, 
    "harvest.filled": {
        activeTitle: "Fill Harvest",
        icon: 'ArrowSquareIn',
        bgColor: "white",
        color: "black",
        title: "Harvest Filled",
        util: true,
        allowedContexts: ['harvests']
    }
}


const plantTypes = {
    "cannabis": {
        title: "Cannabis",
        icon: "/plant-icon.png",
    },
    microgreens: {
        title: "Microgreens",
        icon: "/microgreens.png",
    }
}


const intervals = {
    germination: {
        id: "germination",
        title: 'Germination',
        order: 1,
        lower: 3,
        upper: 10, 
    },
    seedling: {
        id: "seedling",
        title: 'Seedling',
        order: 2,
        lower: 14,
        upper: 17
    },
    vegetative: {
        id: "vegetative",
        title: 'Vegetative',
        order: 3,
        lower: 21,
        upper: 112
    },
    flowering: {
        id: "flowering",
        title: 'Flowering',
        order: 4,
        lower: 56,
        upper: 847,
    },
    harvest: {
        id: "harvest",
        title: 'Harvest',
        order: 5,
        lower: 0,
        upper: 1
    },
    drying: {
        id: "drying",
        title: 'Drying',
        order: 6,
        lower: 2,
        upper: 7
    },
    curing: {
        id: "curing",
        title: 'Curing',
        order: 7,
        lower: 14,
        upper: 60
    },
    done: {
        id: "done",
        title: 'Done',
        order: 8,
        lower: 0,
        upper: 1
    }
}

const typeOptions = {
    temperature: {
        title: 'Temperature',
        icon: 'Thermometer',
        unit: '℃',  // degrees celsius, 
    },
    humidity: {
        title: 'Humidity',
        icon: 'CloudSun',
        unit: '%' // percentage
    },
    soil: {
        title: 'Soil Moisture',
        icon: 'Drop',
        unit: '%' // percentage
    },
    light: {
        title: 'Light',
        icon: 'Sun',
        unit: 'lux' // lux
    },
    wind_speed: {
        title: 'Wind Speed',
        icon: 'Wind',
        unit: 'm/s' // metres per second
    }
}


export { eventCategories, singulars, repeat_task_intervals, repeat_task_titles, plantTypes, intervals, typeOptions }