import {useState} from "react"
import Skeleton from 'react-loading-skeleton'
import { Pencil } from "phosphor-react"

import styles from './quick-edit.module.css'

const shakeElement = (element) => {
    element.classList.add("shake")
    setTimeout(() => {
        element.classList.remove("shake")
    }, 500)
}

const ActionButton = ({ handler, actionText }) => {
    return <div className={styles.actionButton} onClick={handler} type='success'>{actionText}</div>
}

const EditInputField = ({ value, setValue, style }) => {
    return <input type='text' autoFocus={true} size={value.length} style={{ padding: 0, border: "none", ...style, maxWidth: "min-content", borderBottom: "1px solid #e0e0e0", background: "none" }} value={value} onChange={setValue}></input>
}

const QuickEdit = ({ plainTextTitle, title, value, onFinish, index, style }) => {

    const [editing, setEditing] = useState(false)
    const [actionText, setActionText] = useState("edit")
    const [textFieldValue, setTextFieldValue] = useState(value)
    const [loading, setLoading] = useState(false)
    const [showEditeable, setShowEditeable] = useState(false)
    const [error, setError] = useState(false)

    if (loading) {
        return <Skeleton></Skeleton>
    }

    const onEditText = (e) => {
        const targetValue = e.target.value
        setTextFieldValue(targetValue)
    }

    const onMouseEnter = () => setShowEditeable(true)
    const onMouseLeave = () => setShowEditeable(false)

    const handler = () => {
        if (!editing) {
            setTextFieldValue(value || "")
            setEditing(true)
            setActionText("cancel")
        } else {
            setEditing(false)
            setActionText("edit")
        }
    }

    const onSave = async e => {

        if (e) e.preventDefault()
        if (!textFieldValue || !textFieldValue.length) {
            shakeElement(e.target)
            return
        }
        setLoading(true)
        try {
            await onFinish(textFieldValue, index)
            setLoading(false)
            handler()
        } catch (err) {
            setLoading(false)
            setError(err.message)
            shakeElement(e.target)
            setTimeout(() => { setError() }, 3000)

        }
    }

    if (editing) {
        return <form onSubmit={onSave} className={styles.form}>
            <EditInputField style={style} value={textFieldValue} setValue={onEditText} />
            <div className={styles.note} style={{ color: error ? "red" : null }}>{error ?? "Press enter to save"}</div>
        </form>
    }

    if (!value) {
        return <>{value} <ActionButton handler={handler} actionText={"Add " + (plainTextTitle || title)}></ActionButton>  </>
    }

    return <div className={styles.staticContainer} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={handler} style={{ ...style }}>{value} {showEditeable ? <Pencil style={{ opacity: 0.5, marginLeft: "8px" }} weight='light'></Pencil> : null}</div>

}


export { QuickEdit }
