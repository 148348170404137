import {fillFromObject} from "../utils"
import Network from "../../services/network";

class Provider {
    constructor(data) {
        fillFromObject(data, this);
    }

    static dataUrl = "/api/v1/providers";

    static async create(user, data) {

        const { name, website, type } = data;
        const res = await Network.post(
            this.dataUrl,
            user.accessToken,
            {
                name,
                website, 
                type
            }
        );

        return res;
    }
}

export default Provider;