import {v4} from "uuid"
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage"
import app from "../utils/firebase"

function dataURItoBlob(dataURI) {

    var byteString = atob(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });

}

const uploadBlob = async (blob) => {
    const storage = getStorage();
    const storageRef = ref(storage, v4());
    return uploadBytes(storageRef, blob).then(async (snapshot) => {
        const url = await getDownloadURL(snapshot.ref);
        return url;
    });
}

const uploadFromDataURI = async (dataURI) => {
    const blob = dataURItoBlob(dataURI)
    const imageUrl = await uploadBlob(blob)
    return imageUrl
}

export { uploadFromDataURI }