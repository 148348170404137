import Workflow from "@/client/components/workflow/model";
import Network from "../../services/network";
import Space from "@/client/models/space";
import { HarvestCreateData, HarvestFillData, HarvestShape } from "./types";
import { User } from "@/client/components/user/types";

class Harvest implements HarvestShape {

    _id: string
    created: number
    owners: string[] // Team ID
    creators: string[] // Team ID
    yield: number //  in grams
    status: string
    workflow: Workflow
    space: Space
    type: string
    name: string
    fills: [{
        timestamp: number
        plants: string[]
        batches: string[],
        fillYield: number
    }]
    states: [{
        timestamp: number
        user: string
        from: string
        to: string
    }]

    constructor(data: HarvestShape) {
        this._id = data?._id
        this.created = data?.created
        this.owners = data?.owners
        this.creators = data?.creators
        this.yield = data?.yield
        this.status = data?.status
        this.workflow = new Workflow(data?.workflow)
        this.space = new Space(data?.space)
        this.type = data?.type
        this.name = data?.name
        this.fills = data?.fills
        this.states = data?.states
    }

    static baseURL = '/api/v2/harvests'

    static async create(user: User, yieldAmount: number, { batches, plants, space, workflow, photo, comment, state }: HarvestCreateData) {
        const res = await Network.post('/api/v2/harvests', user.accessToken, { yieldAmount, plants: plants, batches: batches, space, workflow, photo, comment, state })
        return new Harvest(res.data)
    }

    async fill(user: User, yieldAmount: number, { batches, plants, photo, comment, state }: HarvestFillData) {
        const { _id } = this
        const { data: updatedHarvest } = await Network.post(`/api/v2/harvests/${_id}/fill`, user.accessToken, { yieldAmount, photo, comment, batches, plants, state })
        return new Harvest(updatedHarvest)
    }

    async changeStatus(user: User, status: string) {
        const { _id } = this
        const data = await Network.put(`/api/v2/harvests/${_id}`, user.accessToken, { status })
        return new Harvest(data)
    }
}

export default Harvest