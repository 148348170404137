import confirmationStep from "./confirmation";
import strainStep from "./strain";
import providerStep from "./providers";
import sourceStep from "./source";
import typeStep from "./type";
import quantityStep from "./quantity";
import doneStep from "./done";
import unifiedListStep from "./unified-list";

const steps = {
    unifiedList: unifiedListStep,
    type: typeStep,
    source: sourceStep,
    provider: providerStep,
    strain: strainStep,
    quantity: quantityStep,
    done: doneStep,
    confirmation: confirmationStep,
}

export default steps