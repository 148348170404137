import { Grid, Spacer } from "@geist-ui/core"
import { EditableData, ListAction, Button, Input } from "../../../ui"
import { spaceTypes, conditionTypes } from '../../../utils/static'


const Confirmation = ({ onChange, onComplete, data, setStep }) => {
    return <Grid.Container direction="column">
        <Input
            onEnter={onComplete}
            fullWidth
            placeholder='Space Name'
            value={data.name}
            onChange={e => { onChange('name', e.target.value) }}
        />
        <Spacer h={2} />
        <EditableData title='Grow Conditions' tap={() => setStep('conditions')} subtitle={conditionTypes[data.conditions]?.title} />
        <Spacer h={0.5} />
        <EditableData title='Space Type' tap={() => setStep('type')} subtitle={spaceTypes[data.type]?.title} />
        <Spacer h={2} />
        <Button fullWidth onClick={onComplete}>Add Space</Button>
    </Grid.Container>
}


const SubStep = ({ onChange, config, onComplete }) => {
    const { data, key } = config
    const stepList = Object.keys(data)
    return <>
        <Grid.Container direction="column">
            {stepList.map(item => {
                const itemData = data[item]
                return <ListAction
                    key={item}
                    style={{ width: "100%" }}
                    tap={() => { onChange(key, item); onComplete() }}
                    {...itemData} />
            })}
        </Grid.Container>
        <Spacer h={1} />
    </>
}



export { Confirmation, SubStep }