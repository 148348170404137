import { Grid, Spacer } from "@geist-ui/core"
import { useState } from "react"
import { Input, Button } from "../../../../../ui"
import Validator from "../../../../../utils/validator"

const config = {
    name: {
        required: true,
    }
}

const ManualStrain = ({ onComplete, onChange }) => {
    const [name, setName] = useState("")

    const handle = async () => {
        Validator.validate(config, { name })
        await onChange("strain", {
            mode: 'manual',
            payload: {
                name
            }
        })
        await onComplete()
    }

    return <Grid>
        <Input onEnter={handle} onChange={e => setName(e.target.value)} placeholder='Name' fullWidth />
        <Spacer h={2} />
        <Button onClick={handle} fullWidth>
            Next
        </Button>
    </Grid>
}


const onComplete = (setStep) => {
    setStep("quantity")
}

const strainStep = {
    title: "Variety",
    subtitle: "Which variety did you get?",
    View: ManualStrain,
    onComplete
}

export default strainStep