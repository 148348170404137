import styles from './tag.module.css';

const get10PercentShade = rgba => {
    if (!rgba) return;
    const [r, g, b, a] = rgba.split(',').map(v => parseInt(v.replace(/\D/g, '')));
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}

const Tag = ({children, rgba, style}) => {
    const background = get10PercentShade(rgba);
    return <div className={styles?.tag} style={{background, color: rgba, ...style}}>{children}</div>
}

export default Tag;