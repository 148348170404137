import { Divider, Grid, Spacer } from "@geist-ui/core"
import ProviderList from '../../../../provider/list'
import { Input, ListAction, Button } from "../../../../../ui"
import { useState } from "react"
import validators from "validator"
import Validator from "../../../../../utils/validator"

const { isURL } = validators

const config = {
    name: {
        required: true,
    },
    website: {
        required: true,
        validators: [{ errorText: "Not a valid URL", checker: isURL }]
    }
}


const ManualProvider = ({ onChange, onComplete }) => {

    const [errorMessage, setErrorMessage] = useState()
    const [name, setName] = useState("")
    const [website, setWebsite] = useState("")

    const handle = async () => {
        try {
            Validator.validate(config, { name, website })
            await onChange("provider", {
                mode: 'manual',
                payload: { name, website }
            })
            await onComplete()
        } catch (error) {
            setErrorMessage(error.message)
            throw error
        }
    }

    return <Grid>
        {errorMessage && <div style={{ color: "var(--color-error)", marginBottom: "18px" }}>{errorMessage}</div>}
        <Input onEnter={handle} onChange={e => setName(e.target.value)} placeholder='Name' fullWidth />
        <Spacer />
        <Input onEnter={handle} onChange={e => setWebsite(e.target.value)} placeholder='Website' fullWidth />
        <Spacer h={2} />
        <Button onClick={handle} fullWidth>
            Next
        </Button>
    </Grid>
}


const ProviderListView = ({ data, onChange, onComplete }) => {

    const hijacker = provider => {
        onChange("provider", { payload: provider, mode: "list" })
        onComplete()
    }

    return <div>
        <ListAction tap={() => onChange("entryMode", "manual")} icon='TextT' title='Add Manually' subtitle="If you can't find what you're looking for in the list below"></ListAction>
        <Divider />
        <ProviderList type={data?.type} callback={hijacker} />
    </div>
}

const ProviderStep = ({ onChange, onComplete, data }) => {

    const isManual = (data.entryMode === 'manual' || data.source === 'other')

    const childprops = { onChange, onComplete, data }

    return <div>
        {isManual ?
            <ManualProvider {...childprops} /> :
            <ProviderListView {...childprops} />
        }
    </div>
}

const onComplete = (setStep) => {
    setStep("strain")
}


const providerStep = {
    View: ProviderStep,
    title: "Genetic Provider",
    subtitle: "Who did you get the genetic from?",
    onComplete
}
export default providerStep