"use client"

import * as React from "react"
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from "@radix-ui/react-icons"

import { cn } from "@/client/utils/lib/utils"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/client/ui/avatar"
import { Button } from "@/client/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/client/ui/command"
import { DialogTrigger } from "@/client/ui/dialog"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/client/ui/popover"
import CreateTeamDialog from "../CreateTeamDialog"


type Team = {
  _id: string
  name: string
  logo: string
}

type PopoverTriggerProps = React.ComponentPropsWithoutRef<typeof PopoverTrigger>

const makeAcronym = (sentence: string) => {
  return sentence
    .split(" ")
    .map((word) => word[0].toUpperCase())
    .join("")
}

interface TeamSwitcherProps extends PopoverTriggerProps {
  teams: Team[]
  team: Team
  onAdd: () => void
  switchTeam: (teamId: string) => Promise<void>
}

export default function TeamSwitcher({ className, teams, onAdd, team, switchTeam }: TeamSwitcherProps) {
  const [open, setOpen] = React.useState(false)

  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false)

  return (
    <CreateTeamDialog callback={onAdd} open={showNewTeamDialog} onOpenChange={setShowNewTeamDialog}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={cn("w-[200px] justify-between", className)}
          >
            <Avatar className="mr-2 h-5 w-5">
              <AvatarImage
                src={team.logo}
                alt={team.name}
              />
              <AvatarFallback>{makeAcronym(team.name[0])}</AvatarFallback>
            </Avatar>
            {team.name}
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search team..." />
              <CommandEmpty>No team found.</CommandEmpty>
              <CommandGroup key={"other"} heading={"Current Team"}>
                <CommandItem
                  key={team._id}
                  onSelect={() => {
                    setOpen(false)
                  }}
                  className="text-sm"
                >
                  <Avatar className="mr-2 h-5 w-5">
                    <AvatarImage
                      src={team.logo}
                      alt={team.name}
                      className="grayscale"
                    />
                    <AvatarFallback>{makeAcronym(team.name[0])}</AvatarFallback>
                  </Avatar>
                  {team.name}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4", "opacity-100"
                    )}
                  />
                </CommandItem>
              </CommandGroup>
              <CommandGroup key={"other"} heading={"Other Teams"}>
                {teams?.map((t: Team) => {
                  if(t._id === team._id) return null
                  return <CommandItem
                    key={t._id}
                    onSelect={async () => {
                      await switchTeam(t._id)
                      setOpen(false)
                    }}
                    className="text-sm"
                  >
                    <Avatar className="mr-2 h-5 w-5">
                      <AvatarImage
                        src={t.logo}
                        alt={t.name}
                        // className="grayscale"
                      />
                      <AvatarFallback>{makeAcronym(t.name[0])}</AvatarFallback>
                    </Avatar>
                    {t.name}
                    <CheckIcon
                      className={cn(
                        "ml-auto h-4 w-4",
                        team._id === t._id
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
})}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false)
                      setShowNewTeamDialog(true)
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    Create Team
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </CreateTeamDialog>
  )
}