import {Grid, Spacer} from "@geist-ui/core"
import { X } from 'phosphor-react'
import styles from '../../table.module.css'

const Chip = ({ children, onRemove }) => {
    return <div className={styles.chip}>
        {children}
        <Spacer w={0.3}></Spacer>
        <X onClick={onRemove} size={16}></X>
    </div>
}

const ActiveFilters = ({ filter, config, onRemove }) => {

    const skipList = ["limit"]

    return <Grid direction="row">
        <Grid.Container gap={1}>
            {Object.keys(filter).map(key => {
                if (skipList.includes(key)) return null
                const currentValue = filter[key]
                return <Grid key={key}>
                    <Chip onRemove={() => onRemove(key)}>
                    {config.filter[key]?.getChipText(currentValue)}
                </Chip>
                </Grid>
            })}
        </Grid.Container>
    </Grid>
}

export default ActiveFilters