import {URLStep, TriggerStep} from "./views"

const steps = {
    trigger: {
        title: "Choose a Trigger",
        subtitle: "Choose an event that will trigger the webhook.",
        onComplete: setStep => setStep('url'),
        View: TriggerStep
    },
    url: {
        title: 'Enter a URL',
        subtitle: 'The endpoint should accept POST requests.',
        onComplete: async (setStep, submit) => await submit(),
        View: URLStep
    },
}

export default steps