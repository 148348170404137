import {ListAction} from "../list"
import Loader from "../loader"
import Card from "../card"

import { useState } from "react"

const Notification = ({ title, subtitle, icon, color, backgroundColor, onTap }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const handleClick = async () => {
        setLoading(true)
        try { await onTap() }
        catch (e) { setError(e.message) }
        setLoading(false)
    }

    return <div style={{ marginBottom: "30px" }}>
        {loading ?
            <Loader></Loader> :
            <Card style={{ background: backgroundColor }}>
                {error ? error : <ListAction tap={handleClick} {...{ title, subtitle, icon, color }}></ListAction>}
            </Card>}
    </div>
}

export default Notification