import styles from "./wrappers.module.css"
import React from "react";
import Modal from '../components/modal/base';

import { HeadSection, TopBar, DesktopMenuList, TitleBar } from "./components";
import { Spacer } from "@geist-ui/core";

const Page = ({ children, modal, closeModal, setModal, tabs, setFullScreenModal }) => {

    return <div>
        <HeadSection />
        <div className={styles.topBar}>
            <TopBar setFullScreenModal={setFullScreenModal} />
        </div>
        <Modal toggle={closeModal} open={modal}>{modal}</Modal>
        <div className={styles.sidebar}>
            <DesktopMenuList />
        </div>
        <main className={styles.main}>
            <TitleBar tabs={tabs} setModal={setModal} title='test'></TitleBar>
            <div className={styles.page}>
                {children}
                <Spacer h={4} />
            </div>
        </main>
    </div>
}


export default Page 