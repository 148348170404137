import { Spacer } from "@geist-ui/core"

import { Button } from '@/client/ui'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/client/ui/dialog"
import { Label } from "@/client/ui/label"
import { Input } from "@/client/ui-v2/ui/input"

import { useState } from "react"

import { useUser } from "@/client/utils/useUser"
import Network from "@/client/services/network"
import React from "react"

type Props = {
    open: boolean
    onOpenChange: (open: boolean) => void
    children?: React.ReactNode
    callback?: () => Promise<void> | void
}

const CreateTeamDialog = ({ open, onOpenChange, children, callback }: Props) => {
    const [name, setName] = useState("")
    const { user } = useUser()

    const submit = async (e: {preventDefault: ()=> void}) => {

        e.preventDefault()

        try {
            await Network.post(`/api/v1/teams`, user?.accessToken, {name})
            if (callback) await callback()
            onOpenChange(false)
        } catch (e) {
            
        }
    }

    return <Dialog open={open} onOpenChange={onOpenChange}>
        {children}
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Create team</DialogTitle>
                <DialogDescription>
                    Add a new team to manage products and customers.
                </DialogDescription>
            </DialogHeader>
            <div>
                <div className="space-y-4 py-2 pb-4">
                    <div className="space-y-2">
                        <Label htmlFor="name">Team name</Label>
                        <Input value={name} onChange={e=>{e.preventDefault(); setName(e.target.value)}} id="name" placeholder="Acme Inc." />
                    </div>
                    <Spacer/>
                </div>
            </div>
            <DialogFooter>
                <Button variant="outline" onClick={() => onOpenChange(false)}>
                    Cancel
                </Button>
                <Button type="submit" onClick={submit}>Create</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
}

export default CreateTeamDialog