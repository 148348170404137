import Storage from "../services/storage";
import Network from '../services/network'

const login = async (email, password, code) => {

    const res = await Network.post('/api/v1/auth/login', "", {
        email,
        password,
        code
    })

    await setUserCookies(res)
}

const signup = async (data, inviteCode) => {

    const res = await Network.post('/api/v1/auth/signup', "", { ...data, inviteCode })
    if (!res) throw new Error("Could not log in")

    await setUserCookies(res)

}

const refreshToken = async (refreshToken) => {
    if (!refreshToken) {
        refreshToken = await Storage.local.get('refreshToken')
    }
    const res = await Network.post('/api/v1/auth/refresh', null, {
        refreshToken
    })
    await setUserCookies(res)
}

const logout = () => {
    Storage.local.remove('user')
    Storage.local.remove('refreshToken')
}

const setUserCookies = async (userData) => {

    const { refreshToken, accessToken, user } = userData
    const { _id, email, role, firstName, lastName, team } = user
    const formatted = { _id, email, role, firstName, lastName, accessToken, team }
    await Storage.local.setJSON('user', formatted)
    await Storage.local.set('refreshToken', refreshToken)

    Storage.cookies.set('token_valid', true, { expires: 1 / 24 })
    Storage.cookies.set('refresh_token_valid', true, { expires: 365 })
}

const getUserCookies = async () => {
    const user = await Storage.local.getJSON('user')
    const refreshToken = await Storage.local.get('refreshToken')


    const token_valid = Storage.cookies.get('token_valid')
    const refresh_token_valid = Storage.cookies.get('refresh_token_valid')

    if (!token_valid) return { refreshToken }
    return { user, refreshToken }
}


const check = async (email) => {
    const checkedEmail = await Network.post('/api/v1/auth/check', null, { email })
    return checkedEmail.userExists
}

const AuthUtils = { login, signup, logout, refreshToken, getUserCookies, check, setUserCookies }
export default AuthUtils
export { login, signup, logout, refreshToken, getUserCookies, setUserCookies }