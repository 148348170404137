import useSWR, {useSWRConfig} from "swr"
import Network from "../services/network"
import { buildQueryFromFilter } from "../services/network"
import { useUser } from "../utils/useUser"

const parseData = (data, model) => {
    if (!data) return null
    if (!model) return data
    if (Array.isArray(data)) {
        return data?.map(item => new model(item))
    }
    return new model(data)
}


const getFunctionNames = (name) => {
    const uppercaseName = name ? name[0].toUpperCase() + name.toString().slice(1) : "Data"
    const refreshFunctionName = "refresh" + uppercaseName
    return refreshFunctionName

}


const useSWRDataWithAuth = (url, filter, { model, name, v2, redirect } = { redirect: true },) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser({ redirect })
    const { data, error } = useSWR(user ? [url + (filter ? buildQueryFromFilter(filter) : ""), user.accessToken] : null, Network.get)

    const refresh = async () => {
        await mutate(user ? [url + (filter ? buildQueryFromFilter(filter) : ""), user.accessToken] : null)
    }


    const refreshFunctionName = getFunctionNames(name)

    return {
        [(name ?? "data")]: parseData(v2 ? data?.data : data, model),
        next: data?.next,
        meta: data?.meta,
        error,
        loading: !data && !error,
        refresh, 
        [refreshFunctionName]: refresh
    }
}

const useData = (url, filter, { model, name, v2, redirect, authless } = { redirect: true },) => {
    const { mutate } = useSWRConfig()
    const { user } = useUser({ redirect })
    const filterComponent = filter ? buildQueryFromFilter(filter) : ""
    const key = [url + filterComponent, user?.accessToken]
    const { data, error } = useSWR(key, Network.get)

    const refresh = async () => {
        await mutate(key)
    }

    return {
        [(name ?? "data")]: parseData(v2 ? data?.data : data, model),
        next: data?.next,
        meta: data?.meta,
        error,
        loading: !data && !error,
        refresh
    }
}


const useSWRData = (url, filter, { model, name }) => {
    const { mutate } = useSWRConfig()
    const { data, error } = useSWR(filter ? url + buildQueryFromFilter(filter) : url, Network.get)

    const refreshData = async () => {
        await mutate(filter ? buildQueryFromFilter(filter) : url)
    }

    return {
        [name ?? "data"]: data ? parseData(data, model) : null,
        error,
        loading: !data && !error,
        ["refresh" + (name ?? "Data")]: refreshData
    }
}


export { useSWRData, useSWRDataWithAuth, useData }