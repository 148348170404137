import moment from "moment"
import {useEffect, useState} from "react"
import QRCode from 'react-qr-code'
import styles from './qr-code.module.css'

const PlantBarcodeLine = ({ plant, team }) => {

    const [baseURL, setBaseURL] = useState("https://app.cloner.co.za")

    useEffect(() => {
        setBaseURL(window?.location?.origin ?? "")
    }, [])

    return <div className={styles.container}>
        <div className={styles.qrCodeLine}>
            <div>
                {team?.logo ? <img style={{ width: "40px", height: "40px", borderRadius: "200px" }} src={team?.logo}></img> : team?.name}
                <h4 style={{ marginBottom: 0 }}>{plant?.name}</h4>
                <strong style={{ opacity: 0.5 }}>{plant?.strain.name} from {plant?.provider?.name}</strong>
                <br />
                {moment(plant?.timestamp).format("DD.MM.YYYY")}
            </div>
            <div>
                <QRCode size={64} height={50} width={50} value={baseURL + "/plants/" + plant?._id}></QRCode>
            </div>
        </div>
        <div style={{flexBasis: "50%", display: "flex", alignItems: "center", justifyContent: "center", opacity: 0.5}}>
            <strong>Back Side. Glue together.</strong>
        </div>
    </div>
}


export default PlantBarcodeLine