import {fillFromObject} from "../utils"
import Network from "../../services/network";

class Strain {
    constructor(data) {
        fillFromObject(data, this);
    }

    static dataUrl = "/api/v1/strains";
    
    static async create(user, provider, name, type) {

        const res = await Network.post(
            this.dataUrl,
            user.accessToken,
            {name, provider, type}
        );

        return res;
    }
}

export default Strain;