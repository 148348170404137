const buildFilter = (req) => {
	const {query} = req
	var copiedQuery = {...query}
	var output = {}

	if (query.limit) {
		output["limit"] = query.limit
		delete copiedQuery.limit
	}

	if (query.sort) {
		output["sort"] = query.sort
		delete copiedQuery.sort
	}

	if (query.startDate) {
		copiedQuery["dueDate"] = {
			...copiedQuery["dueDate"],
			$gte: parseInt(query.startDate),
		}
		delete copiedQuery.startDate
	}

	if (query.endDate) {
		copiedQuery["dueDate"] = {...copiedQuery["dueDate"], $lt: query.endDate}
		delete copiedQuery.endDate
	}

	Object.keys(copiedQuery).forEach((key) => {
		if (copiedQuery[key] === "true") copiedQuery[key] = true
		if (copiedQuery[key] === "false") copiedQuery[key] = false
	})

	output.filter = copiedQuery

	return output
}

const ops = ['ne', 'gt', 'gte', 'lt', 'lte', 'eq', 'contains', 'elemMatch', 'in']
const superOps = ['and', 'or']

const parseValue = (value) => {
    if (value === 'true') return true
    if (value === 'false') return false
    if (value === 'null') return null
    if (value === 'undefined') return undefined

    if (isNaN(value)) return value

    return Number(value)
}

const removeOuterParentheses = (value) => {
    const commandIndex = value.indexOf('(')
    return value.slice(commandIndex + 1, value.length - 1)
}

const parseInstruction = (key, value) => {
    const cleanedValue = value.replace(/\s/g, '')
    const command = cleanedValue.split('(')[0]

    if (superOps.includes(command)) {
        const arrayRep = removeOuterParentheses(cleanedValue)?.split(',')
        if (arrayRep.length > 1) {
            const val = {
                key: `$${command}`, value: arrayRep.map((val) => {
                    const instructRes = parseInstruction(key, val)
                    return { [instructRes.key]: instructRes.value }
                })
            }
            return val
        }
    }
    if (ops.includes(command)) {
        const parsedValue = parseValue(removeOuterParentheses(cleanedValue))
        if (command === 'eq') return { key, value: parsedValue }
        return { key, value: { ["$" + command]: parsedValue } }
    }
}


const buildFilterFromQuery = (query) => {


    if (!query) return ({ filter: {}, sort: {}, limit: 100 })
    const keys = Object.keys(query)

    var filter = {}
    var sort = {}
    var limit = 100
    var skip = 0

    keys.forEach(key => {
        const lhsKey = key?.split('[')[0]
        const rhsKey = key?.split('[')[1]?.split(']')[0]
        switch (lhsKey) {
            case 'limit':
                limit = parseInt(query[key])
                break
            case 'skip':
                skip = parseInt(query[key])
                break
            case 'sort':
                const sortField = key.split('[')[1].split(']')[0]
                sort[sortField] = parseInt(query[key])
                break
            case 'filter':
                const parsedInstruction = parseInstruction(rhsKey, query[key])
                const parsedValue = parseValue(parsedInstruction?.value)
                filter[parsedInstruction?.key] = parsedValue
        }
    })

    return { filter, sort, limit, skip }
}

export { buildFilter, buildFilterFromQuery, removeOuterParentheses, parseInstruction, parseValue }