import styles from './list-item.module.css'

const ListItem = ({ title, subtitle, Icon, children, onClick, lastChild }) => {

    return <div onClick={onClick} className={styles.listItem} style={{ borderBottom: lastChild ? "none" : null }}>
        {Icon ? <div className={styles.listItemIcon}>
            <Icon size={24}></Icon>
        </div> : null}
        <div className={styles.listItemText}>
            <div className={styles.listItemTitle}>{title}</div>
            <div className={styles.listItemSubtitle}>{subtitle}</div>
        </div>
        {children || children?.length < 0 ?
            <div className={styles.listItemChildren}>
                {children}
            </div> : null
        }
    </div>
}

export default ListItem