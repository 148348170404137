import {Divider, Grid, Spacer, useMediaQuery} from "@geist-ui/core"
import { useUser } from "../../utils/useUser"
import { EditableData, Button, SecondaryButton } from "../../ui"
import { ModalHeader } from "./components"
import { useEffect, useState } from "react"
import Network from "../../services/network"
import { PlantLoader, WrappedPlant } from "../plant"
import { useRouter } from "next/router"
import useSWR from "swr"

const PlantSelectionSection = ({ plants, phase, onClose, onComplete }) => {

    const [availablePlants, setAvailablePlants] = useState([])
    const [selected, setSelected] = useState([])
    const isMobile = useMediaQuery('sm', { match: 'down' })

    useEffect(() => {
        if (availablePlants.length === 0) {
            var plantsArray = []
            plants.map(plant => {
                if (plant.status === phase) {
                    if (!plant.batch) plantsArray.push(plant)
                }
            })
            setAvailablePlants(plantsArray)
            setSelected(plantsArray)
        }

    }, [availablePlants.length, plants, phase])

    const tapPlant = passedPlant => {
        if (selected.includes(passedPlant)) {
            var copySelected = [...selected]
            const currentIndex = copySelected.indexOf(passedPlant)
            copySelected.splice(currentIndex, 1)
            setSelected(copySelected)
        } else {
            setSelected([...selected, passedPlant])
        }


    }

    if (availablePlants.length === 0) {
        return "Loading..."
    }

    return <>
        Selected Plants: {selected.length}
        <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
            {availablePlants.map((plant, index) => {
                const isSelected = selected.includes(plant)
                return <WrappedPlant key={index} onClick={() => tapPlant(plant)} variant='selectable' selected={isSelected} plantId={plant._id} />
            })}
        </div>
        <Spacer h={4} />
        <Grid.Container direction={isMobile ? 'column-reverse' : 'row'} gap={2}>
            <Grid direction="column" xs={24} sm={12}>
                <SecondaryButton style={{ width: "100%" }} onClick={onClose}>
                    Dismiss
                </SecondaryButton>
            </Grid>
            <Grid direction="column" xs={24} sm={12}>
                <Button onClick={async () => await onComplete(selected)} style={{ width: "100%" }}>
                    Continue
                </Button>
            </Grid>
        </Grid.Container>

    </>
}



const PlantConfirmationPage = ({ onComplete, phase, plants, onClose }) => {
    const { query: { id } } = useRouter()
    const { user } = useUser()
    const { data, error } = useSWR(user ? ['/api/v1/spaces/' + id + '/plants', user?.accessToken] : null, Network.get)

    const Wrapper = ({ children }) => {
        return <div style={{ padding: "40px" }}>
            <ModalHeader style={{ width: "100%" }} />
            <Spacer h={3} />
            <strong>Confirm Plant Selection</strong>
            <Spacer h={2} />
            {children}
        </div>
    }

    if (!data) return <Wrapper>
        <PlantLoader />
    </Wrapper>

    return <Wrapper>
        <PlantSelectionSection onComplete={onComplete} onClose={onClose} phase={phase} plants={data} />
    </Wrapper>
}

const LeftoversPage = ({ plantIds }) => {
    return <div>
        Leftover Plants:
        <Divider style={{ marginBottom: 0 }}></Divider>
        <div style={{ maxHeight: "250px", overflowY: "scroll" }}>
            {plantIds.map(plantId => {
                return <WrappedPlant key={plantId} plantId={plantId} />
            })}
        </div>
        <Divider style={{ marginTop: 0 }}></Divider>
        <Spacer h={2}></Spacer>
    </div>
}

const CreateBatchPopup = ({ onClose, callback }) => {

    const { query: { id } } = useRouter()
    const [step, setStep] = useState('first')
    const [res, setRes] = useState()

    const createBatch = async (plants) => {
        setStep('loading')
        try {
            const callbackRes = await callback()
            setRes(callbackRes?.leftovers)
            setStep('complete')
        } catch (er) {
            setRes(er.message)
            console.log(er)
            setStep('error')
        }
        // setBatch(batch)
    }


    const Wrapper = ({ children, variant, title, subtitle }) => {
        return <div style={{ padding: "20px" }}>
            <ModalHeader variant={variant} title={title} subtitle={subtitle} style={{ width: "100%" }} />
            <Spacer h={2} />
            {children}
        </div>
    }

    if (step === 'error') return <Wrapper variant={"error"}>{res}</Wrapper>
    if (step === 'complete') return <Wrapper subtitle={'Batch created successfully. ' + res ? "Some plants could not be added" : ""} title='Batch Created' variant='done'>
        <Spacer />
        {res && <LeftoversPage plantIds={res}></LeftoversPage>}
        <SecondaryButton style={{ width: "100%" }} onClick={onClose}>
            Dismiss
        </SecondaryButton>
    </Wrapper>

    if (step === 'loading') return <Wrapper>Loading...</Wrapper>

    // if (step === 'confirm-plants') return <PlantConfirmationPage onClose={onClose} onComplete={createBatch} phase={phase} />

    return <Wrapper>
        <Spacer h={2} />
        <Grid.Container style={{ width: "100%" }}>
            <Grid xs={24} direction='column'>
                <EditableData
                    subtitle="To verify the authenticity of your batch"
                    title="Certificates"
                    variant="Variant 2"
                    // Using default values:
                    actionText="Change"
                    icon="GridFour"
                />
            </Grid>
            <Spacer />
            <Grid xs={24}>
                <EditableData
                    style={{ width: "100%" }}
                    subtitle="Post Updates and Phase Changes to the whole batch"
                    title="Actions"
                    variant="Variant 2"
                    // Using default values:
                    actionText="Change"
                    icon="GridFour"
                />
            </Grid>
        </Grid.Container>
        <Spacer h={4} />
        <Grid.Container direction='column' gap={1}>
            <Grid direction="column" xs={24} >
                <Button onClick={createBatch} style={{ width: "100%" }}>
                    Continue
                </Button>
            </Grid>
            <Grid direction="column" xs={24} >
                <SecondaryButton style={{ width: "100%" }} onClick={onClose}>
                    Dismiss
                </SecondaryButton>
            </Grid>

        </Grid.Container>
    </Wrapper>
}

export default CreateBatchPopup