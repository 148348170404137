import {Spinner} from "@geist-ui/core"

const Status = ({ options, current, style, loading }) => {

    const option = loading ? options["loading"] : options[current]

    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", ...style }}>
        {loading ? <Spinner scale={.5} style={{marginRight: "8px"}}/> : 
        <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: option?.color ?? "var(--color-primary-50)", marginRight: "10px" }}></div>}
        <div style={{ color: option?.title ? option?.color : "var(--color-primary-50)" }}>{option?.title ?? "Inactive"} </div>
    </div>
}

export default Status