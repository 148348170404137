import {Spacer, Grid} from "@geist-ui/core"
import { EditableData, Input, Button, SecondaryButton } from '../../ui'

import { useState } from 'react'

import { upperFirst } from 'lodash'

import { ModalHeader } from './components'
import { getNextState } from '../../../common/workflow'
import { getMajorityStatus } from '../../../common/plant-utils'


const PhaseChangeModal = ({ plants, onClose, callback }) => {

    const [date, setDate] = useState(Date.now())
    const [editingDate, setEditingDate] = useState(false)
    const [done, setDone] = useState(false)

    const updatePhase = async () => {
        // const date = parseDate(date)
        await callback(date)
        setDone(true)
    }

    const parseDate = () => {
        const dateArray = date.split('.')
        const dateObj = new Date(parseInt(dateArray[2]), parseInt(dateArray[1]) - 1, parseInt(dateArray[0]))
        return dateObj.valueOf()
    }

    const Wrapper = ({ title, variant, subtitle, children }) => {
        return (
            <div style={{ padding: "20px" }}>
                <ModalHeader variant={variant} title={title ?? "Change Phase"}
                    subtitle={subtitle ?? "You should only do this if you're sure the plant has matured."}
                    style={{ width: "100%" }}></ModalHeader>
                <Spacer h={2} />
                {children}
                <SecondaryButton fullWidth onClick={onClose}>
                    Dismiss
                </SecondaryButton>
            </div>
        )

    }

    if (!plants) return <div style={{ position: "relative", height: "300px" }}>No Plants Provided</div>

    const majorityState = getMajorityStatus(plants)
    const workflow = plants[0]?.workflow
    const nextState = getNextState(majorityState, workflow?.steps)
    const currentTitle = workflow?.steps[majorityState]?.title
    const nextTitle = workflow?.steps[nextState]?.title

    

    if (done) return <Wrapper variant={'done'} title='Phase Changed' subtitle='The status of your plant was updated'>
        <Spacer h={2} />
    </Wrapper>

    if (editingDate) return <div >
        <Grid.Container style={{ height: "100%", textAlign: "center" }} direction='column' justify='center' alignContent='center' alignItems='stretch'>
            <Grid>
                <img width='70px' src='/icons/phase-change.png' />
            </Grid>
            <Grid>
                <div style={{ fontSize: "24px", fontWeight: "700" }}>Change Plant Phase</div>
            </Grid>
            <Grid>
                <div style={{ color: "#8E51C8", fontWeight: "500" }}>{currentTitle} &#8594; {nextTitle}</div>
            </Grid>
            <Spacer />
            <Grid>
                <div style={{ fontWeight: "500", textAlign: "center" }}>Enter the date that your plant moved from <strong>{upperFirst(plant.status)}</strong> to <strong>{upperFirst(nextPhase)}</strong>?</div>
            </Grid>
            <Spacer h={2} />
            <Grid xs={24} alignContent='center' direction='column' alignItems='center' justify='center'>
                <Input onChange={e => { setDate(e.target.value) }} placeholder={"DD.MM.YYYY"} />
            </Grid>
            <Spacer h={2} />
            <Grid xs={24} gap={4}>
                <Grid xs={6}>
                    <div style={{ width: "100%" }}>
                        <SecondaryButton icon={'CaretLeft'} type='success-light' shadow width={'100%'} onClick={async () => {
                            setEditingDate(false)
                        }}>{""}</SecondaryButton>
                    </div>
                </Grid>
                <Spacer w={0.5} />
                <Grid xs={18}>
                    <div style={{ width: "100%" }}>
                        <Button type='success-light' shadow width={'100%'} onClick={updatePhase}>Confirm</Button>
                    </div>
                </Grid>

            </Grid>
        </Grid.Container>
    </div>

    return <Wrapper>
        <EditableData
            variant='Variant 2'
            title='New Phase'
            subtitle={nextTitle}
        />
        {/* 
        <Spacer h={0.5} />
        <DatePickerDisplay onClick={() => { setEditingDate(true) }} value={date} /> */}
        {/* <EditableData
            subtitle={date.toString()}
            title='Date Changed' /> */}
        <Spacer h={2} />
        <Button type='success-light' shadow width={'100%'} onClick={updatePhase}>Change Phase</Button>
        <Spacer h={0.5} />

    </Wrapper>
}
export default PhaseChangeModal