import { intervals } from "../variables";
import { Steps, Step} from "./workflow.types";

const getStepsAsArray = (steps: Steps) => {
    
    const stepArray: Step[] = [];
    const stepKeys = Object.keys(steps);
    stepKeys.forEach((key) => {
        const step = steps[key];
        if (step.noProgression) return;
        stepArray.push({ ...step, id: key });
    });
    const sorted = stepArray.sort((a, b) => a.order - b.order);
    const stepIds = sorted.map((step) => step.id);
    return { steps: sorted, keys: stepIds as string[] };
};

const getStepsAsSortedObject = (workflow: { steps: Steps }) => {
    const result = getStepsAsArray(workflow.steps);
    if (!result) return {};

    const { steps } = result;
    const newObj: Steps = {};
    steps.forEach(step => newObj[step.id] = step);
    return newObj;
};

const getNextState = (status: string, providedSteps: Steps = intervals) => {
    const steps = getStepsAsSortedObject({ steps: providedSteps });
    const keyArray = Object.keys(steps);
    const currentIndex = keyArray.indexOf(status);
    const lastIndex = keyArray.length - 1;
    const isLastState = currentIndex === lastIndex;

    return !isLastState ? keyArray[currentIndex + 1] : keyArray[currentIndex];
};

const getPreviousState = (status: string, steps: Steps = intervals) => {
    const current = Object.keys(steps).indexOf(status);
    return current !== 0 ? Object.keys(steps)[current - 1] : status;
};

export { getStepsAsArray, getStepsAsSortedObject, getNextState, getPreviousState };
