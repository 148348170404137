import Network from "../services/network"

class Plant {

    constructor(plantData) {
        for (let key in plantData) {
            this[key] = plantData[key];
        }
    }

    static baseURL = '/api/v1/plants'
    static APIv2 = '/api/v2/plants'

    static async uproot(user, reason, plants) {
        if (!user) throw new Error("Missing user");

        const res = await Network.post('/api/v1/plants/uproot', user.accessToken, {
            plants: plants,
            description: reason,
            // space: batch?.space,
        })

    }

    async move(space, user) {
        if (!space || !user) throw new Error("Missing required arguments");
        await Network.post('/api/v1/spaces/' + space + '/plants', user.accessToken, {
            plants: [this._id]
        })
    }

    async delete(reason, user) {
        await Plant.uproot(user, reason, [this._id])
    }



    async postUpdate(update, user) {
        if (!update.category) throw new Error("Missing required arguments");
        await Network.post('/api/v1/plants/' + this._id + '/events', user.accessToken,
            { ...update, space: this.space }
        )
    }

    async endGrowth(user) {
        await Network.put(`/api/v1/plants/${this._id}`, user?.accessToken, { status: 'done' })
    }

    async rename(name, user) {
        await Network.put('/api/v1/plants/' + this._id, user.accessToken, { name })
    }

    async clone(user, space, quantity, makeBatch) {
        await Network.post('/api/v1/plants/' + this._id + "/clone", user.accessToken, {
            space,
            quantity,
            makeBatch
        })
    }

    async advanceState(user, date) {

        await Network.put(`${Plant.baseURL}/${this._id}/state`, user.accessToken, {
            date,
        })

    }

    async updatePublicationStatus(user, status) {
        await Network.put(`${Plant.baseURL}/${this._id}`, user.accessToken, {
            published: status,
        })
    }

}

export default Plant