import { PopupStepper } from "../../../../ui"
import { useUser } from "../../../../utils/useUser"
import { useActiveGenetics } from "../../../../data/useGenetics"
import Genetic from '../../'
import steps from './steps'

const AddGeneticPopup = ({ onClose }) => {

    const { user } = useUser()
    const { refreshGenetics } = useActiveGenetics()

    const submit = async data => {
        await Genetic.create(user, { ...data, quantity: parseInt(data.quantity) }, data.type)
        await refreshGenetics()
    }

    return <PopupStepper onClose={onClose} callback={submit} steps={steps} initialStep='unifiedList' />
}

export default AddGeneticPopup