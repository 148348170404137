import { getStepsAsArray } from "../../../common/workflow"
import Network from "../../services/network"
import { User } from "@/client/components/user/types"

interface WorkflowShape {
    _id: string
    title: string
    description: string
    steps: any
    owners: string[]
    creators: string[]
    type: string
    timestamp: number
    default: boolean
    appliesTo: string[]
}

class Workflow {

    _id: string
    title: string
    description: string
    steps: any
    owners: string[]
    creators: string[]
    type: string
    timestamp: number
    default: boolean
    appliesTo: string[]

    constructor(props: WorkflowShape) {
        const { _id, title, steps, description, owners, creators, type, timestamp, appliesTo } = props ?? {}
        this._id = _id
        this.description = description
        this.steps = steps
        this.title = title
        this.owners = owners
        this.creators = creators
        this.type = type
        this.timestamp = timestamp
        this.default = props?.default
        this.appliesTo = appliesTo
    }

    static baseURL = "/api/v1/workflows"

    getSteps() {
        const { steps } = getStepsAsArray(this.steps)
        return steps
    }

    getNextStep(stepId: string) {
        const { steps, keys } = getStepsAsArray(this.steps)
        const current = keys.indexOf(stepId)
        const next = steps[current + 1]
        return next
    }

    async delete(user: User) {
        await Network.del("/api/v1/workflows/" + this._id, user.accessToken)
    }

    async addStep(user: User, position: number) {
        await Network.post("/api/v1/workflows/" + this._id + "/steps", user.accessToken, {
            position
        })
        return this
    }

    async removeStep(user: User, id: string) {
        await Network.del("/api/v1/workflows/" + this._id + "/steps/" + id, user.accessToken)
        return this
    }

    async duplicate(user: User) {
        const response = await Network.post("/api/v1/workflows/" + this._id + "/duplicate", user.accessToken)
        return response
    }

    async rename(user: User, title: string) {
        const response = await Network.put("/api/v1/workflows/" + this._id, user.accessToken, {
            title
        })
        return response
    }

    async editValue(user: User, key: string, value: any) {
        const response = await Network.put("/api/v1/workflows/" + this._id, user.accessToken, {
            [key]: value
        })
        return response
    }

    async update(user: User, stepId: string, data: any) {
        const response = await Network.put("/api/v1/workflows/" + this._id + "/steps/" + stepId, user.accessToken, data)
        return response
    }

    static async create(user: User, data: any) {
        const { title, type, isHarvest } = data
        if (!title || !type) throw new Error("Missing required data")

        const response = await Network.post("/api/v1/workflows", user.accessToken, {
            title, type, isHarvest
        })

        if (!response) throw new Error("Failed to create workflow")
        else return response
    }

    getStepNames() {
        const { steps, keys } = getStepsAsArray(this.steps)
        const output: { [key: string]: string } = {}
        keys.forEach((key: string, index: number) => {
            if (steps[index].title) {
                output[key] = steps[index].title
            }
        })
        return output
    }

}


export interface WorkflowBasedEntity {
    workflow: Workflow,
    status: string,
}

export default Workflow