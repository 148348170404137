import steps from "./steps"
import {PopupStepper} from "../../../ui"
import { useUser } from '../../../utils/useUser'
import { useRouter } from 'next/router'
import Sensor from '../model'

const AddSensorModal = ({ onClose }) => {
    const { user } = useUser()
    const router = useRouter()

    const callback = async (data) => {
        const sensor = await Sensor.create(user, data)
        router.push(`/sensors/${sensor._id}`)
        onClose()
    }

    return <PopupStepper callback={callback} onClose={onClose} steps={steps}></PopupStepper>

}


export default AddSensorModal