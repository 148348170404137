import {Spacer, Grid, Link} from "@geist-ui/core"

const SectionTitle = ({ children, action, title, actionText }) => {

    return <><Spacer/><Grid.Container justify='space-between' alignItems='center'>
        <Grid>   <span style={{ fontSize: "15px", fontWeight: 500, color: "gray" }}>{children}{title}</span>
        </Grid>
        {action ?
            <Grid>
                <Link style={{ fontSize: "15px" }} color href='#' onClick={action}>{actionText ?? "View All"}</Link>
            </Grid> : null}
    </Grid.Container>
    <Spacer/>
    </>
}
export { SectionTitle }