import FixedData from "https://framer.com/m/FixedData-asHM.js@rniNYmS4OevX4gMbofXS"
import EditablData from "https://framer.com/m/EditableData-yj79.js@D4IHyz8rm539CZ3IHnrf"
import InfoLine from "https://framer.com/m/InfoLine-tXia.js@wIA17tW7nVPA5S9oaY8a"


const EditableData = (props) => {
    return <EditablData {...props} style={{ width: "100%", ...props?.style }} />
}


export { EditableData, FixedData, InfoLine }