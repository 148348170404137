import {Grid, Spacer} from "@geist-ui/core"
import { LogoIcon } from "../components/logo"
import moment from "moment"
import styles from './wrappers.module.css'
import QRCode from "react-qr-code"
import ReportInfo from "https://framer.com/m/Report-Info-3qEu.js@cSqyvxh4KdZhNV1oJaZR"
import { useEffect, useState } from "react"
import Image from "next/image"

const ReportSectionHeading = ({ title, amount }) => {
    return <div style={{ borderBottom: "var(--standard-border)", marginBottom: "32px", paddingBottom: "16px" }}>

        <h2 style={{ fontSize: "14px", fontWeight: "normal", color: "var(--color-primary-50)", margin: 0 }}>Section {amount}</h2>
        <h1 style={{ fontSize: "24px", fontWeight: "bold", margin: 0 }}>{title}</h1>
    </div>
}

const PDFPageSection = ({ children, title, number }) => {
    return <Grid.Container style={{ padding: "64px 0" }} direction='column'>
        <ReportSectionHeading amount={`${number}`} title={title}></ReportSectionHeading>
        {children}
    </Grid.Container>
}

const PDFPageHeader = () => {

    return <Grid.Container justify="space-between" style={{ marginBottom: "24px" }}>
        <Grid>
            <LogoIcon style={{ width: "24px", height: "24px", marginRight: "8px", }}></LogoIcon>
        </Grid>
        <Grid>
            {moment(Date.now()).format("MMMM Do YYYY")}
        </Grid>
    </Grid.Container>
}


const PDFInfoSection = ({ values }) => {
    return <div>
        <Grid.Container gap={4}>
            {values?.map((value, index) => {
                return <Grid xs={6} key={index}><ReportInfo value={`${value.value}`} title={`${value.title}`}></ReportInfo></Grid>
            })}
        </Grid.Container>
    </div>
}

const ReportPage = ({ children, team, title }) => {

    const [verificationURL, setVerificationURL] = useState()

    useEffect(() => {
        setVerificationURL(window.location.href)
    }, [])

    return <div className={styles?.pdfPage}>
        <PDFPageHeader />
        <Spacer h={2} />
        <Grid.Container justify="space-between">
            <Grid>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {team?.logo ? <div style={{ marginRight: "12px", borderRadius: "200px"}}>
                        <Image src={team?.logo} style={{borderRadius: "2000px"}} height={40} objectFit='contain' width={40} ></Image></div> : null}
                    <h3 style={{ margin: 0 }}>{team?.name}</h3>
                </div>
                <h1 style={{ fontSize: "40px", fontWeight: "bold", margin: 0 }}>{title}</h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {team?.location ? <h4 style={{ color: "var(--color-primary-50)", margin: 0 }}>{team?.location}</h4> : null}
                </div>
            </Grid>
            <Grid>
                {verificationURL && <QRCode size={96} value={verificationURL}></QRCode>}
            </Grid>
        </Grid.Container>
        {children}
    </div>
}

export default ReportPage
export { PDFPageSection, PDFInfoSection }