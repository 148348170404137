import Network from "../services/network"


class Space {

    constructor(data) {
        for (let key in data) {
            this[key] = data[key];
        }
    }

    static baseURL = "/api/v1/spaces"

    async addSensors(user, sensors) {
        const res = await Network.post(`${Space.baseURL}/${this._id}/sensors`, user.accessToken, { sensors })
        return res
    }

    async removeSensors(user, sensors) {
        const res = await Network.post(`${Space.baseURL}/${this._id}/sensors/remove`, user.accessToken, { sensors })
        return res
    }

    static async create(user, data) {
        const { name } = data
        if (!name) throw new Error("Name is required")
        return await Network.post(Space.baseURL, user.accessToken, data)
    }
    
}

export default Space