import styles from './input.module.css'

const sleep = async (ms) => {
    await new Promise(resolve => setTimeout(resolve, ms));
}

const shakeElement = async (element) => {
    element.classList.add("shake")
    await sleep(1000)
    element.classList.remove("shake")

}

const BaseInput = ({ onChange, value, placeholder, type, name, style, fullWidth, autoComplete }) => {
    return <input autoComplete={autoComplete} type={type} name={name} style={{ width: fullWidth ? '100%' : null, ...style }} className={styles.input} onChange={onChange} placeholder={placeholder} value={value} />
}

const Input = ({ throwError, onChange, value, placeholder, type, name, style, fullWidth, onEnter, autoComplete }) => {

    const props = { onChange, value, placeholder, type, name, style, fullWidth, autoComplete }

    const onEnterPressed = async e => {
        e.preventDefault();

        try {
            await onEnter()
        } catch (error) {
            await shakeElement(e.target)
            if (throwError) throw error
        }
    }

    if (onEnter) {
        return <form onSubmit={onEnterPressed} style={{ width: "100%" }}>
            <BaseInput {...props} fullWidth />
        </form>
    }

    return <BaseInput {...props} />


}



export { Input }