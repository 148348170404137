import {Display, Spacer, Spinner} from "@geist-ui/core"
import { SecondaryButton, Button, EditableData } from "../../ui"
import { useState } from "react"
import { useSpaces } from "../../data"
import SpaceListItem from "../space"
import { useRouter } from "next/router"
import { ModalHeader } from "./components"
import { PlantListItem } from "../plant"

const ChangeSpacePopup = ({ callback, close, currentSpace }) => {

    const [loading, setLoading] = useState(false)
    const { spaces } = useSpaces()
    const [selectedSpace, setSelectedSpace] = useState()
    const [leftovers, setLeftovers] = useState()
    const [completed, showCompleted] = useState()
    const router = useRouter()

    const submit = async () => {
        setLoading(true)
        const leftovers = await callback(selectedSpace._id)
        setLeftovers(leftovers)
        showCompleted(true)
    }

    const selectSpace = (space) => {
        setSelectedSpace(space)
    }

    const Wrapper = ({ children, title, subtitle }) => {
        return <div style={{ padding: "20px", textAlign: "center" }}>
            <ModalHeader style={{ width: "100%" }} variant={title ? 'done' : 'primary'} title={title ?? "Change Space"} subtitle={subtitle ?? "Move these items to a new Space"} />
            <Spacer h={2} />
            {children}
        </div>
    }


    if (completed) {
        return <Wrapper subtitle={leftovers ? "Some plants could not be moved" : ""} title={"Items moved to " + selectedSpace.name}>
            {leftovers ? <div style={{maxHeight: "300px", overflowY: "scroll"}}>
                {leftovers.map(plant => {
                    return <PlantListItem key={plant._id} plant={plant}/>
                })}
            </div> : ""}
            <Spacer h={2} />
            <Button onClick={() => { router.push('/spaces/' + selectedSpace._id); close() }} width="100%">
                Go to new Space
            </Button>
            <Spacer h={0.5} />
            <SecondaryButton onClick={close} width='100%'>
                Dismiss
            </SecondaryButton>
        </Wrapper>
    }

    if (loading) return <Wrapper>
        <Display>
            <Spinner />
        </Display>
    </Wrapper>

    if (selectedSpace) {
        return <Wrapper>
            <EditableData title="Move to Space" subtitle={selectedSpace?.name} />
            <Spacer h={2} />
            <Button onClick={submit} style={{ width: "100%" }}>
                Move Items
            </Button>
            <Spacer h={0.5} />
            <SecondaryButton onClick={close} style={{ width: "100%" }}>
                Cancel
            </SecondaryButton>
        </Wrapper>
    }

    return <Wrapper>
        <div style={{ maxHeight: "20vh", overflowY: "scroll" }}>
            {spaces?.map((space) => {
                if (space._id == router.query.id) return
                if (space._id == currentSpace) return
                return <SpaceListItem key={space.id} tap={() => selectSpace(space)} space={space} />
            })}
        </div>
        <Spacer h={2} />
        <SecondaryButton width={"100%"} onClick={close}>Cancel</SecondaryButton></Wrapper>
}

export default ChangeSpacePopup