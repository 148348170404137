import {motion} from "framer-motion"
import styles from './modal.module.css'
import { useMediaQuery } from '@geist-ui/core'

export default function Modal({ open, children, toggle, backgroundColor, color, style }) {

    const isMobile = useMediaQuery('sm', { match: 'down', ssrMatchMedia: "sm" })
    const openBool = !!open

    const modalVariants = {
        open: { bottom: isMobile ? 10 : 40 },
        closed: { bottom: -400 }
    }


    return <>
        <motion.div onClick={toggle} className={styles.bg} style={{
            background: "black",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 800,
            width: "100%",
            display: "none"
        }}
            animate={{
                display: openBool ? "block" : "none",
                opacity: openBool ? 0.5 : 0,
            }}
        >
        </motion.div>

        <motion.div className={styles.modalContainer} style={{
            backgroundColor,
            color,
            ...style
        }}
            variants={modalVariants}
            initial="closed"
            animate={openBool ? "open" : "closed"}>{children}</motion.div></>



}

