import moment from "moment"
import Skeleton from "react-loading-skeleton"
import { LineChart, Line, YAxis, ResponsiveContainer, Tooltip, XAxis, Legend } from 'recharts';
import EmptyState from '../empty-state';
import randomColor from 'randomcolor';
import { SimpleBarChart } from "./bar";


const SimpleLineChart = ({ data, dimensions }) => {
    if (!data) return <Skeleton width={dimensions?.width ? dimensions?.width + "px" : "100%"} height={dimensions?.height ? dimensions?.height + "px" : "300px"} />
    if (!data.length) return <EmptyState title='No data in the given range'>
    </EmptyState>

    const formattedData = data.map(chunk => {
        return {
            name: moment(chunk.timestamp).format("HH:mm"),
            value: chunk.value,
            unit: "°C"
        }
    })

    const generateTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return <div>
                <p>{payload[0].value} {payload[0].unit}</p>
            </div>
        } else return "Fucked"
    }

    return <ResponsiveContainer width={dimensions?.width ?? "100%"} height={300}>
        <LineChart data={formattedData}>
            <YAxis padding={"0px"} />
            <XAxis dataKey="name" />
            <Tooltip payload={formattedData} content={generateTooltip}></Tooltip>
            <Line strokeWidth="4px" type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
    </ResponsiveContainer>
}

const ComplexLineChart = ({ data, dimensions, lines }) => {
    if (!data) return <Skeleton width={dimensions?.width ? dimensions?.width + "px" : "100%"} height={dimensions?.height ? dimensions?.height + "px" : "300px"} />
    if (!data.length) return <EmptyState title='No data in the given range'>
    </EmptyState>

    const formattedData = data.map(chunk => {
        const obj = {
            timestamp: moment(chunk.timestamp).format("DD.MM"),
        }
        lines.forEach(line => {
            obj[line.id] = chunk[line.id]
            obj["unit"] = line.unit === 'celsius' ? '℃' : line.unit 
        })
        return obj
    })

    return <ResponsiveContainer width={dimensions?.width ?? "100%"} height={300}>
        <LineChart data={formattedData}>
            <YAxis unit={formattedData?.[0]?.unit} padding={"0px"} />
            <XAxis dataKey="timestamp" />
            <Tooltip 
            ></Tooltip>
            <Legend/>
            {lines.map(line => {
                const {id, title} = line
                const color = randomColor({luminosity: 'dark',})
                return <Line name={title} key={id} strokeWidth="2px" type="monotone" dataKey={id} stroke={color} />
            })}
        </LineChart>
    </ResponsiveContainer>
}

export { SimpleLineChart, ComplexLineChart, SimpleBarChart }