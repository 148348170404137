import { Grid, Spacer } from "@geist-ui/core"
import { useState } from "react"
import { Input, Button } from "../../../../../ui"


const QuantityStep = ({ onChange, onComplete }) => {

    const [quantity, setQuantity] = useState('')
    const [isValid, setIsValid] = useState("undefined")

    const handle = e => {
        if (e) e.preventDefault()
        const valid = quantity > 0
        setIsValid(valid)
        if (valid) {
            onChange("quantity", quantity)
            onComplete()
        }
    }

    return <div>
        <Grid xs={24}>
            <Input onEnter={handle} style={{ width: "100%", border: isValid ? "none" : "1px solid red" }} placeholder="Quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
        </Grid>
        <Spacer h={2} />
        <Button fullWidth onClick={handle}>
            Next
        </Button>
    </div>
}

const onComplete = (setStep) => {
    setStep("confirmation")
}

const quantityStep = {
    View: QuantityStep,
    title: "Quantity",
    subtitle: "How many seeds did you get?",
    onComplete
}

export default quantityStep